import React from 'react';
import './Heading3.css';

export const Heading3 = (props) => {
    return (
        <h3 className="custom-h3" id={props.id}>
            {props.children}
        </h3>
    );
};
