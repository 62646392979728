import styles from "./AboutFaq.module.css";
import Footer from "../../navigation/footer/Footer";

const AboutFaq = ({ children, footerData, node }) => {
  return (
    <div className={styles["complete-about-faq"]}>
      <div className={styles["frame-2018776051"]}>
        <div className={styles["frame-2018775814"]}>
          <div className={styles["hero-images"]}>
            <div className={styles["frame-1"]}>
              <div className={styles["carousel-1"]}></div>
            </div>
            <div className={styles["group-1010107217"]}>
              <div className={styles["your-questions-answered"]}>
                Your questions—answered{" "}
              </div>
              <div className={styles["faq"]}>FAQ </div>
              <div
                className={
                  styles[
                  "get-answer-to-commonly-asked-questions-across-all-of-jumpstart"
                  ]
                }
              >
                Get answer to commonly-asked questions across all of Jumpstart.{" "}
              </div>
            </div>
            <div className={styles["group-1321317153"]}>
              <img
                src='webp/Illustration_FAQ.webp'
                alt='Illustration_FAQ'
                style={{
                  position: 'absolute',
                  top: 131,
                  left: 584
                }}
              />
              <div className={styles["group-1321317142"]}>
                <div className={styles["group-55890"]}>
                  <div className={styles["group-17482"]}>
                    <svg
                      className={styles["vector20"]}
                      width="22"
                      height="170"
                      viewBox="0 0 22 170"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.809479 12.7765L21.5859 0.533203L21.2149 157.098L0.438477 169.342L0.809479 12.7765Z"
                        fill="url(#paint0_linear_5291_145092)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_5291_145092"
                          x1="11.0122"
                          y1="0.533203"
                          x2="11.0122"
                          y2="169.342"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#8DC8E8" />
                          <stop offset="1" stopColor="#0078D4" />
                        </linearGradient>
                      </defs>
                    </svg>

                    <svg
                      className={styles["vector21"]}
                      width="178"
                      height="243"
                      viewBox="0 0 178 243"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M177.439 242.342L0.46875 140.315L0.839758 0.444824L177.81 102.472L177.439 242.342Z"
                        fill="url(#paint0_linear_5291_145093)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_5291_145093"
                          x1="89.1396"
                          y1="0.444824"
                          x2="89.1396"
                          y2="242.342"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#8DC8E8" />
                          <stop offset="1" stopColor="#0078D4" />
                        </linearGradient>
                      </defs>
                    </svg>

                    <svg
                      className={styles["group-1321317154"]}
                      width="61"
                      height="70"
                      viewBox="0 0 61 70"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M55.0184 31.3505L54.9939 39.1517L0.319336 8.01337L0.337721 0.206055L55.0184 31.3505Z"
                        fill="url(#paint0_linear_5321_145938)"
                      />
                      <path
                        d="M55.0184 62.0234L54.9939 69.8246L0.319336 38.6862L0.337721 30.8789L55.0184 62.0234Z"
                        fill="url(#paint1_linear_5321_145938)"
                      />
                      <path
                        d="M60.4135 48.7241L60.3889 56.5314L0.319336 22.6684L0.337721 14.8672L60.4135 48.7241Z"
                        fill="url(#paint2_linear_5321_145938)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_5321_145938"
                          x1="92.1272"
                          y1="43.5867"
                          x2="-16.3392"
                          y2="17.6152"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#0078D4" />
                          <stop offset="0.34375" stopColor="#49C5B1" />
                          <stop offset="0.671875" stopColor="#FFA38B" />
                          <stop offset="1" stopColor="#C03BC4" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_5321_145938"
                          x1="92.1272"
                          y1="74.2595"
                          x2="-16.3392"
                          y2="48.2881"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#0078D4" />
                          <stop offset="0.34375" stopColor="#49C5B1" />
                          <stop offset="0.671875" stopColor="#FFA38B" />
                          <stop offset="1" stopColor="#C03BC4" />
                        </linearGradient>
                        <linearGradient
                          id="paint2_linear_5321_145938"
                          x1="101.182"
                          y1="61.2759"
                          x2="-17.6304"
                          y2="32.0605"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#0078D4" />
                          <stop offset="0.34375" stopColor="#49C5B1" />
                          <stop offset="0.671875" stopColor="#FFA38B" />
                          <stop offset="1" stopColor="#C03BC4" />
                        </linearGradient>
                      </defs>
                    </svg>

                    <svg
                      className={styles["vector25"]}
                      width="178"
                      height="124"
                      viewBox="0 0 178 124"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.84082 0.297363L177.811 102.324V106.777V123.843L0.84082 21.4448V4.37845V0.297363Z"
                        fill="url(#paint0_linear_5291_145097)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_5291_145097"
                          x1="55.463"
                          y1="123.843"
                          x2="49.0885"
                          y2="-130.911"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#2A446F" />
                          <stop offset="0.354167" stopColor="#0078D4" />
                          <stop offset="1" stopColor="#8DC8E8" />
                        </linearGradient>
                      </defs>
                    </svg>

                    <svg
                      className={styles["union3"]}
                      width="39"
                      height="29"
                      viewBox="0 0 39 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.56724 1.02412C8.16429 2.50815 10.0193 5.84722 10.0193 8.81528C10.0193 11.7833 8.16429 12.8964 5.56724 11.4123C2.97019 9.9283 0.744141 6.21822 0.744141 3.62117C0.744141 1.02412 2.97019 -0.459915 5.56724 1.02412ZM19.2949 9.18716C21.8919 10.6712 23.747 14.0103 23.747 16.9783C23.747 19.9464 21.8919 20.6884 19.2949 19.2044C16.6978 17.7203 14.8428 14.3813 14.8428 11.4132C14.8428 8.81615 16.6978 7.70313 19.2949 9.18716ZM38.2157 25.14C38.2157 22.1719 36.3607 18.8328 33.7636 17.3488C31.1666 15.8648 29.3115 16.9778 29.3115 19.9459C29.3115 22.9139 31.1666 26.253 33.7636 27.737C36.3607 29.2211 38.2157 28.108 38.2157 25.14Z"
                        fill="url(#paint0_linear_5291_145098)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_5291_145098"
                          x1="19.4799"
                          y1="0.43457"
                          x2="19.4799"
                          y2="46.1157"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#8DC8E8" />
                          <stop offset="1" stopColor="#0078D4" />
                        </linearGradient>
                      </defs>
                    </svg>

                    <svg
                      className={styles["vector29"]}
                      width="22"
                      height="34"
                      viewBox="0 0 22 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.810547 12.3239L21.587 0.45166V21.5991L0.810547 33.4713V12.3239Z"
                        fill="url(#paint0_linear_5291_145102)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_5291_145102"
                          x1="11.1988"
                          y1="0.45166"
                          x2="11.1988"
                          y2="33.4713"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#0078D4" />
                          <stop offset="1" stopColor="#2A446F" />
                        </linearGradient>
                      </defs>
                    </svg>

                    <svg
                      className={styles["vector30"]}
                      width="199"
                      height="115"
                      viewBox="0 0 199 115"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.833984 12.297L21.6104 0.0537109L198.69 102.378L177.805 114.324L0.833984 12.297Z"
                        fill="url(#paint0_linear_5291_145103)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_5291_145103"
                          x1="61.9024"
                          y1="114.324"
                          x2="57.0238"
                          y2="-121.351"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#2A446F" />
                          <stop offset="0.354167" stopColor="#0078D4" />
                          <stop offset="1" stopColor="#8DC8E8" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div className={styles["group-55889"]}>
                    <div className={styles["group-5"]}>
                      <svg
                        className={styles["group5"]}
                        width="263"
                        height="170"
                        viewBox="0 0 263 170"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M262.605 76.6655L262.556 93.8353C262.501 113.117 249.829 132.37 224.515 147.081C173.584 176.678 90.6926 176.679 39.4026 147.066C13.5892 132.163 0.698624 112.599 0.754084 93.067L0.802616 75.8975C0.747366 95.4292 13.6383 114.993 39.4511 129.897C90.7414 159.509 173.633 159.509 224.563 129.911C249.878 115.2 262.55 95.9474 262.605 76.6655Z"
                          fill="#D2D2D2"
                        />
                        <path
                          d="M223.959 22.6721C275.249 52.2843 275.52 100.298 224.563 129.911C173.632 159.509 90.7407 159.509 39.4504 129.897C-11.8399 100.284 -12.1103 52.2702 38.8202 22.6733C89.7771 -6.94017 172.669 -6.94058 223.959 22.6721Z"
                          fill="#FAFAFA"
                        />
                      </svg>
                    </div>
                    <div className={styles["group-6"]}>
                      <svg
                        className={styles["group6"]}
                        width="73"
                        height="103"
                        viewBox="0 0 73 103"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M72.0611 79.2574C71.0597 70.5011 66.884 62.4154 60.3239 56.5297C53.7637 50.6441 45.2741 47.3667 36.4608 47.3175C27.6475 47.2682 19.1218 50.4506 12.4963 56.2626C5.87085 62.0746 1.60508 70.1132 0.505917 78.8578C0.216343 80.1254 0.126567 81.4304 0.239857 82.7257C0.706671 87.7274 4.24125 92.6622 10.8434 96.4637C24.9812 104.599 47.7884 104.599 61.8595 96.4637C68.3947 92.6622 71.8626 87.7274 72.2627 82.7928C72.3621 81.6108 72.2942 80.4206 72.0611 79.2574Z"
                          fill="url(#paint0_linear_5291_145110)"
                        />
                        <path
                          d="M36.2498 43.5574C40.51 43.5573 44.6745 42.2939 48.2167 39.9269C51.7589 37.56 54.5197 34.1958 56.1499 30.2598C57.7801 26.3239 58.2066 21.9929 57.3754 17.8145C56.5441 13.6362 54.4926 9.79813 51.4801 6.78575C48.4676 3.77337 44.6295 1.72194 40.4511 0.890878C36.2727 0.0598188 31.9417 0.486454 28.0058 2.11683C24.0699 3.7472 20.7058 6.50809 18.339 10.0504C15.9722 13.5926 14.7089 17.7572 14.709 22.0174C14.709 24.8462 15.2662 27.6472 16.3487 30.2606C17.4312 32.874 19.0179 35.2486 21.0182 37.2488C23.0184 39.249 25.3931 40.8356 28.0065 41.918C30.62 43.0005 33.421 43.5575 36.2498 43.5574Z"
                          fill="url(#paint1_linear_5291_145110)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_5291_145110"
                            x1="22.4506"
                            y1="102.565"
                            x2="19.3227"
                            y2="-11.3436"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#2A446F" />
                            <stop offset="0.354167" stopColor="#0078D4" />
                            <stop offset="1" stopColor="#8DC8E8" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_5291_145110"
                            x1="28.0058"
                            y1="43.5574"
                            x2="24.8239"
                            y2="-45.2169"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#2A446F" />
                            <stop offset="0.354167" stopColor="#0078D4" />
                            <stop offset="1" stopColor="#8DC8E8" />
                          </linearGradient>
                        </defs>
                      </svg>

                      <svg
                        className={styles["group7"]}
                        width="73"
                        height="103"
                        viewBox="0 0 73 103"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M72.357 79.7023C71.3563 70.9454 67.1808 62.8589 60.6205 56.9727C54.0602 51.0864 45.5702 47.8086 36.7564 47.7594C27.9427 47.7101 19.4164 50.8927 12.7908 56.7053C6.16509 62.5178 1.89946 70.557 0.80082 79.3022C0.511276 80.5698 0.421501 81.8749 0.534761 83.1702C1.00158 88.1717 4.53595 93.1065 11.1381 96.9079C25.2759 105.043 48.0831 105.043 62.1543 96.9079C68.6897 93.1065 72.1575 88.1717 72.5576 83.2371C72.6573 82.0553 72.5898 80.8653 72.357 79.7023Z"
                          fill="url(#paint0_linear_5291_145113)"
                        />
                        <path
                          d="M36.5448 44.0027C40.8051 44.0028 44.9697 42.7395 48.512 40.3726C52.0543 38.0058 54.8152 34.6417 56.4456 30.7057C58.0759 26.7697 58.5025 22.4387 57.6714 18.2603C56.8402 14.0819 54.7887 10.2438 51.7762 7.23135C48.7638 4.21889 44.9257 2.16738 40.7473 1.33625C36.5689 0.505125 32.2379 0.93171 28.3019 2.56206C24.366 4.19241 21.0018 6.9533 18.635 10.4956C16.2681 14.0379 15.0048 18.2025 15.0049 22.4628C15.0049 28.1755 17.2743 33.6543 21.3138 37.6938C25.3533 41.7333 30.8321 44.0027 36.5448 44.0027Z"
                          fill="url(#paint1_linear_5291_145113)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_5291_145113"
                            x1="22.7456"
                            y1="103.009"
                            x2="19.6175"
                            y2="-10.9042"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#2A446F" />
                            <stop offset="0.354167" stopColor="#0078D4" />
                            <stop offset="1" stopColor="#8DC8E8" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_5291_145113"
                            x1="28.3017"
                            y1="44.0027"
                            x2="25.1198"
                            y2="-44.7716"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#2A446F" />
                            <stop offset="0.354167" stopColor="#0078D4" />
                            <stop offset="1" stopColor="#8DC8E8" />
                          </linearGradient>
                        </defs>
                      </svg>

                      <svg
                        className={styles["group8"]}
                        width="150"
                        height="211"
                        viewBox="0 0 150 211"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M149.219 162.838C147.153 144.761 138.533 128.068 124.991 115.916C111.449 103.765 93.9223 96.9989 75.728 96.8972C57.5336 96.7954 39.9328 103.366 26.2554 115.364C12.5779 127.363 3.77239 143.959 1.50458 162.012C0.906408 164.628 0.720669 167.322 0.954067 169.996C1.91758 180.321 9.21387 190.508 22.8428 198.355C52.0275 215.149 99.1087 215.149 128.156 198.355C141.647 190.508 148.805 180.321 149.631 170.134C149.837 167.694 149.698 165.238 149.219 162.838Z"
                          fill="url(#paint0_linear_5291_145116)"
                        />
                        <path
                          d="M75.2926 89.1423C84.0871 89.1423 92.6841 86.5345 99.9965 81.6485C107.309 76.7626 113.008 69.818 116.374 61.693C119.739 53.5679 120.62 44.6273 118.904 36.0018C117.188 27.3763 112.953 19.4533 106.735 13.2346C100.516 7.016 92.5931 2.78105 83.9676 1.06534C75.3421 -0.650368 66.4015 0.230219 58.2764 3.59574C50.1514 6.96127 43.2068 12.6606 38.3209 19.9729C33.435 27.2853 30.8271 35.8823 30.8271 44.6768C30.8272 56.4698 35.512 67.7797 43.8508 76.1186C52.1897 84.4574 63.4996 89.1422 75.2926 89.1423Z"
                          fill="url(#paint1_linear_5291_145116)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_5291_145116"
                            x1="75.2901"
                            y1="96.896"
                            x2="75.2901"
                            y2="210.951"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#8DC8E8" />
                            <stop offset="1" stopColor="#0078D4" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_5291_145116"
                            x1="75.2928"
                            y1="0.210937"
                            x2="75.2928"
                            y2="89.1423"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#8DC8E8" />
                            <stop offset="1" stopColor="#0078D4" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              {/* svgs end */}
            </div>
          </div>
          {children}
          <Footer footerData={footerData} />
        </div>
      </div>
    </div>
  );
};

export default AboutFaq;


