// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OverviewCardContainer_frame-2018775831__GPTjc {
  padding: 0px 136.5px 300px 0px;
  display: flex;
  flex-direction: column;
  gap: 34px;
  align-items: flex-end;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  width: 1440px;
}

.OverviewCardContainer_frame-20187758312__dyPcg {
  padding: 0px 0px 50px 0px;
  display: flex;
  flex-direction: column;
  gap: 34px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.OverviewCardContainer_frame-2018775833__6y6sq {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 35px;
  align-items: flex-start;
  justify-content: flex-start;
}

.OverviewCardContainer_frame-2018775833__6y6sq > * {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(33% - 35px);
  max-width: calc(33% - 35px);
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/cards/OverviewCardContainer.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,qBAAqB;EACrB,2BAA2B;EAC3B,cAAc;EACd,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,uBAAuB;EACvB,uBAAuB;EACvB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,SAAS;EACT,uBAAuB;EACvB,2BAA2B;AAC7B;;AAEA;EACE,YAA0B;EAA1B,cAA0B;EAA1B,4BAA0B;EAC1B,2BAA2B;AAC7B","sourcesContent":[".frame-2018775831 {\n  padding: 0px 136.5px 300px 0px;\n  display: flex;\n  flex-direction: column;\n  gap: 34px;\n  align-items: flex-end;\n  justify-content: flex-start;\n  flex-shrink: 0;\n  position: relative;\n  width: 1440px;\n}\n\n.frame-20187758312 {\n  padding: 0px 0px 50px 0px;\n  display: flex;\n  flex-direction: column;\n  gap: 34px;\n  align-items: flex-start;\n  justify-content: center;\n  flex-shrink: 0;\n  position: relative;\n}\n\n.frame-2018775833 {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  gap: 35px;\n  align-items: flex-start;\n  justify-content: flex-start;\n}\n\n.frame-2018775833 > * {\n  flex: 1 0 calc(33% - 35px);\n  max-width: calc(33% - 35px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"frame-2018775831": `OverviewCardContainer_frame-2018775831__GPTjc`,
	"frame-20187758312": `OverviewCardContainer_frame-20187758312__dyPcg`,
	"frame-2018775833": `OverviewCardContainer_frame-2018775833__6y6sq`
};
export default ___CSS_LOADER_EXPORT___;
