// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logout_state-rest-item-logout__6zo-p,
.Logout_state-rest-item-logout__6zo-p * {
  box-sizing: border-box;
}

.Logout_state-rest-item-logout__6zo-p {
  background: var(--neutral-background-5-rest, #000000);
  padding: 14px 10px 14px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
}

.Logout_state-rest-item-logout__6zo-p:hover {
  background-color: #333;
}

.Logout_state-rest-item-logout__6zo-p:active {
  background-color: #242424;
}

.Logout_frame-59961__9WKWm {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.Logout_logout__wmCQn {
  color: #ffffff;
  text-align: center;
  font: 400 13px/20px "Segoe UI", sans-serif;
  font-style: normal;
  letter-spacing: 0.65px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/navigation/buttons/Logout.module.css"],"names":[],"mappings":"AAAA;;EAEE,sBAAsB;AACxB;;AAEA;EACE,qDAAqD;EACrD,4BAA4B;EAC5B,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,uBAAuB;EACvB,2BAA2B;EAC3B,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,mBAAmB;EACnB,2BAA2B;EAC3B,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,0CAA0C;EAC1C,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".state-rest-item-logout,\n.state-rest-item-logout * {\n  box-sizing: border-box;\n}\n\n.state-rest-item-logout {\n  background: var(--neutral-background-5-rest, #000000);\n  padding: 14px 10px 14px 10px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  align-items: flex-start;\n  justify-content: flex-start;\n  position: relative;\n  cursor: pointer;\n}\n\n.state-rest-item-logout:hover {\n  background-color: #333;\n}\n\n.state-rest-item-logout:active {\n  background-color: #242424;\n}\n\n.frame-59961 {\n  display: flex;\n  flex-direction: row;\n  gap: 4px;\n  align-items: center;\n  justify-content: flex-start;\n  flex-shrink: 0;\n  position: relative;\n}\n\n.logout {\n  color: #ffffff;\n  text-align: center;\n  font: 400 13px/20px \"Segoe UI\", sans-serif;\n  font-style: normal;\n  letter-spacing: 0.65px;\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"state-rest-item-logout": `Logout_state-rest-item-logout__6zo-p`,
	"frame-59961": `Logout_frame-59961__9WKWm`,
	"logout": `Logout_logout__wmCQn`
};
export default ___CSS_LOADER_EXPORT___;
