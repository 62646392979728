// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/edit-github.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../public/edit-github-hover.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../../public/edit-github-focus.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditGithub_button__mKAqj {
  width: 32px;
  height: 33px;
  border: none;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.EditGithub_button__mKAqj:hover {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

.EditGithub_button__mKAqj:focus {
  outline: none;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}`, "",{"version":3,"sources":["webpack://./src/components/pages/buttons/EditGithub.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,mDAAqD;AACvD;;AAEA;EACE,mDAA2D;AAC7D;;AAEA;EACE,aAAa;EACb,mDAA2D;AAC7D","sourcesContent":[".button {\n  width: 32px;\n  height: 33px;\n  border: none;\n  background: url('../../../../public/edit-github.svg');\n}\n\n.button:hover {\n  background: url('../../../../public/edit-github-hover.svg');\n}\n\n.button:focus {\n  outline: none;\n  background: url('../../../../public/edit-github-focus.svg');\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `EditGithub_button__mKAqj`
};
export default ___CSS_LOADER_EXPORT___;
