// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SideMenuClose_state-rest-type-collapse__O9uex,
.SideMenuClose_state-rest-type-collapse__O9uex * {
  box-sizing: border-box;
}
.SideMenuClose_state-rest-type-collapse__O9uex {
  width: 53px;
  height: 53px;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.SideMenuClose_rectangle-34656628__r0Z4P {
  background: var(--neutral-background-6-rest, #3d3d3d);
  border-radius: 200px;
  width: 53px;
  height: 53px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.SideMenuClose_chevron__VPG2K {
  position: absolute;
  left: 43px;
  top: 15px;
  transform: translate(-32px, 0px);
  overflow: visible;
}

.SideMenuClose_rectangle-34656628__r0Z4P:hover {
  background: var(--neutral-background-6-hover, #525252);
}

.SideMenuClose_rectangle-34656628__r0Z4P:active {
  background: var(--neutral-background-6-pressed, #333333);
}

.SideMenuClose_rectangle-34656628__r0Z4P:focus {
  background: var(--neutral-background-6-selected, #4c4c4c);
}`, "",{"version":3,"sources":["webpack://./src/components/navigation/sidemenu/SideMenuClose.module.css"],"names":[],"mappings":"AAAA;;EAEE,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gCAAgC;AAClC;AACA;EACE,qDAAqD;EACrD,oBAAoB;EACpB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,QAAQ;AACV;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,gCAAgC;EAChC,iBAAiB;AACnB;;AAEA;EACE,sDAAsD;AACxD;;AAEA;EACE,wDAAwD;AAC1D;;AAEA;EACE,yDAAyD;AAC3D","sourcesContent":[".state-rest-type-collapse,\n.state-rest-type-collapse * {\n  box-sizing: border-box;\n}\n.state-rest-type-collapse {\n  width: 53px;\n  height: 53px;\n  position: relative;\n  transition: all 0.2s ease-in-out;\n}\n.rectangle-34656628 {\n  background: var(--neutral-background-6-rest, #3d3d3d);\n  border-radius: 200px;\n  width: 53px;\n  height: 53px;\n  position: absolute;\n  left: 0px;\n  top: 0px;\n}\n.chevron {\n  position: absolute;\n  left: 43px;\n  top: 15px;\n  transform: translate(-32px, 0px);\n  overflow: visible;\n}\n\n.rectangle-34656628:hover {\n  background: var(--neutral-background-6-hover, #525252);\n}\n\n.rectangle-34656628:active {\n  background: var(--neutral-background-6-pressed, #333333);\n}\n\n.rectangle-34656628:focus {\n  background: var(--neutral-background-6-selected, #4c4c4c);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"state-rest-type-collapse": `SideMenuClose_state-rest-type-collapse__O9uex`,
	"rectangle-34656628": `SideMenuClose_rectangle-34656628__r0Z4P`,
	"chevron": `SideMenuClose_chevron__VPG2K`
};
export default ___CSS_LOADER_EXPORT___;
