import { useMsal } from "@azure/msal-react";
import { apiRequest } from "../../../auth/authConfig";
import styles from "./Login.module.css";
import msalInstance from "../../../auth";
import { useAuthContext } from "../../../context/AuthProvider";

const Login = () => {
  const { instance } = useMsal();
  const { dispatch } = useAuthContext();
  return (
    <div
      className={`${styles[`state-rest-item-login`]} focus-on-tab`}
      onClick={async () => {
        try {
          const idToken = await msalInstance.loginPopup({
            scopes: [
              "openid",
              "offline_access",
              "https://jumpstartexternal.onmicrosoft.com/b2c_1_all/user_impersonation",
            ],
          });

          if (idToken) {
            sessionStorage.setItem("authToken", idToken);
            dispatch({
              type: "SET_TOKEN",
              payload: {
                token: idToken,
              },
            });
          }
          const account = msalInstance.getActiveAccount();
          if (account) {
            const tokenResponse = await msalInstance.acquireTokenSilent({
              scopes: [
                "openid",
                "offline_access",
                "https://jumpstartexternal.onmicrosoft.com/b2c_1_all/user_impersonation",
              ],
              account: account,
            });

            sessionStorage.setItem(
              "username",
              account.idTokenClaims.given_name
            );
            dispatch({
              type: "SET_NAME",
              payload: {
                name: account.idTokenClaims.given_name,
              },
            });
          }
        } catch (err) {
          console.log(err);
        }
      }}
      role="button"
      tabIndex="0"
    >
      <div className={styles["frame-59961"]}>
        <div className={styles["login"]}>Login </div>
      </div>
    </div>
  );
};

export default Login;
