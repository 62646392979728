// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CopyToClipboard_frame-2018775999__o4cMN,
.CopyToClipboard_frame-2018775999__o4cMN * {
  box-sizing: border-box;
}

.CopyToClipboard_frame-2018775999__o4cMN {
  background: var(--neutral-background-7-rest, #242424);
  border-radius: 0.25rem;
  padding: 0.625rem;
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  position: relative;
}

.CopyToClipboard_frame-2018775999__o4cMN:hover {
  background: var(--neutral-background-7-hover, #383838);
}

.CopyToClipboard_frame-2018775999__o4cMN:active {
  background: var(--neutral-background-2-pressed, #242424);
}

.CopyToClipboard_frame-2018775999__o4cMN:focus {
  border-radius: 0.25rem;
  border-style: dashed;
  border-color: #ffffff;
  border-width: 0.0625rem;
}

.CopyToClipboard_frame__RM9Gn {
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/buttons/CopyToClipboard.module.css"],"names":[],"mappings":"AAAA;;EAEE,sBAAsB;AACxB;;AAEA;EACE,qDAAqD;EACrD,sBAAsB;EACtB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,sDAAsD;AACxD;;AAEA;EACE,wDAAwD;AAC1D;;AAEA;EACE,sBAAsB;EACtB,oBAAoB;EACpB,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".frame-2018775999,\n.frame-2018775999 * {\n  box-sizing: border-box;\n}\n\n.frame-2018775999 {\n  background: var(--neutral-background-7-rest, #242424);\n  border-radius: 0.25rem;\n  padding: 0.625rem;\n  display: flex;\n  flex-direction: row;\n  gap: 0.625rem;\n  align-items: center;\n  justify-content: center;\n  width: 2rem;\n  height: 2rem;\n  position: relative;\n}\n\n.frame-2018775999:hover {\n  background: var(--neutral-background-7-hover, #383838);\n}\n\n.frame-2018775999:active {\n  background: var(--neutral-background-2-pressed, #242424);\n}\n\n.frame-2018775999:focus {\n  border-radius: 0.25rem;\n  border-style: dashed;\n  border-color: #ffffff;\n  border-width: 0.0625rem;\n}\n\n.frame {\n  flex-shrink: 0;\n  position: relative;\n  overflow: visible;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"frame-2018775999": `CopyToClipboard_frame-2018775999__o4cMN`,
	"frame": `CopyToClipboard_frame__RM9Gn`
};
export default ___CSS_LOADER_EXPORT___;
