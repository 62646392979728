// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavBar_navbarContainer__Ei8zS {
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 1280px;
  max-width: 1440px;
  margin: 0 auto;
}

.NavBar_leftItems__56A\\+F {
  display: flex;
  align-items: center;
}

.NavBar_rightItems__XxwE8 {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/navigation/NavBar.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".navbarContainer {\n  background-color: black;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  min-width: 1280px;\n  max-width: 1440px;\n  margin: 0 auto;\n}\n\n.leftItems {\n  display: flex;\n  align-items: center;\n}\n\n.rightItems {\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbarContainer": `NavBar_navbarContainer__Ei8zS`,
	"leftItems": `NavBar_leftItems__56A+F`,
	"rightItems": `NavBar_rightItems__XxwE8`
};
export default ___CSS_LOADER_EXPORT___;
