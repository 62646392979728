import React, { useState } from 'react';
import styles from './CopyCodeToClipboard.module.css';

const CopyCodeToClipboard = ({ onClick }) => {
  const [state, setState] = useState('rest');

  const rest = <svg
    className={styles["icon"]}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 2C6.89543 2 6 2.89543 6 4V14C6 15.1046 6.89543 16 8 16H14C15.1046 16 16 15.1046 16 14V4C16 2.89543 15.1046 2 14 2H8ZM7 4C7 3.44772 7.44772 3 8 3H14C14.5523 3 15 3.44772 15 4V14C15 14.5523 14.5523 15 14 15H8C7.44772 15 7 14.5523 7 14V4ZM4 6.00001C4 5.25973 4.4022 4.61339 5 4.26758V14.5C5 15.8807 6.11929 17 7.5 17H13.7324C13.3866 17.5978 12.7403 18 12 18H7.5C5.567 18 4 16.433 4 14.5V6.00001Z"
      fill="#D6D6D6"
    />
  </svg>;

  const hover = <svg
    className={styles["icon"]}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 4C6 2.89543 6.89543 2 8 2H14C15.1046 2 16 2.89543 16 4V14C16 15.1046 15.1046 16 14 16H8C6.89543 16 6 15.1046 6 14V4ZM4 6.00001C4 5.25973 4.4022 4.61339 5 4.26758V14.5C5 15.8807 6.11929 17 7.5 17H13.7324C13.3866 17.5978 12.7403 18 12 18H7.5C5.567 18 4 16.433 4 14.5V6.00001Z"
      fill="#D6D6D6"
    />
  </svg>;

  return (
    <span
      className={styles.container}
      onMouseEnter={() => setState('hover')}
      onMouseLeave={() => setState('rest')}
      onClick={onClick}
    >
      {
        state === 'rest' && rest
      }
      {
        state === 'hover' && hover
      }
    </span>
  );
};

export default CopyCodeToClipboard;

