// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 120px;
  padding: 0px 63.79px 0px 141px;
}

.error-container .error-summary {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 120px;
  gap: 22px;
}

.error-container .error-summary .error-title {
  color: var(--Neutral-Foreground-3-Error-Error, #dc626d);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 2.24px;
}

.error-container .error-summary .error-header {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.error-container .error-summary .error-description {
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  line-height: 150%;
  letter-spacing: 0.6px;
}

.error-container .error-summary .back-to-home {
  display: flex;
  padding: 6px 35px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 4px;
  cursor: pointer;
  background: linear-gradient(90deg, #1f5177 0%, #0078d4 100%);
}

.error-container .error-summary .back-to-home .text {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.42px;
}
`, "",{"version":3,"sources":["webpack://./src/components/error/error.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,UAAU;EACV,8BAA8B;AAChC;;AAEA;EACE,oBAAoB;EACpB,sBAAsB;EACtB,uBAAuB;EACvB,qBAAqB;EACrB,SAAS;AACX;;AAEA;EACE,uDAAuD;EACvD,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,kBAAkB;EAClB,eAAe;EACf,4DAA4D;AAC9D;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;AACxB","sourcesContent":[".error-container {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-end;\n  gap: 120px;\n  padding: 0px 63.79px 0px 141px;\n}\n\n.error-container .error-summary {\n  display: inline-flex;\n  flex-direction: column;\n  align-items: flex-start;\n  padding-bottom: 120px;\n  gap: 22px;\n}\n\n.error-container .error-summary .error-title {\n  color: var(--Neutral-Foreground-3-Error-Error, #dc626d);\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 150%;\n  letter-spacing: 2.24px;\n}\n\n.error-container .error-summary .error-header {\n  font-size: 32px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 150%;\n}\n\n.error-container .error-summary .error-description {\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 350;\n  line-height: 150%;\n  letter-spacing: 0.6px;\n}\n\n.error-container .error-summary .back-to-home {\n  display: flex;\n  padding: 6px 35px;\n  justify-content: center;\n  align-items: center;\n  gap: 5px;\n  border-radius: 4px;\n  cursor: pointer;\n  background: linear-gradient(90deg, #1f5177 0%, #0078d4 100%);\n}\n\n.error-container .error-summary .back-to-home .text {\n  text-align: center;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 24px;\n  letter-spacing: 0.42px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
