import React from "react";
import styles from "./AdaptiveCloud.module.css";
import Blog from "../blogs/Blog";
import Footer from "../../navigation/footer/Footer";
import YouTube from "react-youtube";

const AdaptiveCloud = ({ blogs, footerData }) => {
  // Define the options for the YouTube player
  const opts = {
    // Set the height and width of the player
    height: 640,
    width: 1280,
    // Set the player parameters
    playerVars: {
      // Enable autoplay
      autoplay: 0,
      // Enable fullscreen button
      fs: 1,
      // Disable related videos
      rel: 0,
      // Disable video information
      showinfo: 1,
      controls: 1,
      modestbranding: 1,
      allowfullscreen: 1,
    },
  };

  let sortedBlogs = [];
  if (blogs.length > 0) {
    sortedBlogs = blogs.sort((a, b) => new Date(b.PubDate) - new Date(a.PubDate));
  }

  const [showPlayer, setShowPlayer] = React.useState(false);
  const youtubeRef = React.useRef(null);

  return (
    <div className={styles["wip-video-adaptive-cloud-v-3"]}>
      <div className={styles["frame-2018776021"]}>
        <div className={styles["frame-2018775814"]}>
          <div className={styles["watch-our-video"]}>
            <div className={styles["video-player"]}>
              <div className={styles["frame-2018776022"]}>
                {
                  !showPlayer && <img
                    src='images/adaptive-cloud/Video-Thumbnail.png'
                    alt='A series of video clips depicting edge and cloud compute environments.'
                    width={1280}
                    height={640}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: -100
                    }}
                  />
                }
                <div className={styles["rectangle-34656648"]}></div>
                <button
                  aria-label="Play"
                  onClick={() => {
                    setShowPlayer(true);
                    setTimeout(() => {
                      youtubeRef.current.getInternalPlayer().playVideo();
                    }, 1000);
                  }}
                  className={styles["frame"]}
                >
                </button>
                <div className={styles["content"]}>
                  <div className={styles["watch-our-video2"]}>
                    WATCH OUR VIDEO{" "}
                  </div>
                  <div className={styles["azure-s-adaptive-cloud-approach"]}>
                    Azure’s adaptive cloud approach{" "}
                  </div>
                  <div className={styles["frame-2018775983"]}>
                    <div
                      className={
                        styles[
                        "thrive-in-dynamic-environments-by-unifying-teams-sites-and-systems"
                        ]
                      }
                    >
                      Thrive in dynamic environments by unifying teams, sites,
                      and systems​.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                position: 'absolute',
                top: 140,
                left: 'calc(50% - 640px)',
              }}>
              {
                showPlayer && <YouTube
                  ref={youtubeRef}
                  videoId={"0zc_O7U2YqM"}
                  opts={opts}
                />
              }
            </div>
          </div>
          <div className={styles["azure-edge-computing-v-3"]}>
            <div className={styles["frame-2018776036"]}>
              <div className={styles["content2"]}>
                <div className={styles["discover"]}>DISCOVER </div>
                <div className={styles["adaptive-cloud"]}>Adaptive cloud </div>
                <div className={styles["frame-20187759832"]}>
                  <div
                    className={
                      styles[
                      "an-adaptive-cloud-approach-unifies-siloed-teams-distributed-sites-and-sprawling-systems-enabling-organizations-to-leverage-cloud-native-and-ai-to-work-simultaneously-across-hybrid-multicloud-edge-distributed-computing-and-io-t"
                      ]
                    }
                  >
                    An adaptive cloud approach unifies siloed teams, distributed
                    sites, and sprawling systems, enabling organizations to
                    leverage cloud-native and AI to work simultaneously across
                    hybrid, multicloud, edge, distributed computing, and IoT.{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles["group-1321317183"]}>
              <img
                src='webp/Illustration_Adaptive-cloud-01.webp'
                alt='Illustration_Adaptive-cloud-01'
                style={{
                  position: 'absolute',
                  top: 156,
                  left: 243
                }}
              />
            </div>
          </div>
          <div className={styles["frame-2018776136"]}>
            <div className={styles["frame-44"]}>
              <div className={styles["design"]}>
                <div className={styles["ai-automation"]}>AI + AUTOMATION </div>
                <div className={styles["operate-with-ai-enhanced-management"]}>
                  Operate with AI-enhanced management and security{" "}
                </div>
                <div className={styles["frame-20187759832"]}>
                  <div
                    className={
                      styles[
                      "elevate-it-capabilities-and-focus-on-strategic-work-by-abstracting-resources-from-distributed-locations-into-one-operations-and-management-layer-with-ai-assistance-and-automation"
                      ]
                    }
                  >
                    Elevate IT capabilities, and focus on strategic work by
                    abstracting resources from distributed locations into one
                    operations and management layer with AI assistance and
                    automation.​{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles["frame-2018776054"]}>
              <div className={styles["group-1321317093"]}>
                <img
                  src='webp/Illustration_Adaptive-cloud-02.webp'
                  alt='Illustration_Adaptive-cloud-02'
                  style={{
                    position: 'absolute',
                    top: -299,
                    left: -266
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles["frame-2018776139"]}>
            <div className={styles["group-1321317176"]}>
              <img
                src='webp/Illustration_Adaptive-cloud-03.webp'
                alt='Illustration_Adaptive-cloud-03'
                style={{
                  position: 'absolute',
                  top: -216,
                  left: -240
                }}
              />
            </div>
            <div className={styles["frame-44"]}>
              <div className={styles["design2"]}>
                <div className={styles["limitless-reach"]}>
                  LIMITLESS REACH{" "}
                </div>
                <div
                  className={
                    styles[
                    "rapidly-develop-and-scale-applications-across-boundaries"
                    ]
                  }
                >
                  Rapidly develop and scale applications across boundaries ​{" "}
                </div>
                <div className={styles["frame-20187759832"]}>
                  <div
                    className={
                      styles[
                      "transcend-legacy-system-constraints-with-composable-cloud-native-tool-chains-containers-and-data-services-everywhere"
                      ]
                    }
                  >
                    Transcend legacy system constraints with composable
                    cloud-native tool chains, containers, and data services
                    everywhere. ​{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles["frame-2018776140"]}>
            <div className={styles["frame-44"]}>
              <div className={styles["design"]}>
                <div className={styles["connected-insights"]}>
                  CONNECTED INSIGHTS{" "}
                </div>
                <div
                  className={
                    styles[
                    "cultivate-data-and-insights-across-physical-operations"
                    ]
                  }
                >
                  Cultivate data and insights across physical operations​{" "}
                </div>
                <div className={styles["frame-20187759832"]}>
                  <div
                    className={
                      styles[
                      "supercharge-physical-operations-with-a-unified-data-foundation-enabling-efficient-workflows-predictive-insights-and-cost-effective-resource-utilization"
                      ]
                    }
                  >
                    Supercharge physical operations with a unified data
                    foundation, enabling efficient workflows, predictive
                    insights, and cost-effective resource utilization.​{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles["frame-2018776141"]}>
              <div className={styles["group-1321317109"]}>
                <img
                  src='webp/Illustration_Adaptive-cloud-04.webp'
                  alt='Illustration_Adaptive-cloud-04'
                  style={{
                    position: 'absolute',
                    top: -407,
                    left: -352
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles["frame-2018775831"]}>
            <div className={styles["content2"]}>
              <div className={styles["related-blogs"]}>RELATED BLOGS </div>
              <div
                className={
                  styles["stay-informed-with-the-latest-announcements"]
                }
              >
                Stay informed with the latest announcements{" "}
              </div>
            </div>
            
            <div className={styles["blogs"]}>
              <div className={styles["frame-2018775832"]}>
                <div className={styles["frame-20187758322"]}>
                  <div className={styles["frame-2018776026"]}>
                    {
                      sortedBlogs.map((blog, index) => <Blog key={index} blog={blog} />)
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer footerData={footerData} />
        </div>
      </div>
    </div>
  );
};

export default AdaptiveCloud;
