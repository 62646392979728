// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SideMenuOpen_state-rest-type-expand__epNju,
.SideMenuOpen_state-rest-type-expand__epNju * {
    box-sizing: border-box;
}

.SideMenuOpen_state-rest-type-expand__epNju {
    width: 53px;
    height: 53px;
    position: relative;
    transition: all 0.2s ease-in-out;
}

.SideMenuOpen_rectangle-34656628__\\+LlLg {
    background: var(--neutral-background-6-rest, #3d3d3d);
    border-radius: 10px 200px 200px 10px;
    width: 53px;
    height: 41px;
    position: absolute;
    left: 0px;
    top: 6px;
}

.SideMenuOpen_chevron__apOof {
    position: absolute;
    left: 21px;
    top: 39px;
    transform: translate(0px, -24px);
    overflow: visible;
}

.SideMenuOpen_rectangle-34656628__\\+LlLg:hover {
    background: var(--neutral-background-6-hover, #525252);
}

.SideMenuOpen_rectangle-34656628__\\+LlLg:active {
    background: var(--neutral-background-6-pressed, #333333);
}

.SideMenuOpen_rectangle-34656628__\\+LlLg:focus {
    background: var(--neutral-background-6-selected, #4c4c4c);
}`, "",{"version":3,"sources":["webpack://./src/components/navigation/sidemenu/SideMenuOpen.module.css"],"names":[],"mappings":"AAAA;;IAEI,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,gCAAgC;AACpC;;AAEA;IACI,qDAAqD;IACrD,oCAAoC;IACpC,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,QAAQ;AACZ;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,gCAAgC;IAChC,iBAAiB;AACrB;;AAEA;IACI,sDAAsD;AAC1D;;AAEA;IACI,wDAAwD;AAC5D;;AAEA;IACI,yDAAyD;AAC7D","sourcesContent":[".state-rest-type-expand,\n.state-rest-type-expand * {\n    box-sizing: border-box;\n}\n\n.state-rest-type-expand {\n    width: 53px;\n    height: 53px;\n    position: relative;\n    transition: all 0.2s ease-in-out;\n}\n\n.rectangle-34656628 {\n    background: var(--neutral-background-6-rest, #3d3d3d);\n    border-radius: 10px 200px 200px 10px;\n    width: 53px;\n    height: 41px;\n    position: absolute;\n    left: 0px;\n    top: 6px;\n}\n\n.chevron {\n    position: absolute;\n    left: 21px;\n    top: 39px;\n    transform: translate(0px, -24px);\n    overflow: visible;\n}\n\n.rectangle-34656628:hover {\n    background: var(--neutral-background-6-hover, #525252);\n}\n\n.rectangle-34656628:active {\n    background: var(--neutral-background-6-pressed, #333333);\n}\n\n.rectangle-34656628:focus {\n    background: var(--neutral-background-6-selected, #4c4c4c);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"state-rest-type-expand": `SideMenuOpen_state-rest-type-expand__epNju`,
	"rectangle-34656628": `SideMenuOpen_rectangle-34656628__+LlLg`,
	"chevron": `SideMenuOpen_chevron__apOof`
};
export default ___CSS_LOADER_EXPORT___;
