import React, { useState } from "react";
import styles from "./CreateChildPage.module.css";

const CreateChildPage = (props) => {
    return (
        <button
            title="Create a child page"
            onClick={() => {
                window.open(props.href, '_blank');
            }}
            className={styles["button"]}
            tabIndex="0"
        >
        </button>
    );
};

export default CreateChildPage;
