import { useState } from "react";
import CopyButtonTooltip from "../CopyButtonTooltip/CopyButtonTooltip";

const CopyButton = ({ uniqueKey }) => {
  const [isUrlCopied, setIsUrlCopied] = useState([]);

  return (
    <CopyButtonTooltip
      title={"Copied!"}
      placement={"right"}
      open={
        isUrlCopied[uniqueKey] === undefined ? false : isUrlCopied[uniqueKey]
      }
    >
      <span className="code-copy-btn">
        <img
          key={uniqueKey}
          className="copy-btn"
          width={"23px"}
          src="./icon/copy-square.svg"
          alt="Copy"
        />
        <img
          onClick={() => {
            setIsUrlCopied((prev) => ({
              ...prev,
              [uniqueKey]: true,
            }));
            setTimeout(() => {
              setIsUrlCopied((prev) => ({
                ...prev,
                [uniqueKey]: false,
              }));
            }, 2000);
          }}
          key={uniqueKey}
          className="hovered-copy-btn"
          width={"23px"}
          src="./icon/hovered-copy.svg"
          alt="Copy"
        />
      </span>
    </CopyButtonTooltip>
  );
};

export default CopyButton;
