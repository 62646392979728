import React, { useEffect, useState } from "react";
import "./MenuDrawer.css";
import MenuCard from "./MenuCard";
import SubMenuItem from "./SubMenuItem";
import { Link } from "react-router-dom";

const MenuDrawer = ({
  menuItem,
  setQueHideMenuDrawer,
  setSelectedMenuItem,
  setIsMenuDrawerVisible,
  menuItems,
}) => {
  const [subMenuItems, setSubMenuItems] = useState([]);
  const [selectedSubMenuItem, setSelectedSubMenuItem] = useState(null);
  const [selectedMenuCard, setSelectedMenuCard] = useState(null);

  useEffect(() => {
    if (menuItem && menuItem.Items && menuItem.Items.length > 0) {
      setSubMenuItems(menuItem.Items);
      setSelectedSubMenuItem(menuItem.Items[0]);
      setSelectedMenuCard(null);
    } else {
      setSubMenuItems([]);
      setSelectedSubMenuItem(null);
      setSelectedMenuCard(null);
    }
  }, [menuItem]);

  const handleOnClick = (menuItem) => {
    setSelectedSubMenuItem(menuItem);
    setSelectedMenuCard(null);
  };

  const handleOnClickMenuCard = (menuItem) => {
    setQueHideMenuDrawer(true);
    setSelectedMenuCard((prev) => {
      if (prev !== menuItem) {
        return menuItem;
      }
      return null;
    });
  };

  const menuCard = (item, index) => {
    if (item.Link === undefined || item.Link === null || item.Link === "") {
      return (
        <MenuCard
          item={item}
          selectedMenuCard={selectedMenuCard}
          setSelectedMenuCard={handleOnClickMenuCard}
        >
          {item.Description}
        </MenuCard>
      );
    } else if (item.Link.includes("http")) {
      return (
        <a href={item.Link} target="_blank" rel="noopener noreferrer">
          <MenuCard
            item={item}
            selectedMenuCard={selectedMenuCard}
            setSelectedMenuCard={handleOnClickMenuCard}
          >
            {item.Description}
          </MenuCard>
        </a>
      );
    } else {
      return (
        <Link
          to={item.Link}
          onClick={() => {
            setQueHideMenuDrawer(true);
            setIsMenuDrawerVisible(menuItems.map(() => false));
          }}
        >
          <MenuCard
            item={item}
            selectedMenuCard={selectedMenuCard}
            setSelectedMenuCard={handleOnClickMenuCard}
          >
            {item.Description}
          </MenuCard>
        </Link>
      );
    }
  };

  return (
    <div className="frame-2018775984">
      <div className="sub-menu">
        <div className="sub-menu-body">
          {subMenuItems.map((subMenuItem, index) => {
            return (
              <SubMenuItem
                isSelected={selectedSubMenuItem === subMenuItem}
                onClick={() => handleOnClick(subMenuItem)}
              >
                {subMenuItem.Title}
              </SubMenuItem>
            );
          })}
        </div>
      </div>
      <div className="frame-2018775955">
        <div className="frame-2018775954">
          {selectedSubMenuItem && (
            <>
              <div className="frame-59895">
                <div className="overview3">{selectedSubMenuItem.Title}</div>
                <Link
                  to={selectedSubMenuItem.Link || ""}
                  onClick={() => {
                    setQueHideMenuDrawer(true);
                    setIsMenuDrawerVisible(menuItems.map(() => false));
                  }}
                >
                  <div className="view-all">View all </div>
                </Link>
              </div>
              <div className="description">
                {selectedSubMenuItem.Description}
              </div>
            </>
          )}
        </div>
        <div className="frame-59653">
          <div className="row-1">
            {selectedSubMenuItem &&
              selectedSubMenuItem.Items.map((item, index) => {
                return menuCard(item, index);
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuDrawer;
