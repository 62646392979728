import styles from "./Community.module.css";
import Footer from "../../navigation/footer/Footer";
import OverviewCard from "../cards/OverviewCard";


const Community = ({ node, footerData }) => {
  return (
    <div className={styles["complete-community"]}>
      <div className={styles["frame-2018776051"]}>
        <div className={styles["frame-2018775814"]}>
          <div className={styles["hero-images"]}>
            <div className={styles["frame-1"]}>
              <div className={styles["carousel-1"]}></div>
            </div>
            <div className={styles["group-1010107217"]}>
              <div className={styles["take-part-in-jumpstart"]}>
                Take part in Jumpstart{" "}
              </div>
              <div className={styles["community"]}>COMMUNITY </div>
              <div className={styles["explore-different-ways-to-get-involved"]}>
                Explore different ways to get involved.{" "}
              </div>
            </div>
            <div className={styles["group-1321317147"]}>
            <img
                src='webp/Illustration_Community.webp'
                alt='Illustration_Contribute'
                style={{
                  position: 'absolute',
                  top: 145,
                  left: 580
                }}
              />
            </div>
          </div>
          <div className={styles["frame-2018775831"]}>
            {
              node.Items.map((item, index) => {
                return (<>
                  <div className={styles["group-1010107219"]}>
                    <div className={styles["contribute"]}>{item.Title} </div>
                    <div
                      className={
                        styles[
                        "arc-jumpstart-is-community-driven-and-committed-to-open-source-whether-you-re-a-first-timer-or-seasoned-contributor-view-our-guides-and-jump-in-on-the-action"
                        ]
                      }
                    >
                      {item.Description}
                    </div>
                  </div>
                  <div className={styles["frame-2018775835"]}>
                    <div className={styles["frame-2018775833"]}>
                      {
                        item.Items.map((subItem, index) => (
                          <OverviewCard key={index} node={subItem} />
                        ))
                      }
                    </div>
                  </div>
                </>)
              })
            }
          </div>
          <Footer footerData={footerData} />
        </div>
      </div>
    </div>
  );
};

export default Community;

