import React, { useState } from "react";
import styles from "./ReportBug.module.css";

const ReportBug = (props) => {
    return (
        <button
            title="Report a bug"
            onClick={() => {
                window.open(props.href, '_blank');
            }}
            className={styles["button"]}
            tabIndex="0"
        >
        </button>
    );
};

export default ReportBug;
