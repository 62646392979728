// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.code-block-container {
    position: relative;
    overflow: scroll;

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: #909294;
        border-radius: 20px;
    }

    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        display: block;
    }
}

.code-block-header {
    background-color: #3c414a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 10px;
    margin-bottom: -8px;
}

.code-block-body {
    position: relative;
}

.copy-code-successfully-toast {
    position: absolute;
    top: 3px;
    right: 3px;
}`, "",{"version":3,"sources":["webpack://./src/components/Overrides/CodeBlock.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;;IAEhB;QACI,6BAA6B;IACjC;;IAEA;QACI,mBAAmB;QACnB,mBAAmB;IACvB;;IAEA;QACI,UAAU;QACV,WAAW;QACX,cAAc;IAClB;AACJ;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,UAAU;AACd","sourcesContent":[".code-block-container {\n    position: relative;\n    overflow: scroll;\n\n    ::-webkit-scrollbar-track {\n        background-color: transparent;\n    }\n\n    ::-webkit-scrollbar-thumb {\n        background: #909294;\n        border-radius: 20px;\n    }\n\n    ::-webkit-scrollbar {\n        width: 7px;\n        height: 7px;\n        display: block;\n    }\n}\n\n.code-block-header {\n    background-color: #3c414a;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 2px 10px;\n    margin-bottom: -8px;\n}\n\n.code-block-body {\n    position: relative;\n}\n\n.copy-code-successfully-toast {\n    position: absolute;\n    top: 3px;\n    right: 3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
