import styles from "./ReleaseNotes.module.css";
import Footer from "../../navigation/footer/Footer";

const ReleaseNotes = ({ children, footerData, node }) => {
  return (
    <div className={styles["complete-about-release-notes"]}>
      <div className={styles["frame-2018776051"]}>
        <div className={styles["frame-2018775814"]}>
          <div className={styles["hero-images"]}>
            <div className={styles["frame-1"]}>
              <div className={styles["carousel-1"]}></div>
            </div>
            <div className={styles["group-1010107217"]}>
              <div className={styles["get-the-latest-updates"]}>
                Get the latest updates{" "}
              </div>
              <div className={styles["release-notes"]}>RELEASE NOTES </div>
              <div
                className={
                  styles[
                  "even-small-changes-can-have-a-big-impact-see-what-s-new-in-this-release"
                  ]
                }
              >
                Even small changes can have a big impact. See what’s new in this
                release.{" "}
              </div>
            </div>
            <div className={styles["frame-2018776089"]}>
            <img
                  src='webp/Illustration_Release-notes.webp'
                  alt='Illustration_Release-notes'
                  style={{
                    position: 'absolute',
                    top: -108,
                    left: 67
                  }}
                />
            </div>
          </div>
          {children}
          <Footer footerData={footerData} />
        </div>
      </div>
    </div>
  );
};

export default ReleaseNotes;


