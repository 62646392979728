import styles from "./Search.module.css";

const Search = ({ isSearchDrawerVisible, toggleSearchDrawer }) => {
  return (
    <div
      className={`${
        styles[
          `state-rest-item-search${isSearchDrawerVisible ? "-selected" : ""}`
        ]
      } focus-on-tab`}
      onClick={() => {
        toggleSearchDrawer();
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === "Space") toggleSearchDrawer();
      }}
      role="button"
      tabIndex="0"
    >
      <div className={styles["frame-59941"]}>
        <div className={styles["search"]}>Search </div>
        <span className={styles["frame"]}></span>
      </div>
    </div>
  );
};

export default Search;
