import React, { useState } from "react";
import styles from "./HomeBlog.module.css";

const HomeBlog = ({ blog }) => {
    const { Link, PubDate, Image, Title, BlogType, Text } = blog;
    const [hovering, setHovering] = useState(false);

    let title = Title;
    if(title.length > 70) {
        title = title.slice(0, 70);
        const words = title.split(" ");
        title = words.slice(0, words.length - 1).join(" ") + "...";
    }

    let text = Text;
    if(text.length > 200) {
        text = text.slice(0, 200);
        const words = text.split(" ");
        text = words.slice(0, words.length - 1).join(" ") + "...";
    }

    return (
        <a
            href={Link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ zIndex: 1000 }}
        >
            <div className={styles["blog-cards"]} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
                {
                    hovering && <div className={styles["glow"]}></div>
                }
                <div className={styles["card-with-pic"]}>
                    <div className={styles["publish-date"]}>
                        {PubDate.toUpperCase()}
                    </div>
                    <img
                        className={styles["image"]}
                        src={`images/home/${Image}`}
                        alt={Title}
                    />
                    <div className={styles["body"]}>
                        <div className={styles["title-container"]}>
                            <div className={styles["title"]}>
                                <div className={styles["title-text"]}>
                                    {title}
                                    <br />
                                </div>
                            </div>
                            <div className={styles["blog-type-container"]}>
                                <div className={styles["blog-type"]}>
                                    <div className={styles["blog-type-text"]}>
                                        {BlogType.toUpperCase()}
                                    </div>
                                </div>
                            </div>
                            <div className={styles["text-container"]}>
                                <div className={styles["text"]}>
                                    {text}
                                </div>
                            </div>
                            <svg
                                className={styles["svg"]}
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8.84487 2.80593C8.64493 2.61547 8.3284 2.62317 8.13793 2.82311C7.94747 3.02307 7.9552 3.33955 8.15513 3.53001L12.3227 7.49973H2.5C2.22386 7.49973 2 7.7236 2 7.99973C2 8.27587 2.22386 8.49973 2.5 8.49973H12.3228L8.15513 12.4695C7.9552 12.66 7.94747 12.9765 8.13793 13.1765C8.3284 13.3764 8.64493 13.3841 8.84487 13.1937L13.7908 8.48253C13.9034 8.37527 13.9701 8.2384 13.9907 8.0962C13.9968 8.065 14 8.03273 14 7.99973C14 7.96673 13.9968 7.9344 13.9907 7.90313C13.97 7.761 13.9033 7.62427 13.7908 7.51707L8.84487 2.80593Z"
                                    fill="#D6D6D6"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    )
}

export default HomeBlog;
