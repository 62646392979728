import React, { useState } from "react";
import styles from "./EditGithub.module.css";

const EditGithub = (props) => {
  return (
    <button
      title="Edit this page on GitHub"
      onClick={() => {
        window.open(props.href, '_blank');
      }}
      className={styles["button"]}
      tabIndex="0"
    >
    </button>
  );
};

export default EditGithub;
