// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-italics {
    font: 400 16px/24px "Segoe UI", sans-serif;
    font-style: italic;
}

.custom-italics strong {
    color: var(--neutral-text-text-cta, #50e6ff);
}

.custom-italics code {
    color: #FFA38B;
    font-family: 'Source Code Pro', monospace;    
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 24.49px;
    letter-spacing: 0.48px;
}`, "",{"version":3,"sources":["webpack://./src/components/Overrides/Italics.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,kBAAkB;AACtB;;AAEA;IACI,4CAA4C;AAChD;;AAEA;IACI,cAAc;IACd,yCAAyC;IACzC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,oBAAoB;IACpB,sBAAsB;AAC1B","sourcesContent":[".custom-italics {\n    font: 400 16px/24px \"Segoe UI\", sans-serif;\n    font-style: italic;\n}\n\n.custom-italics strong {\n    color: var(--neutral-text-text-cta, #50e6ff);\n}\n\n.custom-italics code {\n    color: #FFA38B;\n    font-family: 'Source Code Pro', monospace;    \n    font-size: 16px;\n    font-style: italic;\n    font-weight: 400;\n    line-height: 24.49px;\n    letter-spacing: 0.48px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
