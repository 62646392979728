import React from "react";
import { Link } from "react-router-dom";
import styles from "./HomeCard.module.css";

const HomeCard = (props) => {
    const { Title, Description, Image } = props;
    const link = props.Link;
    return (
        <Link to={link}>
            <div className={styles["jumpstart-offerings"]}>
                <div className={styles["frame-2018775816"]}>
                    <div className={styles["rectangle-36724"]}></div>
                    <div className={styles["frame-20187760402"]}>
                        <div className={styles["frame-2018776041"]}>
                            <div className={styles["group-1321317035"]}>
                                <img
                                    src={`svgs/${Image}.svg`}
                                    alt={Image}
                                    style={{
                                        position: 'absolute',
                                        top: -50,
                                        left: 10,
                                        width: 255,
                                        height: 185,
                                    }}
                                />
                            </div>
                            <div className={styles["frame-442"]}>
                                <div className={styles["general-object-detection"]}>
                                    <div className={styles["frame-2018775799"]}>
                                        <div className={styles["frame-2018775798"]}>
                                            <div
                                                className={
                                                    styles[
                                                    "provide-automated-zero-to-hero-scenarios-for-multiple-environments-and-deployment-types-including-arc-enabled-servers-arc-enabled-kubernetes-and-more"
                                                    ]
                                                }
                                            >
                                                {Description}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles["frame-2018775810"]}>
                        <div className={styles["browse-scenarios"]}>
                            {Title}
                        </div>
                        <svg
                            className={styles["chevron-close"]}
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M6.60231 4.45729C6.39298 4.66662 6.39298 5.00603 6.60231 5.21536L11.4049 10.018L6.60231 14.8206C6.39298 15.03 6.39298 15.3693 6.60231 15.5786C6.81164 15.788 7.15104 15.788 7.36038 15.5786L12.542 10.397C12.7513 10.1877 12.7513 9.84831 12.542 9.63897L7.36038 4.45729C7.15104 4.24796 6.81164 4.24796 6.60231 4.45729Z"
                                fill="#50E6FF"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default HomeCard;