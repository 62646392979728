import React from "react";
import styles from "./ByLine.module.css";

const ByLine = ({ byline }) => {
    const { Author, Info, Link } = byline;

    return (
        <div className={styles["text"]}>
            By&nbsp;<a href={Link} target="_blank" rel="noopener noreferrer"><span class={styles["author"]}>{Author}</span></a>, {Info}
        </div>
    )
}

export default ByLine;
