import React, { useEffect, useState } from "react";
import MdFilePreviewer from "../../mdFilePreviewer";

const CardSchema = ({ onClose, show }) => {
  const [faqText, setFaqText] = useState("");
  const [extractedText, setExtractedText] = useState("");

  useEffect(() => {
    fetch(
      "https://raw.githubusercontent.com/Azure/arc_jumpstart_drops/main/SCHEMA.md"
    )
      .then((res) => res.text())
      .then((text) => setFaqText(text))
      .catch((error) => console.log(error));
  }, []);

  const closeButtonStyles = {
    position: "absolute",
    top: "0",
    right: "0",
    transform: "translate(-50% , 351%)",
    cursor: "pointer",
    color: "#333",
    fontSize: "20px",
  };

  useEffect(() => {
    if (show === "Drop schema example") {
      const container = document.getElementById("card-container");
      // Scroll to the middle of the container
      if (container) {
        container.scrollTop =
          container.scrollHeight / 1.32 - container.clientHeight;
      }
    } else if (show === "image") {
      const container = document.getElementById("card-container");
      // Scroll to the middle of the container
      if (container) {
        container.scrollTop = container.scrollHeight - container.clientHeight;
      }
    } else {
      const container = document.getElementById("card-container");
      // Scroll to the middle of the container
      if (container) {
        container.scrollTop = 0;
      }
    }
  }, [show, faqText]);

  return (
    <div style={{}} className="card-schema" id="card-schema">
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          alignItems: "center",
          marginRight: "10px",
          paddingTop: "10px",
          cursor: "pointer",
        }}
        onClick={onClose}
      >
        <img style={closeButtonStyles} src="./icon/close-modal.svg" />
      </span>
      <div
        id="card-container"
        style={{
          height: "906px",
          overflow: "scroll",
        }}
      >
        <MdFilePreviewer
          previewText={extractedText ? extractedText : faqText}
        />

        <>
          <h2>Drops card example</h2>
          <img
            className="example-test-img"
            src="./images/drops/dropExample.svg"
            height={500}
            width={500}
          />
        </>
      </div>
    </div>
  );
};

export default CardSchema;
