import React from "react";
import ReactDOM from "react-dom/client";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Title from "./Title";
import msalInstance from "./auth";
import AuthProvider from "./context/AuthProvider";
import SidePanelProvider from "./context/SidepanelProvider";

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Title />
    <MsalProvider instance={msalInstance}>
      <AuthProvider>
        <SidePanelProvider>
          <App />
        </SidePanelProvider>
      </AuthProvider>
    </MsalProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
