import styles from "./Contribute.module.css";
import Footer from "../../navigation/footer/Footer";
import OverviewCard from "../cards/OverviewCard";
import OverviewCardContainer from "../cards/OverviewCardContainer";

const Contribute = ({ node, footerData }) => {
  return (
    <div className={styles["complete-contribute"]}>
      <div className={styles["frame-2018776051"]}>
        <div className={styles["frame-2018775814"]}>
          <div className={styles["hero-images"]}>
            <div className={styles["frame-1"]}>
              <div className={styles["carousel-1"]}></div>
            </div>
            <div className={styles["group-1010107217"]}>
              <div className={styles["jump-in-on-jumpstart"]}>
                Jump in on Jumpstart{" "}
              </div>
              <div className={styles["contribute"]}>CONTRIBUTE </div>
              <div
                className={
                  styles[
                  "see-how-you-can-contribute-to-our-open-source-community"
                  ]
                }
              >
                See how you can contribute to our open-source community.{" "}
              </div>
            </div>
            <div className={styles["group-1321317131"]}>
              <img
                src='webp/Illustration_Contribute.webp'
                alt='Illustration_Contribute'
                style={{
                  position: 'absolute',
                  top: 151,
                  left: 630
                }}
              />
            </div>
          </div>
          <OverviewCardContainer node={node} />
          <Footer footerData={footerData} />
        </div>
      </div>
    </div>
  );
};

export default Contribute;


