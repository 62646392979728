import styles from "./Mission.module.css";
import Footer from "../../navigation/footer/Footer";

const Mission = ({ children, footerData, node }) => {
  return (
    <div className={styles["complete-about-our-mission"]}>
      <div className={styles["industry-applications-text-pic"]}>
        <div className={styles["placeholder-1"]}></div>
        <div className={styles["get-started-bar-prepurchase-exp"]}></div>
      </div>
      <div className={styles["frame-2018776051"]}>
        <div className={styles["frame-2018775814"]}>
          <div className={styles["hero-images"]}>
            <div className={styles["frame-1"]}>
              <div className={styles["carousel-1"]}></div>
            </div>
            <div className={styles["group-1010107217"]}>
              <div className={styles["see-what-s-guiding-us"]}>
                See what’s guiding us{" "}
              </div>
              <div className={styles["our-mission"]}>OUR MISSION </div>
              <div
                className={
                  styles[
                  "learn-about-our-core-design-philosophy-and-open-source"
                  ]
                }
              >
                Learn about our core design philosophy and open source.{" "}
              </div>
            </div>
            <div className={styles["our-mission2"]}>
            <img
                  src='webp/Illustration_Our-Mission.webp'
                  alt='Illustration_Our-Mission'
                  style={{
                    position: 'absolute',
                    top: -200,
                    left: -237
                  }}
                />
              <div className={styles["illustration-get-started-cost-003"]}>
                <svg
                  className={styles["vector25"]}
                  width="123"
                  height="89"
                  viewBox="0 0 123 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M122.527 88.2908L0.263686 16.2252L0.263686 11.5648L2.46033 0.0633974L122.527 73.1358L122.527 88.2949L122.527 88.2908ZM103.798 65.2475C102.024 64.21 100.643 65.0403 100.643 67.1167C100.705 68.228 101.004 69.3099 101.516 70.2807C102.028 71.2516 102.741 72.0861 103.601 72.7214C105.375 73.7589 106.756 72.9286 106.756 70.8522C106.694 69.7409 106.395 68.6591 105.883 67.6883C105.37 66.7175 104.658 65.883 103.798 65.2475ZM113.261 71.0608C111.487 70.0219 110.107 70.8522 110.107 72.9286C110.169 74.0401 110.467 75.1221 110.979 76.093C111.492 77.0639 112.205 77.8984 113.065 78.5333C114.839 79.5722 116.218 78.7405 116.218 76.6655C116.157 75.5541 115.859 74.472 115.346 73.5011C114.834 72.5302 114.121 71.6958 113.261 71.0608ZM94.1368 59.4356C92.3625 58.3967 90.9831 59.227 90.9831 61.3034C91.0448 62.4149 91.343 63.4969 91.8554 64.4678C92.3678 65.4387 93.0808 66.2731 93.9407 66.9081C95.715 67.947 97.0945 67.1167 97.0945 65.0403C97.0328 63.9288 96.7345 62.8468 96.2222 61.8759C95.7098 60.905 94.9967 60.0705 94.1368 59.4356Z"
                    fill="url(#paint0_linear_4940_159301)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_4940_159301"
                      x1="61.3955"
                      y1="88.2949"
                      x2="61.3955"
                      y2="0.0634026"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#8DC8E8" />
                      <stop offset="1" stopColor="#0078D4" />
                    </linearGradient>
                  </defs>
                </svg>

                <svg
                  className={styles["vector26"]}
                  width="636.52734375"
                  height="381.9164123535156"
                ></svg>

                <svg
                  className={styles["vector27"]}
                  width="22"
                  height="37"
                  viewBox="0 0 22 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.2747 12.859V36.2938L0.481445 24.5753V0.822266L21.2747 12.859Z"
                    fill="white"
                  />
                </svg>

                <svg
                  className={styles["vector28"]}
                  width="200"
                  height="265"
                  viewBox="0 0 200 265"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M196.074 264.716L0.918945 153.235L1.23933 0.892578L199.639 113.789L196.074 264.716Z"
                    fill="url(#paint0_linear_4940_159304)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_4940_159304"
                      x1="100.279"
                      y1="-16.6008"
                      x2="100.279"
                      y2="264.716"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#8DC8E8" />
                      <stop offset="1" stopColor="#0078D4" />
                    </linearGradient>
                  </defs>
                </svg>

                <svg
                  className={styles["vector29"]}
                  width="24"
                  height="185"
                  viewBox="0 0 24 185"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.394606 14.0103L23.4284 0.708984L23.1102 171.723L0.0742188 184.708L0.394606 14.0103Z"
                    fill="url(#paint0_linear_4940_159305)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_4940_159305"
                      x1="11.7513"
                      y1="0.708984"
                      x2="11.7513"
                      y2="184.708"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#8DC8E8" />
                      <stop offset="1" stopColor="#0078D4" />
                    </linearGradient>
                  </defs>
                </svg>

                <svg
                  className={styles["vector30"]}
                  width="199"
                  height="136"
                  viewBox="0 0 199 136"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.239258 0.777846L198.638 110.723V117.833L195.074 135.38L0.239258 23.8986V0.771484V0.777846ZM30.6313 35.9333C33.5105 37.5161 35.7511 36.2494 35.7511 33.0816C35.6502 31.3861 35.1659 29.7356 34.3345 28.2545C33.5032 26.7733 32.3465 25.5002 30.9517 24.5309C28.0725 22.9481 25.8319 24.2148 25.8319 27.3826C25.9331 29.078 26.4176 30.7284 27.2489 32.2095C28.0802 33.6906 29.2367 34.9638 30.6313 35.9333ZM15.2762 27.0643C18.1554 28.6493 20.3938 27.3826 20.3938 24.2148C20.2937 22.5191 19.8097 20.8683 18.9783 19.387C18.1469 17.9058 16.9898 16.6328 15.5944 15.6641C12.7152 14.0791 10.4767 15.348 10.4767 18.5136C10.5768 20.2093 11.0609 21.8601 11.8923 23.3414C12.7237 24.8226 13.8808 26.0956 15.2762 27.0643ZM46.309 44.8001C49.1882 46.385 51.4266 45.1183 51.4266 41.9506C51.3265 40.2549 50.8425 38.6041 50.0111 37.1228C49.1797 35.6416 48.0226 34.3685 46.6272 33.3999C43.748 31.8149 41.5096 33.0816 41.5096 36.2494C41.6097 37.9451 42.0937 39.5959 42.9251 41.0771C43.7565 42.5584 44.9136 43.8314 46.309 44.8001Z"
                    fill="url(#paint0_linear_4940_159306)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_4940_159306"
                      x1="99.4388"
                      y1="0.771484"
                      x2="99.439"
                      y2="112.935"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#8DC8E8" />
                      <stop offset="1" stopColor="#0078D4" />
                    </linearGradient>
                  </defs>
                </svg>

                <svg
                  className={styles["vector31"]}
                  width="24"
                  height="38"
                  viewBox="0 0 24 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.397461 14.2583L23.4334 0.957031V24.0842L0.397461 37.3791V14.2583Z"
                    fill="url(#paint0_linear_4940_159307)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_4940_159307"
                      x1="11.9154"
                      y1="0.957031"
                      x2="11.9154"
                      y2="37.3791"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#8DC8E8" />
                      <stop offset="1" stopColor="#0078D4" />
                    </linearGradient>
                  </defs>
                </svg>

                <svg
                  className={styles["vector32"]}
                  width="219"
                  height="126"
                  viewBox="0 0 219 126"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.2762 28.6641C16.7192 29.5829 17.9125 30.8445 18.7495 32.3365C19.5866 33.8284 20.0414 35.5044 20.0734 37.2148C20.0734 40.3826 17.835 41.6493 14.9558 40.0643C13.4724 39.1617 12.2311 37.9115 11.3391 36.4217C10.4471 34.932 9.93118 33.2474 9.83597 31.5136C9.83597 28.3458 12.7131 27.3974 15.2762 28.6641ZM30.6313 37.5331C32.0746 38.4517 33.2682 39.7133 34.1056 41.2052C34.943 42.6972 35.3983 44.3732 35.4307 46.0837C35.4307 49.2494 33.1923 50.5161 30.313 48.9333C28.8297 48.0307 27.5884 46.7805 26.6964 45.2907C25.8044 43.8009 25.2885 42.1164 25.1933 40.3826C25.834 37.2148 28.0725 35.9481 30.6313 37.5331ZM46.309 46.716C47.7519 47.635 48.9451 48.8966 49.7821 50.3885C50.6191 51.8805 51.0741 53.5563 51.1063 55.2667C51.1063 58.4345 48.8678 59.7012 45.9886 58.1184C44.5454 57.1991 43.3519 55.9371 42.5145 54.4448C41.6771 52.9526 41.2218 51.2764 41.1892 49.5656C41.5096 46.3999 43.748 45.1332 46.309 46.716ZM0.239258 13.7779L23.2731 0.476562L218.43 111.958L195.396 125.259L97.8178 69.5186L0.239258 13.7779Z"
                    fill="url(#paint0_linear_4940_159308)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_4940_159308"
                      x1="124.583"
                      y1="-15.498"
                      x2="109.335"
                      y2="88.5626"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#8DC8E8" />
                      <stop offset="1" stopColor="#0078D4" />
                    </linearGradient>
                  </defs>
                </svg>

                <svg
                  className={styles["vector33"]}
                  width="79"
                  height="45"
                  viewBox="0 0 79 45"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_f_4940_159309)">
                    <path
                      d="M0.858398 5.67744L9.71462 0.373047L78.6048 40.084L70.5081 44.6394L0.858398 5.67744Z"
                      fill="#0D2F4B"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_f_4940_159309"
                      x="-7.96475"
                      y="-8.4501"
                      width="95.3924"
                      height="61.9124"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feGaussianBlur
                        stdDeviation="4.41158"
                        result="effect1_foregroundBlur_4940_159309"
                      />
                    </filter>
                  </defs>
                </svg>

                <svg
                  className={styles["vector34"]}
                  width="72"
                  height="218"
                  viewBox="0 0 72 218"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.55 39.9588L71.2975 217.07L0.208008 177.599L0.460504 0.740234L71.55 39.9588Z"
                    fill="url(#paint0_linear_4940_159310)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_4940_159310"
                      x1="21.2885"
                      y1="197.672"
                      x2="95.0038"
                      y2="16.4852"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2A446F" />
                      <stop offset="0.616389" stopColor="#0078D4" />
                      <stop offset="1" stopColor="#8DC8E8" />
                    </linearGradient>
                  </defs>
                </svg>

                <svg
                  className={styles["vector35"]}
                  width="6"
                  height="181"
                  viewBox="0 0 6 181"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.550332 2.95883L5.10575 0.429688V177.287L0.297852 180.07L0.550332 2.95883Z"
                    fill="url(#paint0_linear_4940_159311)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_4940_159311"
                      x1="1.78181"
                      y1="180.07"
                      x2="47.1648"
                      y2="173.561"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2A446F" />
                      <stop offset="0.354167" stopColor="#0078D4" />
                      <stop offset="1" stopColor="#8DC8E8" />
                    </linearGradient>
                  </defs>
                </svg>

                <svg
                  className={styles["vector36"]}
                  width="77"
                  height="43"
                  viewBox="0 0 77 43"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.452148 3.74061L5.00545 0.958984L76.1034 40.4237L71.5501 42.9549L0.452148 3.74061Z"
                    fill="url(#paint0_linear_4940_159312)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_4940_159312"
                      x1="38.2778"
                      y1="0.958984"
                      x2="38.2778"
                      y2="42.9549"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#8DC8E8" />
                      <stop offset="1" stopColor="#0078D4" />
                    </linearGradient>
                  </defs>
                </svg>

                <svg
                  className={styles["vector37"]}
                  width="39"
                  height="159"
                  viewBox="0 0 39 159"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.1762 24.0921V27.8879L1.11674 12.9592V9.16339L27.1762 24.0921ZM38.0565 40.2853V44.079L1.11674 22.8339V18.7771L38.0565 40.2853ZM30.206 17.0075V20.8034L1.11038 3.85052V0.0546875L30.206 17.0075ZM1.11674 32.1887V28.3929L27.1762 43.3216L26.9237 47.1153L1.11674 32.1887ZM27.1762 69.8881V73.6839L1.11674 58.5027V54.7069L27.1762 69.8881ZM38.0565 85.8268V89.8814L1.11674 68.3689V64.5752L38.0565 85.8268ZM30.206 62.5489V66.5994L1.11038 49.6465V45.8507L30.206 62.5489ZM1.11674 77.9847V73.9364L27.1762 89.1176L26.9237 92.9113L1.11674 77.9847ZM27.1762 119.478V123.274L1.11674 108.341V104.545L27.1762 119.478ZM38.0565 154.396V158.189L0.864258 136.938L1.11674 133.142L38.0565 154.396ZM38.0565 135.671V139.467L1.11674 118.213V114.165L38.0565 135.671ZM30.206 112.395V116.191L1.11674 99.2384V95.4426L30.206 112.395ZM27.1698 138.705L26.9173 142.501L1.11038 127.572V123.779L27.1698 138.705Z"
                    fill="url(#paint0_linear_4940_159313)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_4940_159313"
                      x1="38.0565"
                      y1="176.628"
                      x2="38.0265"
                      y2="-6.83781"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#0078D4" />
                      <stop offset="0.34375" stopColor="#49C5B1" />
                      <stop offset="0.671875" stopColor="#FFA38B" />
                      <stop offset="1" stopColor="#C03BC4" />
                    </linearGradient>
                  </defs>
                </svg>

                <svg
                  className={styles["vector38"]}
                  width="48"
                  height="56"
                  viewBox="0 0 48 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M33.6997 44.5673C23.3667 38.5415 15.0113 24.0796 15.0113 12.202C15.0113 6.26105 17.08 2.12999 20.4387 0.150391L5.72222 8.67137C2.36348 10.651 0.296875 14.7842 0.296875 20.723C0.296875 32.6048 8.65023 47.0646 18.9853 53.0904C24.2388 56.1033 28.8876 56.3621 32.3333 54.3804L47.0604 45.8594C43.7017 47.839 38.9532 47.5802 33.6997 44.5673Z"
                    fill="url(#paint0_linear_4940_159314)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_4940_159314"
                      x1="14.7305"
                      y1="55.6536"
                      x2="28.6397"
                      y2="-82.7497"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2A446F" />
                      <stop offset="0.354167" stopColor="#0078D4" />
                      <stop offset="1" stopColor="#8DC8E8" />
                    </linearGradient>
                  </defs>
                </svg>

                <svg
                  className={styles["vector39"]}
                  width="23"
                  height="13"
                  viewBox="0 0 23 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.47168 12.5546L15.2052 3.9466L22.0945 0.0722656L7.35467 8.59537L0.47168 12.5546Z"
                    fill="#FAFAFA"
                  />
                </svg>

                <svg
                  className={styles["group"]}
                  width="53"
                  height="73"
                  viewBox="0 0 53 73"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.573348 71.3577L0.0556641 72.6499L14.7828 64.0419L15.3005 62.8367L18.7441 52.8496L4.01696 61.3727L0.573348 71.3577Z"
                    fill="url(#paint0_linear_4940_159316)"
                  />
                  <path
                    d="M52.5909 30.8125L37.8638 39.3356L37.8447 41.765L25.9798 48.631L23.2236 54.7438L28.2183 68.775L28.8208 70.7546L43.548 62.2315L42.8584 60.2519L39.175 49.7174L52.5039 42.0027L52.5909 30.8125Z"
                    fill="url(#paint1_linear_4940_159316)"
                  />
                  <path
                    d="M43.3771 0.339844L28.65 8.86084L23.3965 18.761L38.0366 10.24L43.3771 0.339844Z"
                    fill="url(#paint2_linear_4940_159316)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_4940_159316"
                      x1="7.60115"
                      y1="69.2245"
                      x2="-3.17639"
                      y2="47.0002"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2A446F" />
                      <stop offset="0.354167" stopColor="#0078D4" />
                      <stop offset="1" stopColor="#8DC8E8" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_4940_159316"
                      x1="35.0807"
                      y1="63.8449"
                      x2="10.2362"
                      y2="23.936"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2A446F" />
                      <stop offset="0.354167" stopColor="#0078D4" />
                      <stop offset="1" stopColor="#8DC8E8" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_4940_159316"
                      x1="31.4637"
                      y1="15.5742"
                      x2="22.3156"
                      y2="-6.10424"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2A446F" />
                      <stop offset="0.354167" stopColor="#0078D4" />
                      <stop offset="1" stopColor="#8DC8E8" />
                    </linearGradient>
                  </defs>
                </svg>

                <svg
                  className={styles["group2"]}
                  width="101"
                  height="82"
                  viewBox="0 0 101 82"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.34668 32.6108L12.3176 34.5926L27.0426 26.0694L15.0717 24.0898L0.34668 32.6108Z"
                    fill="url(#paint0_linear_4940_159320)"
                  />
                  <path
                    d="M99.2129 71.4358L88.7908 61.4508L86.0346 52.2402L71.3945 60.7612L74.0637 70.0588L84.4858 79.9568L85.8628 81.3359L100.59 72.8128L99.2129 71.4358Z"
                    fill="url(#paint1_linear_4940_159320)"
                  />
                  <path
                    d="M91.3759 42.3396L84.5714 30.5469H83.1944L73.4619 30.6318L66.8314 23.6597L62.5243 7.64889L52.7939 1.96683L39.6262 9.66457L31.0904 0.0742188L16.3633 8.5952L27.4728 21.0775L34.1054 21.8519L45.8281 14.9987L47.8841 16.1699L52.1043 32.1828L58.7348 39.1549L68.4673 39.0679H69.8443L76.6488 50.8606L91.3759 42.3396Z"
                    fill="url(#paint2_linear_4940_159320)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_4940_159320"
                      x1="8.5864"
                      y1="34.5926"
                      x2="5.13945"
                      y2="20.8588"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2A446F" />
                      <stop offset="0.354167" stopColor="#0078D4" />
                      <stop offset="1" stopColor="#8DC8E8" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_4940_159320"
                      x1="80.4057"
                      y1="81.3359"
                      x2="62.0944"
                      y2="52.5343"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2A446F" />
                      <stop offset="0.354167" stopColor="#0078D4" />
                      <stop offset="1" stopColor="#8DC8E8" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_4940_159320"
                      x1="39.516"
                      y1="50.8606"
                      x2="13.819"
                      y2="-8.63429"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2A446F" />
                      <stop offset="0.354167" stopColor="#0078D4" />
                      <stop offset="1" stopColor="#8DC8E8" />
                    </linearGradient>
                  </defs>
                </svg>

                <svg
                  className={styles["vector46"]}
                  width="86"
                  height="109"
                  viewBox="0 0 86 109"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M85.7778 84.5261L73.9787 82.6313L71.2204 88.7441L76.1302 102.775L76.8176 104.755L69.9283 108.629L58.6469 96.06L52.0143 95.3725L48.5707 105.357L48.0529 106.65L38.3226 101.055L34.1024 85.0417L27.4698 78.0696L17.7394 78.1566H16.3603L9.56639 66.3617L14.9047 56.4637L12.1464 47.1662L1.71586 37.1748L0.338867 35.7978V24.6119L12.3098 26.5915L15.0681 20.4808L10.1605 6.44963L9.47088 4.47003L16.356 0.595703L27.4655 13.078L34.183 13.7654L37.6288 3.78046L38.1465 2.49043L47.8769 8.0855L52.097 24.0963L58.7275 31.0684L68.46 30.9835H69.8392L76.6415 42.8654L71.3095 52.7612L73.9787 61.9718L84.3987 71.9568L85.7778 73.3359V84.5261ZM43.0605 76.09C53.3955 82.1158 61.8359 77.2951 61.9207 65.4154C61.9207 53.5336 53.5674 38.9889 43.2323 33.048C32.8993 27.0222 24.459 31.8428 24.459 43.8095C24.2871 55.6893 32.6405 70.149 43.0605 76.09Z"
                    fill="url(#paint0_linear_4940_159325)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_4940_159325"
                      x1="26.7097"
                      y1="108.629"
                      x2="34.3476"
                      y2="-63.0643"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2A446F" />
                      <stop offset="0.354167" stopColor="#0078D4" />
                      <stop offset="1" stopColor="#8DC8E8" />
                    </linearGradient>
                  </defs>
                </svg>

                <svg
                  className={styles["vector47"]}
                  width="26"
                  height="48"
                  viewBox="0 0 26 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_i_4940_159326)">
                    <path
                      d="M20.9588 0.462891L25.7713 8.72247L9.99717 17.5149L25.5039 44.1587L20.6915 47.0896L0.639648 12.1862L20.9588 0.462891Z"
                      fill="url(#paint0_linear_4940_159326)"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_i_4940_159326"
                      x="0.639648"
                      y="0.462891"
                      width="25.1318"
                      height="51.6683"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="5.0418" />
                      <feGaussianBlur stdDeviation="2.5209" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_4940_159326"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_4940_159326"
                      x1="8.39655"
                      y1="47.0896"
                      x2="25.8918"
                      y2="-15.6403"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2A446F" />
                      <stop offset="0.354167" stopColor="#0078D4" />
                      <stop offset="1" stopColor="#8DC8E8" />
                    </linearGradient>
                  </defs>
                </svg>

                <svg
                  className={styles["vector48"]}
                  width="30"
                  height="32"
                  viewBox="0 0 30 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_i_4940_159327)">
                    <path
                      d="M29.626 4.34285L6.82165 31.5003L0.956925 27.9952L23.7613 0.837674L29.626 4.34285Z"
                      fill="url(#paint0_linear_4940_159327)"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_i_4940_159327"
                      x="0.957031"
                      y="0.837891"
                      width="28.6689"
                      height="35.7044"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="5.0418" />
                      <feGaussianBlur stdDeviation="2.5209" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_4940_159327"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_4940_159327"
                      x1="9.80573"
                      y1="31.5003"
                      x2="14.4307"
                      y2="-8.49441"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2A446F" />
                      <stop offset="0.354167" stopColor="#0078D4" />
                      <stop offset="1" stopColor="#8DC8E8" />
                    </linearGradient>
                  </defs>
                </svg>

                <svg
                  className={styles["vector49"]}
                  width="26"
                  height="47"
                  viewBox="0 0 26 47"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_i_4940_159328)">
                    <path
                      d="M5.07978 0.0410156L25.3989 35.2109L4.81243 46.6677L0 38.4081L15.7741 29.6157L0.267358 2.97183L5.07978 0.0410156Z"
                      fill="url(#paint0_linear_4940_159328)"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_i_4940_159328"
                      x="0"
                      y="0.0410156"
                      width="25.3984"
                      height="51.6683"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="5.0418" />
                      <feGaussianBlur stdDeviation="2.5209" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_4940_159328"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_4940_159328"
                      x1="7.83939"
                      y1="46.6677"
                      x2="24.9761"
                      y2="-21.8102"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2A446F" />
                      <stop offset="0.354167" stopColor="#0078D4" />
                      <stop offset="1" stopColor="#8DC8E8" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              {/* svgs end */}
            </div>
          </div>
          {children}
          <Footer footerData={footerData} />
        </div>
      </div>
    </div>
  );
};

export default Mission;


