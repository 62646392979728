import Footer from "../../navigation/footer/Footer";
import Banner from "./DropBanner";
import DropCard from "./DropPostCard";
import dropCardsData from "../../../data/dropsCards.json";
import "./Index.css";
import SideBar from "./DropSidebar";
import dropsFilters from "../../../data/dropsFilter.json";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import Modal from "react-modal";
import Select, { components } from "react-select";
import MdFilePreviewer from "../../mdFilePreviewer";

const JumpStartDrops = ({ footerData }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [mdText, setMdText] = useState();
  const [options, setOptions] = useState();
  const [previewText, setPreviewText] = useState();
  const [dropTitle, setDropTitle] = useState();
  const [darkMode, setDarkMode] = useState(true);
  const [modalHeadings, setModalHeadings] = useState([]);
  const [showBotLine, setShowBotLine] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(
    dropsFilters[3].tags.map(() => false)
  );
  const [difficulty, setDifficulty] = useState([]);
  const [seachedDrop, setSearchedDrop] = useState([]);
  const [mdLink, setMdLink] = useState();
  const [rightBarTitle, setRightBarTitle] = useState();
  const [rightBarAuthors, setRightBarAuthors] = useState([]);
  const [rightBarLastModified, setRightBarLastModified] = useState();
  const [githubLink, setGithubLink] = useState();
  const [enlargeRightBar, setEnlargeRightBar] = useState(false);

  let [tagCheckBox, setTagCheckBox] = useState(
    dropsFilters.map((dropFilter) => dropFilter.tags.map(() => false))
  );
  const [programmingLanguage, setProgrammingLanguage] = useState([]);
  const [topic, setTopic] = useState([]);
  const [type, setType] = useState([]);
  let [productFilter, setProductFilter] = useState([]);
  const showingCount = useRef();
  const [count, setCount] = useState();
  const [selectedTitle, setSelectedTitle] = useState();
  const [sortedDropCards, setSortedDropCards] = useState(dropCardsData);
  const [drops, setDrops] = useState([]);
  const [trendingTags, setTrendingTags] = useState([]);
  const [popularTags, setPopulatTags] = useState([]);
  const [filterByPill, setFilterByPill] = useState("");
  const [dropViewChecked, setDropViewChecked] = useState(
    dropCardsData.map((card) => ({ title: card.Title, viewed: false }))
  );

  const [inputSearch, setInputSearch] = useState("");
  const [sortOptions, setSortOptions] = useState([
    { value: "Newest", label: "Newest" },
    { value: "Updated", label: "Updated" },
    { value: "Oldest", label: "Oldest" },
    { value: "Trending", label: "Trending" },
    { value: "Popular", label: "Popular" },
  ]);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState({
    value: "Newest",
    label: "Newest",
  });

  useLayoutEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/telemetry/drops`)
      .then((res) => res.json())
      .then((json) => {
        setDrops(json.data);
        let trending = json.data?.sort((a, b) => b.views - a.views);
        trending = trending?.slice(0, 3);
        setTrendingTags(
          dropCardsData.filter((card) =>
            trending.some((drop) => drop.title === card.Title)
          )
        );
        let popular = json.data?.sort((a, b) => b.downloads - a.downloads);
        popular = popular?.slice(0, 3);
        setPopulatTags(
          dropCardsData.filter((card) =>
            popular.some((drop) => drop.title === card.Title)
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });

    if (!localStorage.getItem("dropViewChecked"))
      localStorage.setItem("dropViewChecked", JSON.stringify([]));
  }, []);

  function isWithinPastMonth(dateString) {
    const dateObj = new Date(dateString);

    const today = new Date();

    const oneMonthAgo = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      today.getDate()
    );

    if (oneMonthAgo.getDate() !== today.getDate()) {
      oneMonthAgo.setDate(0);
    }

    return dateObj >= oneMonthAgo && dateObj < today;
  }

  useMemo(() => {
    if (filterByPill === "trending") {
      if (drops.length > 0) {
        let trending = drops?.sort((a, b) => b.views - a.views);
        trending = trending?.slice(0, 3);
        setSortedDropCards(
          dropCardsData.filter((card) => {
            return trending.some((drop) => drop.title === card.Title);
          })
        );
      }
    } else if (filterByPill === "popular") {
      if (drops.length > 0) {
        let popular = drops?.sort((a, b) => b.downloads - a.downloads);
        popular = popular?.slice(0, 3);
        setSortedDropCards(
          dropCardsData.filter((card) => {
            return popular.some((drop) => drop.title === card.Title);
          })
        );
      }
    } else if (filterByPill === "new") {
      setSortedDropCards(
        dropCardsData.filter((card) => isWithinPastMonth(card.CreatedDate))
      );
    } else if (filterByPill === "updated") {
      setSortedDropCards(
        dropCardsData.filter(
          (card) =>
            isWithinPastMonth(card.LastModified) &&
            !isWithinPastMonth(card.CreatedDate)
        )
      );
    } else
      setSortedDropCards(
        dropCardsData.sort(
          (a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)
        )
      );
  }, [filterByPill]);

  const handleUpdateDrops = () => {
    fetch(`${process.env.REACT_APP_API_URL}/telemetry/drops`)
      .then((res) => res.json())
      .then((json) => {
        setDrops(json.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDataFromMD = (start, end, texts) => {
    const text = texts.replace(/(\*\*`|`*\*\*)/g, "**");
    var startIndex = start
      ? start === ">"
        ? text.indexOf(start) + start.length
        : text.indexOf(`##${start}`)
      : 0;

    let endIndex = text.indexOf(`##${end}`);
    if (endIndex === -1) {
      endIndex = text.length;
    }

    // Extract the text between the markers
    const extractedText = text.substring(startIndex, endIndex);
    setPreviewText(extractedText);
  };

  const fetchTextMapping = (text) => {
    setPreviewText();
    modalHeadings.length = 0;

    const regex = /^##(?!\s*>|\s*#)(.*)/gm;

    let match;
    while ((match = regex.exec(text)) !== null) {
      if (
        !match[1].includes(rightBarTitle) &&
        !modalHeadings.includes(match[1]) &&
        !match[1].includes("Introduction") &&
        !match[1].includes("Dashboard")
      )
        modalHeadings.push(match[1]);
    }
    if (!modalHeadings.includes(" Overview") && modalHeadings.length > 0)
      modalHeadings.unshift("Overview");

    const lines = text.split("\n");

    if (modalHeadings.length > 0) {
      if (text.includes("linkTitle"))
        getDataFromMD(">", modalHeadings[1], text);
      else getDataFromMD("", modalHeadings[1], text);
    }

    // Find the line containing the title
    const titleLine = lines.find((line) => line.trim().startsWith("title:"));

    // Extract only the title text
    const title = titleLine ? titleLine.split(`"`)[1].trim() : "";

    setDropTitle(title);

    setShowBotLine(modalHeadings.map((heading, i) => (i === 0 ? true : false)));
    setModalHeadings([...modalHeadings]);
  };

  useEffect(() => {
    if (mdLink?.includes("arc_jumpstart_drops") && mdLink?.includes("main")) {
      var canaryLink = mdLink.replace(/\/main\//, "/canary/");
    } else if (mdLink?.includes("arc_jumpstart_drops")) {
      const index = mdLink.indexOf("arc_jumpstart_drops/");

      if (index !== -1) {
        const firstPart = mdLink.substring(
          0,
          index + "arc_jumpstart_drops/".length
        );

        const secondPart = mdLink.substring(
          index + "arc_jumpstart_drops/".length
        );
        var canaryLink = firstPart + "canary/";
      }
    }
    if (mdLink) {
      const urls = [
        `${mdLink}/main/README.md`,
        `${mdLink}/master/README.md`,
        `${mdLink}/README.md`,
        `${mdLink}/main/readme.md`,
        `${mdLink}/master/readme.md`,
        `${mdLink}/readme.md`,
        `${mdLink}/index.md`,
        `${mdLink}/main/index.md`,
        `${mdLink}/master/index.md`,
        `${mdLink}/_index.md`,
        `${mdLink}/main/_index.md`,
        `${mdLink}/master/_index.md`,
        `${canaryLink && `${canaryLink}/_index.md`}`,
        `${canaryLink && `${canaryLink}/index.md`}`,
        `${canaryLink && `${canaryLink}/README.md`}`,
        `${canaryLink && `${canaryLink}/readme.md`}`,
      ];

      const promises = urls.map((url) => fetch(url));

      Promise.all(promises)
        .then((responses) => {
          const results = responses.map((response) => response.text());

          return Promise.all(results);
        })
        .then((data) => {
          const mdData = data.find((text) => text.length > 30);
          setMdText(mdData);
          fetchTextMapping(mdData);
        })
        .catch((error) => console.error(error));
    }
  }, [mdLink]);

  const rightBarLastModifiedFormat = (dateString) => {
    const date = new Date(dateString);

    const formattedDate = date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    return formattedDate;
  };

  useEffect(() => {
    setOptions(
      modalHeadings.map((title) => ({
        value: title,
        label: title,
      }))
    );
    setSelectedTitle({ value: modalHeadings[0], label: modalHeadings[0] });
  }, [modalHeadings]);

  const handleSelectedRighbarTitle = (selected) => {
    const i = modalHeadings.indexOf(selected.value);
    setSelectedTitle(selected);
    if (i === 0)
      mdText.includes("linkTitle")
        ? getDataFromMD(">", modalHeadings[i + 1], mdText)
        : getDataFromMD("", modalHeadings[i + 1], mdText);
    else getDataFromMD(selected.value, modalHeadings[i + 1], mdText);
  };

  const ModalHeader = ({ darkMode, onClose }) => {
    const headerStyles = {
      color: darkMode ? "#fff" : "#333",
      textAlign: "center",
      marginBottom: "40px",
      position: "relative",
    };

    const closeButtonStyles = {
      position: "absolute",
      top: "0",
      right: "0",
      transform: "translate(-50%, 250%)",
      cursor: "pointer",
      color: darkMode ? "#fff" : "#333",
      fontSize: "20px",
    };

    return (
      <>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "center",
            marginRight: "10px",
            paddingTop: "10px",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          <img style={closeButtonStyles} src="./icon/close-modal.svg" />
        </span>
        <div style={headerStyles}>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <h3
                style={{
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "26px",
                  letterSpacing: "0.6px",
                  marginBottom: "5px",
                  display: "flex",
                  padding: "0px 60px 0px 60px",
                  textAlign: "left",
                }}
              >
                {rightBarTitle}
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  paddingLeft: "60px",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                {rightBarAuthors.map((author, i) => (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "350",
                        lineHeight: "24px",
                        letterSpacing: "0.7px",
                      }}
                      key={i}
                    >
                      {i === 0 ? "By " : " |"}
                      <a>
                        <span
                          style={{
                            color: "rgb(80, 230, 255)",
                            cursor: "pointer",
                            padding: "0 5px",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "24px",
                            letterSpacing: "0.7px",
                          }}
                          onClick={() => window.open(author?.Link, "_blank")}
                        >
                          {" "}
                          {author?.Name}
                        </span>
                      </a>
                      {i === rightBarAuthors.length - 1 ? (
                        <div
                          style={{
                            display: "flex",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "350",
                            lineHeight: "24px",
                            letterSpacing: "0.7px",
                          }}
                          key={i}
                        >
                          |
                          <a>
                            <span
                              style={{
                                color: "rgb(80, 230, 255)",
                                cursor: "pointer",
                                padding: "0 5px",
                                fontSize: "14px",

                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "24px",
                                letterSpacing: "0.7px",
                                display: "flex",
                                gap: "5px",
                              }}
                              onClick={() => window.open(githubLink, "_blank")}
                            >
                              {" "}
                              View in GitHub
                              <img style={{}} src="./icon/github-icon.svg" />
                            </span>
                          </a>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
              {rightBarLastModified && (
                <div
                  style={{
                    display: "flex",
                    padding: "0 60px",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "350",
                    lineHeight: "24px",
                    letterSpacing: "0.7px",
                  }}
                >
                  Last updated{" "}
                  {rightBarLastModifiedFormat(rightBarLastModified)}
                </div>
              )}
            </div>

            <Select
              value={selectedTitle}
              onChange={handleSelectedRighbarTitle}
              options={options}
              isSearchable={false}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: "#242424",

                  width: "calc(100% - 117px)",

                  marginLeft: "60px",
                  marginTop: "15px",
                  boxShadow: "0px 4px 20px 0px rgba(255, 255, 255, 0.15)",
                  border: "1px solid var(--Neutral-Stroke-1-Rest, #666)",
                  textAlign: "left",
                  borderRadius: "10px",
                  ...(state.selectProps.menuIsOpen && {
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  }),
                  "&:hover": {
                    backgroundColor: "#383838",
                    borderColor: "#666",
                  },
                }),
                menu: (provided) => ({
                  ...provided,
                  background: "#242424",
                  color: "#ffffff",
                  marginLeft: "60px",
                  width: "calc(100% - 117px)",
                  marginTop: 0,
                  borderColor: "white",
                  outline: "",
                  boxShadow: "0px 4px 20px 0px rgba(255, 255, 255, 0.15)",
                  border: "1px solid var(--Neutral-Stroke-1-Rest, #666)",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  textAlign: "left",
                  borderRadius: "0px 0px 10px 10px",
                }),
                menuList: (provided) => ({
                  ...provided,
                  padding: "0px",
                  borderRadius: "0px 0px 10px 10px",
                }),
                option: (defaultStyles, state) => ({
                  ...defaultStyles,

                  backgroundColor: state.isSelected ? "#383838" : "#242424",

                  "&:hover": {
                    backgroundColor: "#383838",
                  },
                }),
                input: (styles) => ({
                  ...styles,
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  color: "White",
                }),
                placeholder: (styles) => ({
                  ...styles,
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  color: "#ffffff",
                }),
                singleValue: (styles) => ({
                  ...styles,
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  color: "#ffffff",
                }),
                dropdownIndicator: (provided, state) => ({
                  ...provided,
                  transform: state.selectProps.menuIsOpen
                    ? "rotate(-180deg)"
                    : null,
                  transition: "transform 0.3s",
                  color: "#ffffff",
                  "&:hover": {
                    color: "#ffffff",
                  },
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "white",
                  primary: "white",
                },
              })}
            />
          </div>
        </div>
      </>
    );
  };

  const handleHideScrollBarFromMainPage = () => {
    var style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML =
      ":not(.app-content) ::-webkit-scrollbar-thumb {  display: none;} .ReactModalPortal ::-webkit-scrollbar-thumb {  display: block;}";
    document.head.appendChild(style);
  };

  const handleShowScrollBarONMainPage = () => {
    var style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML =
      ":not(.app-content) ::-webkit-scrollbar-thumb {  display: block;}";
    document.head.appendChild(style);
  };

  const ModalBody = ({ darkMode }) => {
    const bodyStyles = {
      color: darkMode ? "#fff" : "#333",
      padding: "60px",
      paddingTop: "0",
      paddingBottom: "0",
      overflowY: !enlargeRightBar && "scroll",
      maxHeight: !enlargeRightBar && "calc(100vh - 297px)",
    };

    return (
      <div style={bodyStyles}>
        <MdFilePreviewer
          previewText={previewText?.replace("---", "")}
          githubLink={mdLink}
        />
        {selectedTitle?.value?.includes("Overview") ? (
          <blockquote
            style={{
              background:
                "var(--Neutral-Callout-Disclaimer-Background, #625D31)",
              color: "var(--Neutral-Callout-Disclaimer-Foreground, #FFF492)",
              paddingLeft: "10px",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "20px",
              padding: "14px 10px",
              margin: "0 0 10px 0",
              borderRadius: "10px",
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "row",
                padding: "22.64px 10px",
                borderRadius: "10px",
              }}
            >
              <img
                src="./icon/disclaimer-info.svg"
                style={{ marginRight: "5px", marginTop: "2px" }}
              />
              <span>
                <div>
                  <span
                    style={{
                      color:
                        "var(--Neutral-Callout-Disclaimer-Foreground, #FFF492)",

                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "20px",
                      letterSpacing: "-0.14px",
                      marginRight: "8px",
                    }}
                  >
                    Disclaimer:
                  </span>
                  Please carefully review the{" "}
                  <a
                    style={{
                      color: "var(--Neutral-Callout-Hyperlink, #50E6FF)",

                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "20px",
                      letterSpacing: "-0.14px",
                      cursor: "pointer",
                    }}
                    href="https://github.com/Azure/arc_jumpstart_drops/blob/main/DISCLAIMER.md"
                    target="_blank"
                  >
                    disclaimer page
                  </a>{" "}
                  before contributing or using any Jumpstart Drop to help you
                  understand the risks and limitations associated with the use
                  of all Drops.
                </div>
              </span>
            </span>
          </blockquote>
        ) : null}
      </div>
    );
  };

  const modalStyles = {
    overlay: {
      backgroundColor: darkMode
        ? "rgba(0, 0, 0, 0)"
        : "rgba(255, 255, 255, 0.75)",
      backdropFilter: "none",
      display: "flex",
      alignItems: "end",
      justifyContent: "end",
      zIndex: 6,
    },
    content: {
      backgroundColor: darkMode ? "#242424" : "#fff",
      color: darkMode ? "#fff" : "#333",
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      height: "calc(100% - 40px - 50px)",
      width: "100%",
      maxWidth: -enlargeRightBar ? "200vw" : "40vw",
      maxHeight: "100vh",
      borderTop: "none",
      position: "static",
      paddingLeft: enlargeRightBar ? "250px" : "0px",
      paddingRight: enlargeRightBar ? "250px" : "0px",
      top: "0",
      right: "0",
      overflow: !enlargeRightBar ? "hidden" : "Scroll",
    },
  };

  useMemo(() => {
    setCount(showingCount.current);
  }, [showingCount.current]);

  const DropCardContainer = () => {
    return (
      <>
        {sortedDropCards.map((cardData, i) => {
          if (i === 0) showingCount.current = 0;

          if (
            seachedDrop.length > 0 &&
            productFilter.length === 0 &&
            programmingLanguage.length === 0 &&
            difficulty.length === 0 &&
            topic.length === 0 &&
            type.length === 0 &&
            !lastUpdated.includes(true)
          ) {
            let dropCard = "";

            const searchedFilter = seachedDrop.some((title) =>
              cardData.Title.includes(title)
            );
            if (searchedFilter)
              dropCard = (
                <DropCard
                  cardData={cardData}
                  key={i}
                  setMdLink={setMdLink}
                  setRightBarTitle={setRightBarTitle}
                  setRightBarAuthors={setRightBarAuthors}
                  setRightBarLastModified={setRightBarLastModified}
                  setModalIsOpen={setModalIsOpen}
                  handleHideScrollBarFromMainPage={
                    handleHideScrollBarFromMainPage
                  }
                  setGithubLink={setGithubLink}
                  drops={drops}
                  handleUpdateDrops={handleUpdateDrops}
                  popularTags={popularTags}
                  trendingTags={trendingTags}
                  setFilterByPill={setFilterByPill}
                  filterByPill={filterByPill}
                  dropViewChecked={dropViewChecked}
                  setDropViewChecked={setDropViewChecked}
                  productFilter={productFilter}
                  setProductFilter={setProductFilter}
                  handleSelectedSortDropdownOption={
                    handleSelectedSortDropdownOption
                  }
                />
              );
            else dropCard = null;
            if (dropCard !== null)
              showingCount.current = showingCount.current + 1;
            setCount(showingCount.current);
            return dropCard;
          }

          const haveProductFilter = cardData.Products.some(
            (product) =>
              productFilter.filter((productData) =>
                productData.includes(product)
              ).length > 0
          );
          const haveLanguageFilter = programmingLanguage.some((language) =>
            cardData.ProgrammingLanguage.includes(language)
          );

          const haveTopic = topic.some((topik) =>
            cardData.Topics.includes(topik)
          );

          if (
            !type.some((typ) => cardData.Type.includes(typ)) &&
            type.length > 0
          ) {
            setCount(showingCount.current);

            return null;
          }

          if (
            !difficulty.some((difficult) =>
              cardData.Difficulty.includes(difficult)
            ) &&
            difficulty.length > 0
          ) {
            setCount(showingCount.current);
            return null;
          }

          if (!haveProductFilter && productFilter.length > 0)
            if (!haveLanguageFilter && !haveTopic) {
              setCount(showingCount.current);
              return null;
            }

          if (!haveLanguageFilter && programmingLanguage.length > 0)
            if (!haveProductFilter && !haveTopic) {
              setCount(showingCount.current);
              return null;
            }

          if (!haveTopic && topic.length > 0)
            if (!haveLanguageFilter & !haveProductFilter) {
              setCount(showingCount.current);
              return null;
            }

          if (lastUpdated.includes(true)) {
            let dropCard = "";
            const currentDate = new Date().getTime();

            const updated = new Date(
              cardData.LastModified
                ? cardData.LastModified.substring(0, 10)
                : cardData.CreatedDate.substring(0, 10)
            ).getTime();
            let diff = currentDate - updated;
            diff = Math.round(diff / (1000 * 3600 * 24));
            const index = lastUpdated.findIndex((last) => last === true);
            if (index === 0)
              dropCard =
                diff < 182 ? (
                  <DropCard
                    cardData={cardData}
                    key={i}
                    setMdLink={setMdLink}
                    setRightBarTitle={setRightBarTitle}
                    setRightBarAuthors={setRightBarAuthors}
                    setRightBarLastModified={setRightBarLastModified}
                    setModalIsOpen={setModalIsOpen}
                    handleHideScrollBarFromMainPage={
                      handleHideScrollBarFromMainPage
                    }
                    setGithubLink={setGithubLink}
                    drops={drops}
                    handleUpdateDrops={handleUpdateDrops}
                    popularTags={popularTags}
                    trendingTags={trendingTags}
                    setFilterByPill={setFilterByPill}
                    filterByPill={filterByPill}
                    dropViewChecked={dropViewChecked}
                    setDropViewChecked={setDropViewChecked}
                    productFilter={productFilter}
                    setProductFilter={setProductFilter}
                    handleSelectedSortDropdownOption={
                      handleSelectedSortDropdownOption
                    }
                  />
                ) : null;
            else if (index === 1)
              dropCard =
                diff > 182 && diff < 366 ? (
                  <DropCard
                    cardData={cardData}
                    key={i}
                    setMdLink={setMdLink}
                    setRightBarTitle={setRightBarTitle}
                    setRightBarAuthors={setRightBarAuthors}
                    setRightBarLastModified={setRightBarLastModified}
                    setModalIsOpen={setModalIsOpen}
                    handleHideScrollBarFromMainPage={
                      handleHideScrollBarFromMainPage
                    }
                    setGithubLink={setGithubLink}
                    drops={drops}
                    handleUpdateDrops={handleUpdateDrops}
                    popularTags={popularTags}
                    trendingTags={trendingTags}
                    setFilterByPill={setFilterByPill}
                    filterByPill={filterByPill}
                    dropViewChecked={dropViewChecked}
                    setDropViewChecked={setDropViewChecked}
                    productFilter={productFilter}
                    setProductFilter={setProductFilter}
                    handleSelectedSortDropdownOption={
                      handleSelectedSortDropdownOption
                    }
                  />
                ) : null;
            else
              dropCard =
                diff > 366 ? (
                  <DropCard
                    cardData={cardData}
                    key={i}
                    setMdLink={setMdLink}
                    setRightBarTitle={setRightBarTitle}
                    setRightBarAuthors={setRightBarAuthors}
                    setRightBarLastModified={setRightBarLastModified}
                    setModalIsOpen={setModalIsOpen}
                    handleHideScrollBarFromMainPage={
                      handleHideScrollBarFromMainPage
                    }
                    setGithubLink={setGithubLink}
                    drops={drops}
                    handleUpdateDrops={handleUpdateDrops}
                    popularTags={popularTags}
                    trendingTags={trendingTags}
                    setFilterByPill={setFilterByPill}
                    filterByPill={filterByPill}
                    dropViewChecked={dropViewChecked}
                    setDropViewChecked={setDropViewChecked}
                    productFilter={productFilter}
                    setProductFilter={setProductFilter}
                    handleSelectedSortDropdownOption={
                      handleSelectedSortDropdownOption
                    }
                  />
                ) : null;
            if (dropCard !== null)
              showingCount.current = showingCount.current + 1;
            setCount(showingCount.current);

            return dropCard;
          } else {
            setCount(showingCount.current);
            showingCount.current = showingCount.current + 1;

            return (
              <DropCard
                cardData={cardData}
                key={i}
                setMdLink={setMdLink}
                setRightBarTitle={setRightBarTitle}
                setRightBarAuthors={setRightBarAuthors}
                setRightBarLastModified={setRightBarLastModified}
                setModalIsOpen={setModalIsOpen}
                handleHideScrollBarFromMainPage={
                  handleHideScrollBarFromMainPage
                }
                setGithubLink={setGithubLink}
                drops={drops}
                handleUpdateDrops={handleUpdateDrops}
                popularTags={popularTags}
                trendingTags={trendingTags}
                setFilterByPill={setFilterByPill}
                filterByPill={filterByPill}
                dropViewChecked={dropViewChecked}
                setDropViewChecked={setDropViewChecked}
                productFilter={productFilter}
                setProductFilter={setProductFilter}
                handleSelectedSortDropdownOption={
                  handleSelectedSortDropdownOption
                }
              />
            );
          }
        })}
      </>
    );
  };

  const handleSelectedSortDropdownOption = (selected) => {
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    if (selected.value === "Newest")
      setSortedDropCards(
        dropCardsData.sort(
          (a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)
        )
      );
    else if (selected.value === "Updated")
      setSortedDropCards(
        dropCardsData.sort((a, b) => {
          const aCreatedDate = new Date(a.CreatedDate);
          const bCreatedDate = new Date(b.CreatedDate);

          // Check if both cards were created within the past month
          const aWithinMonth = aCreatedDate >= oneMonthAgo;
          const bWithinMonth = bCreatedDate >= oneMonthAgo;

          // If both are within the past month, keep their original order
          if (aWithinMonth && bWithinMonth) return 0;

          // If only `a` is within the past month, it should come after `b`
          if (aWithinMonth) return 1;

          // If only `b` is within the past month, it should come after `a`
          if (bWithinMonth) return -1;

          // If neither is within the past month, sort by LastModified
          return new Date(b.LastModified) - new Date(a.LastModified);
        })
      );
    else if (selected.value === "Oldest")
      setSortedDropCards(
        dropCardsData.sort(
          (a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate)
        )
      );
    else if (selected.value === "Trending") {
      if (drops.length > 0) {
        let trending = drops?.sort((a, b) => b.views - a.views);
        trending = trending?.slice(0, 3);

        const dropCards = dropCardsData.filter((card) =>
          trending.find((drop) => drop.title === card.Title) ? false : true
        );

        trending = dropCardsData.filter((card) =>
          trending.some((drop) => drop.title === card.Title)
        );

        dropCards.unshift(...trending);
        setSortedDropCards(dropCards);
      }
    } else if (selected.value === "Popular") {
      if (drops.length > 0) {
        let popular = drops?.sort((a, b) => b.downloads - a.downloads);
        popular = popular?.slice(0, 3);

        const dropCards = dropCardsData.filter((card) =>
          popular.find((drop) => drop.title === card.Title) ? false : true
        );
        popular = dropCardsData.filter((card) =>
          popular.some((drop) => drop.title === card.Title)
        );

        dropCards.unshift(...popular);
        setSortedDropCards(dropCards);
      }
    }

    setSelectedDropdownOption({
      label: selected.label,
      value: selected.value,
      img: (
        <img
          src="../icon/sort-selected-tick.svg"
          alt="tick"
          style={{
            marginRight: "8px", // Adjust as needed
            height: "16px", // Adjust as needed
            width: "auto", // Maintain aspect ratio
          }}
        />
      ),
    });
  };

  const CustomSingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <span style={{ fontWeight: "500" }}>Sort by:</span>{" "}
      <span style={{ fontWeight: "400" }}>{children}</span>
    </components.SingleValue>
  );

  const CustomOption = (props) => {
    const { children, isSelected } = props;
    return (
      <components.Option {...props}>
        {children}

        {isSelected && (
          <img
            src="../icon/sort-selected-tick.svg"
            alt="tick"
            style={{
              height: "20px",
              width: "20px",
            }}
          />
        )}
      </components.Option>
    );
  };

  return (
    <span className="drop-body">
      <Banner
        dropCardData={dropCardsData}
        setSearchedDrop={setSearchedDrop}
        productFilter={productFilter}
        setProductFilter={setProductFilter}
        programmingLanguage={programmingLanguage}
        setProgrammingLanguage={setProgrammingLanguage}
        difficulty={difficulty}
        setDifficulty={setDifficulty}
        type={type}
        setType={setType}
        topics={topic}
        setTopics={setTopic}
        lastUpdated={lastUpdated}
        setLastUpdated={setLastUpdated}
        inputSearch={inputSearch}
        setInputSearch={setInputSearch}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: 100,
          paddingBottom: 120,
        }}
      >
        <SideBar
          lastUpdated={lastUpdated}
          setLastUpdated={setLastUpdated}
          tagCheckBox={tagCheckBox}
          setTagCheckBox={setTagCheckBox}
          difficulty={difficulty}
          setDifficulty={setDifficulty}
          programmingLanguage={programmingLanguage}
          setProgrammingLanguage={setProgrammingLanguage}
          productFilter={productFilter}
          setProductFilter={setProductFilter}
          type={type}
          setType={setType}
          topic={topic}
          setTopic={setTopic}
        />
        <div
          style={{
            paddingLeft: 25,
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 15,
            }}
          >
            <div
              style={{
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: 400,
                color: "#fff",
                paddingTop: 15,
              }}
            >
              Showing {showingCount.current} results
            </div>
            <div>
              <Select
                options={sortOptions}
                value={selectedDropdownOption}
                placeholder="Sort by"
                onChange={handleSelectedSortDropdownOption}
                components={{
                  SingleValue: CustomSingleValue,
                  Option: CustomOption,
                }}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#242424",
                    width: "200px",

                    marginRight: "10px",
                    boxShadow:
                      "0px 4px 20px 0px rgba(255, 255, 255, 0.15), 0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
                    border: "1px solid var(--Neutral-Stroke-1-Rest, #666)",

                    textAlign: "left",

                    zIndex: 5,
                    "&:hover": {
                      backgroundColor: "#383838",
                      borderColor: "#666",
                    },
                    ...(state.selectProps.menuIsOpen && {
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.15)",
                    }),
                  }),
                  menu: (provided) => ({
                    ...provided,
                    background: "#242424",
                    color: "#ffffff",

                    marginRight: "10px",
                    width: "200px",
                    marginTop: -5,
                    borderColor: "white",

                    outline: "",
                    boxShadow:
                      "0px 4px 20px 0px rgba(255, 255, 255, 0.15), 0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
                    borderRadius: "4px 4px 4px 4px",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    textAlign: "left",
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    paddingBottom: 0,
                    borderRadius: "4px",
                  }),
                  option: (defaultStyles, state) => ({
                    ...defaultStyles,

                    backgroundColor: state.isSelected ? "#383838" : "#242424",
                    fontWeight: 400,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: "#383838",
                    },
                  }),
                  input: (styles) => ({
                    ...styles,
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    color: "White",
                  }),
                  placeholder: (styles) => ({
                    ...styles,
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    color: "#ffffff",
                  }),
                  singleValue: (styles) => ({
                    ...styles,
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    color: "#ffffff",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    transform: state.selectProps.menuIsOpen
                      ? "rotate(-180deg)"
                      : null,
                    transition: "transform 0.3s",
                    color: "white",
                    "&:hover": {
                      color: "white",
                    },
                  }),
                }}
                isSearchable={false}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 25,
            }}
          >
            <DropCardContainer />
          </div>
        </div>
      </div>
      <div></div>
      <Footer footerData={footerData} />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          handleShowScrollBarONMainPage();
        }}
        onAfterClose={() => {
          setModalIsOpen(false);
          handleShowScrollBarONMainPage();
        }}
        style={modalStyles}
      >
        <ModalHeader
          darkMode={darkMode}
          onClose={() => {
            setModalIsOpen(false);
            handleShowScrollBarONMainPage();
          }}
        />
        <ModalBody darkMode={darkMode} />
        <div
          className="enlarge-circle"
          onClick={() => setEnlargeRightBar(!enlargeRightBar)}
        >
          <img
            style={{}}
            src={
              enlargeRightBar
                ? "../icon/collapse-panel.svg"
                : "../icon/enlarge-panel.svg"
            }
            alt=""
          />
        </div>
      </Modal>
    </span>
  );
};

export default JumpStartDrops;
