import React from "react";

const SidepanelCardSchema = ({ onClose }) => {
  const closeButtonStyles = {
    position: "absolute",
    top: "0",
    right: "0",
    transform: "translate(-50% , 351%)",
    cursor: "pointer",
    color: "#333",
    fontSize: "20px",
  };

  return (
    <div style={{ width: "auto" }} className="card-schema">
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          alignItems: "center",
          marginRight: "10px",
          paddingTop: "10px",
          cursor: "pointer",
        }}
        onClick={onClose}
      >
        <img style={closeButtonStyles} src="./icon/close-modal.svg" />
      </span>
      <div
        style={{
          height: "906px",
        }}
      >
        <h2>Side panel example</h2>
        <img
          className="example-test-img"
          src="./images/drops/sidepanelPreview.png"
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
};

export default SidepanelCardSchema;
