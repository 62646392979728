import Footer from '../../navigation/footer/Footer';
import styles from "./JumpstartScenarios.module.css";
import OverviewCard from "../cards/OverviewCard";
import OverviewCardContainer from '../cards/OverviewCardContainer';

const JumpstartScenarios = ({ node, footerData }) => {
  return (
    <div className={styles["frame-2018776051"]}>
      <div className={styles["frame-2018775814"]}>
        <div className={styles["hero-images"]}>
          <div className={styles["group-1010107217"]}>
            <div className={styles["jumpstart-scenarios"]}>
              <span>
                <span className={styles["jumpstart-scenarios-span"]}>
                  Jumpstart{" "}
                </span>
                <span className={styles["jumpstart-scenarios-span2"]}>
                  Scenarios
                </span>
              </span>{" "}
            </div>
            <div className={styles["multiple-environments"]}>
              MULTIPLE ENVIRONMENTS{" "}
            </div>
            <div
              className={
                styles[
                "get-automated-zero-to-hero-scenarios-and-experience-the-full-breadth-of-jumpstart-all-you-need-is-an-azure-subscription-to-get-started"
                ]
              }
            >
              Get automated zero-to-hero scenarios and experience the full
              breadth of Jumpstart. All you need is an Azure subscription to get
              started.{" "}
            </div>
          </div>
          <div className={styles["illustration-scenarios"]}>
            <img
              src='svgs/Illustration_Scenarios.svg'
              alt='Illustration_Scenarios'
              style={{
                position: 'absolute',
                top: 150,
                left: 635,
                width: 685,
                height: 685,
              }}
            />
          </div>
        </div>
        <OverviewCardContainer node={node} />
        <Footer footerData={footerData} />
      </div>
    </div>
  );
};

export default JumpstartScenarios;
