// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-list {
  
}

.custom-list p.custom-paragraph {
    margin: 0px;
    padding-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Overrides/List.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,WAAW;IACX,oBAAoB;AACxB","sourcesContent":[".custom-list {\n  \n}\n\n.custom-list p.custom-paragraph {\n    margin: 0px;\n    padding-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
