// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-table-header {
    padding: 0.625rem 0.75rem;
    border: 1px solid #BDBDBD;
    background: #38434D;
}`, "",{"version":3,"sources":["webpack://./src/components/Overrides/TableHeader.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB;AACvB","sourcesContent":[".custom-table-header {\n    padding: 0.625rem 0.75rem;\n    border: 1px solid #BDBDBD;\n    background: #38434D;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
