import React from "react";
import styles from "./GitHub.module.css";

export const GitHub = (props) => {
  return (
    <a
      className="git-hub-button focus-on-tab"
      style={{
        cursor: "pointer",
        color: "white",
        textDecoration: "none",
      }}
      tabIndex={0}
      href="https://github.com/Azure/arc_jumpstart_docs"
      target="_blank"
    >
      <div className={styles["state-rest-item-git-hub"]}>
        <div className={styles["frame-59941"]}>
          <div className={styles["git-hub"]}>GitHub </div>
          <span className={styles["git-hub-1"]}></span>
        </div>
      </div>
    </a>
  );
};

export default GitHub;
