import React from 'react';
import { Link } from 'react-router-dom';
import './Anchor.css';

// Override Anchor
export const Anchor = ({ children, path, href }) => {
    const externalLink = href.includes('http' || 'https');
    const isRelativeLink = href.includes('./' || '../');
    if (!externalLink) {
        href = href.replace(/\/_index.md/g, '');
    }

    return externalLink ? (
        <a className='custom-anchor' href={href} target='_blank' rel="noreferrer">
            {children}
        </a>
    ) : (
        isRelativeLink ? (
            <Link to={href} relative='path'>
                <a className='custom-anchor'>
                    {children}
                </a>
            </Link>
        ) : (
            <Link to={href}>
                <a className='custom-anchor'>
                    {children}
                </a>
            </Link>
        )
    )
};
