// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/note-icon.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/disclaimer-icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-blockquote {
  background-color: #2f3646;
  color: #b2cdff;
  /* padding: left 50px right 25px top and bottom 23px */
  padding: 23px 25px 23px 50px;
  margin-left: 0px;
  margin-right: 0px;
}

.custom-blockquote p {
  position: relative;
}

.custom-blockquote p em code {
  color: #63d0ff;
}

.custom-blockquote p::before {
  content: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  position: absolute;
  top: 2px;
  left: -35px;
  /* Adjust this value to position the SVG to the left of the paragraph */
}

.custom-blockquote-disclaimer {
  background-color: #434023;
  color: #fff176;

  padding: 23px 25px 23px 50px;
  margin-left: 0px;
  margin-right: 0px;
}
.custom-blockquote-disclaimer p {
  position: relative;
  text-align: justify;
}

.custom-blockquote-disclaimer p::before {
  content: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  position: absolute;
  top: 2px;
  left: -35px;
  /* Adjust this value to position the SVG to the left of the paragraph */
}
`, "",{"version":3,"sources":["webpack://./src/components/Overrides/Blockquote.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,sDAAsD;EACtD,4BAA4B;EAC5B,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gDAA6C;EAC7C,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,uEAAuE;AACzE;;AAEA;EACE,yBAAyB;EACzB,cAAc;;EAEd,4BAA4B;EAC5B,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,gDAAmD;EACnD,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,uEAAuE;AACzE","sourcesContent":[".custom-blockquote {\n  background-color: #2f3646;\n  color: #b2cdff;\n  /* padding: left 50px right 25px top and bottom 23px */\n  padding: 23px 25px 23px 50px;\n  margin-left: 0px;\n  margin-right: 0px;\n}\n\n.custom-blockquote p {\n  position: relative;\n}\n\n.custom-blockquote p em code {\n  color: #63d0ff;\n}\n\n.custom-blockquote p::before {\n  content: url(\"../../../public/note-icon.svg\");\n  position: absolute;\n  top: 2px;\n  left: -35px;\n  /* Adjust this value to position the SVG to the left of the paragraph */\n}\n\n.custom-blockquote-disclaimer {\n  background-color: #434023;\n  color: #fff176;\n\n  padding: 23px 25px 23px 50px;\n  margin-left: 0px;\n  margin-right: 0px;\n}\n.custom-blockquote-disclaimer p {\n  position: relative;\n  text-align: justify;\n}\n\n.custom-blockquote-disclaimer p::before {\n  content: url(\"../../../public/disclaimer-icon.svg\");\n  position: absolute;\n  top: 2px;\n  left: -35px;\n  /* Adjust this value to position the SVG to the left of the paragraph */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
