// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CopyCodeToClipboard_container__8WhLL,
.CopyCodeToClipboard_container__8WhLL * {
  box-sizing: border-box;
}

.CopyCodeToClipboard_container__8WhLL { 
  position: relative;
  padding: 0.625rem;
  display: flex;
  flex-direction: row;
}

.CopyCodeToClipboard_icon__af3ZY {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/buttons/CopyCodeToClipboard.module.css"],"names":[],"mappings":"AAAA;;EAEE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,iBAAiB;AACnB","sourcesContent":[".container,\n.container * {\n  box-sizing: border-box;\n}\n\n.container { \n  position: relative;\n  padding: 0.625rem;\n  display: flex;\n  flex-direction: row;\n}\n\n.icon {\n  position: absolute;\n  left: 0px;\n  top: 0px;\n  overflow: visible;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CopyCodeToClipboard_container__8WhLL`,
	"icon": `CopyCodeToClipboard_icon__af3ZY`
};
export default ___CSS_LOADER_EXPORT___;
