// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdaptiveCloud_state-rest-action-link-tab-adaptive-cloud__rflaP,
.AdaptiveCloud_state-rest-action-link-tab-adaptive-cloud__rflaP * {
    box-sizing: border-box;
}

.AdaptiveCloud_state-rest-action-link-tab-adaptive-cloud__rflaP {
    background: var(--neutral-background-5-rest, #000000);
    padding: 14px 10px 14px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
}
.AdaptiveCloud_state-rest-action-link-tab-adaptive-cloud__rflaP:hover {
    background-color: #333;
}
.AdaptiveCloud_state-rest-action-link-tab-adaptive-cloud__rflaP:active {
    background-color: #242424;
}
.AdaptiveCloud_frame-59941__ApnQ7 {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
}

.AdaptiveCloud_adaptive-cloud__HlUBs {
    color: var(--neutral-foreground-1-rest, #ffffff);
    text-align: center;
    font: 400 13px/20px "Segoe UI", sans-serif;
    font-style: normal;
    letter-spacing: 0.65px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/navigation/buttons/AdaptiveCloud.module.css"],"names":[],"mappings":"AAAA;;IAEI,sBAAsB;AAC1B;;AAEA;IACI,qDAAqD;IACrD,4BAA4B;IAC5B,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,uBAAuB;IACvB,2BAA2B;IAC3B,kBAAkB;AACtB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,mBAAmB;IACnB,2BAA2B;IAC3B,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,gDAAgD;IAChD,kBAAkB;IAClB,0CAA0C;IAC1C,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".state-rest-action-link-tab-adaptive-cloud,\n.state-rest-action-link-tab-adaptive-cloud * {\n    box-sizing: border-box;\n}\n\n.state-rest-action-link-tab-adaptive-cloud {\n    background: var(--neutral-background-5-rest, #000000);\n    padding: 14px 10px 14px 10px;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    align-items: flex-start;\n    justify-content: flex-start;\n    position: relative;\n}\n.state-rest-action-link-tab-adaptive-cloud:hover {\n    background-color: #333;\n}\n.state-rest-action-link-tab-adaptive-cloud:active {\n    background-color: #242424;\n}\n.frame-59941 {\n    display: flex;\n    flex-direction: row;\n    gap: 4px;\n    align-items: center;\n    justify-content: flex-start;\n    flex-shrink: 0;\n    position: relative;\n}\n\n.adaptive-cloud {\n    color: var(--neutral-foreground-1-rest, #ffffff);\n    text-align: center;\n    font: 400 13px/20px \"Segoe UI\", sans-serif;\n    font-style: normal;\n    letter-spacing: 0.65px;\n    position: relative;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"state-rest-action-link-tab-adaptive-cloud": `AdaptiveCloud_state-rest-action-link-tab-adaptive-cloud__rflaP`,
	"frame-59941": `AdaptiveCloud_frame-59941__ApnQ7`,
	"adaptive-cloud": `AdaptiveCloud_adaptive-cloud__HlUBs`
};
export default ___CSS_LOADER_EXPORT___;
