import React, { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';
import { Heading1 } from './components/Overrides/Heading1';
import { Heading2 } from './components/Overrides/Heading2';
import { Heading3 } from './components/Overrides/Heading3';
import { Heading4 } from './components/Overrides/Heading4';
import { Heading5 } from './components/Overrides/Heading5';
import { Heading6 } from './components/Overrides/Heading6';
import { Paragraph } from './components/Overrides/Paragraph';
import { Anchor } from './components/Overrides/Anchor';
import { Image } from './components/Overrides/Image';
import { List } from './components/Overrides/List';
import { OrderedList } from './components/Overrides/OrderedList';
import { ListItem } from './components/Overrides/ListItem';
import { InlineCode } from './components/Overrides/InlineCode';
import { CodeBlock } from './components/Overrides/CodeBlock';
import { Blockquote } from './components/Overrides/Blockquote';
import { Table } from './components/Overrides/Table';
import { TableRow } from './components/Overrides/TableRow';
import { TableHeader } from './components/Overrides/TableHeader';
import { TableCell } from './components/Overrides/TableCell';
import { ThematicBreak } from './components/Overrides/ThematicBreak';
import { Bold } from './components/Overrides/Bold';
import { Italics } from './components/Overrides/Italics';
import './MarkdownPage.css';
import EditGithub from './components/pages/buttons/EditGithub';
import CreateChildPage from './components/pages/buttons/CreateChildPage';
import ReportBug from './components/pages/buttons/ReportBug';
import Feedback from './components/pages/buttons/Feedback';

const branch = process.env.REACT_APP_DOCS_BRANCH || 'canary';

function MarkdownPage({ node, path, updateSections }) {
    const pathToRoot = `https://raw.githubusercontent.com/Azure/arc_jumpstart_docs/${branch}`;
    const pathToDocs = `https://raw.githubusercontent.com/Azure/arc_jumpstart_docs/${branch}/docs/`;
    const pathToEditDocs = `https://github.com/Azure/arc_jumpstart_docs/edit/${branch}/docs/`;
    const [markdown, setMarkdown] = useState('');

    const removeFrontmatter = (text) => {
        return text.replace(/---[\s\S]*?---/, '');
    };

    useEffect(() => {
        async function getMarkdown(currentNode) {
            try{
            const fullPath = `${pathToDocs}${path}/_index.md`;
            const response = await fetch(fullPath);
            const text = await response.text();
            const markdown = removeFrontmatter(text);
            let htmlContent = '';
            let faqPath = '';
            let faqContent = '';
            if (currentNode && currentNode.children && currentNode.children.length > 0) {
                htmlContent = createHtml(currentNode, false);
                // for each child
                currentNode.children.forEach((child) => {
                    if (child.path.includes('faq') || child.path.includes('FAQ')) {
                        faqPath = child.path;
                    } else {
                        htmlContent = `${htmlContent}${createHtml(child)}`;
                    }
                });
            }
            let content = '';
            if (markdown.replace(/\s/g, "") !== '') {
                content = markdown;
            } else {
                content = htmlContent;
            }
            if (faqPath !== '') {
                faqContent = await createFAQHtml(currentNode, faqPath.replace('\\/g', '/'));
                content = `${content}${faqContent}`;
            }
            setMarkdown(content);}
            catch(err){
                console.log(err)
            }
        }

        const currentNode = findNode(node, path);
        getMarkdown(currentNode);
    }, [path]);

    useEffect(() => {
        if (markdown) {
            updateSections();
        }
    }, [markdown]);

    const createHtml = (node, link = true) => {
        // convert node.path needs to a relative path
        // remove all but the last part of the path and replace with ./
        const relativePath = node.path.replace(/.*\/(.*)/, './$1');

        let html = '';
        if (node.frontMatter) {
            if (node.frontMatter.title) {
                if (link) {
                    html = `<h3><a href='${relativePath}' rel="noopener noreferrer">${node.frontMatter.title}</a></h3>`;
                } else {
                    html = `<h2>${node.frontMatter.title}</h2>`;
                }
            } else if (node.frontMatter.linkTitle) {
                if (link) {
                    html = `<h3><a href='${relativePath}' rel="noopener noreferrer">${node.frontMatter.linkTitle}</a></h3>`;
                } else {
                    html = `<h2>${node.frontMatter.linkTitle}</h2>`;
                }
            }
            if (node.frontMatter.description) {
                html = `${html}<p>${node.frontMatter.description}</p>`;
            } else {
                html = `${html}<p></p>`;
            }
        }
        return html;
    }

    const createFAQHtml = async (node, path) => {
        const fullPath = `${pathToDocs}${path}/_index.md`;
        const response = await fetch(fullPath);
        const text = await response.text();
        const markdown = removeFrontmatter(text);
        const modifiedMarkdown = markdown;//.replace(/(## [^\n]+\n)([^#]+)/g, '<Stack>\n$1$2</Stack>\n');

        return modifiedMarkdown;
    }

    const Stack = ({ children }) => {
        // a header that can be clicked to show/hide the content below it
        const [show, setShow] = useState(false);
        // the header is the innerText of first child, which is an h2 element
        const header = children[0].props.children;
        // the content is the rest of the children
        const content = children.slice(1);
        return (
            <div className='stack'>
                <div className='stack-header' style={{ cursor: 'pointer' }} onClick={() => setShow(!show)}>
                    {header}
                </div>
                {show && <div className='stack-content'>{content}</div>}
            </div>
        );
    }

    const findNode = (node, path) => {
        if (node.path.replace('\\/g', '/') === path.replace('\\/g', '/')) {
            return node;
        } else if (node.children) {
            let result = null;
            for (let i = 0; result == null && i < node.children.length; i++) {
                result = findNode(node.children[i], path);
            }
            return result;
        }
        return null;
    }

    const editInGitHub = `${pathToEditDocs}${path}/_index.md`;
    const createChildPage = `https://github.com/Azure/arc_jumpstart_docs/new/${branch}/docs/${path}`;
    const createAnIssue = `https://github.com/microsoft/azure_arc/issues/new?assignees=&labels=triage&projects=&template=bug_report.md&title=${path}`;
    const createAFeatureRequest = `https://github.com/microsoft/azure_arc/issues/new?assignees=&labels=&projects=&template=feature_request.md&title=`;

    // replace inline hyphens so they don't get mistaken as a sub list
    const markdownText = markdown.replace(/(- .+?) - /g, "$1 \HYPHEN ");
    // const modifiedHtml = markdownText.replace(/(<h2>.*?<\/h2>)(.*?)(?=<h\d>|$)/s, '$1<Feedback/>$2');
    // const modifiedMarkdown = modifiedHtml.replace(/(## [^\n]+\n)([^#]+)/, '$1<Feedback/>\n$2');
    // const updatedMarkdown = markdownText.replace(/(\n\s*```[^`]*```\s*)(\n\s*!\[.*?\]\(.*?\)\s*)/g, "$1<empty></empty>$2");

    const updatedMarkdown = markdownText.replace(
        /(```[^`]*```|```[^`]*\n*```|```[^`]*```)(\n\s*!\[.*?\]\(.*?\)\s*)/g,
        "$1<empty></empty>$2"
    );
    

    return (
        <div className='markdown-page-container'>
            <div className='markdown-page'>
                <div className='github-links'>
                    <EditGithub href={editInGitHub} />
                    <CreateChildPage href={createChildPage} />
                    <ReportBug href={createAnIssue} />
                </div>
                <div className='github-links'>
                    <Feedback href={createAFeatureRequest}/>
                </div>
                <Markdown
                    options={{
                        overrides: {
                            empty: () => <></>,
                            Stack: Stack,
                            a: (props) => <Anchor {...props} path={path} />,
                            blockquote: Blockquote,
                            code: InlineCode,
                            em: Italics,
                            h1: Heading1,
                            h2: Heading2,
                            h3: Heading3,
                            h4: Heading4,
                            h5: Heading5,
                            h6: Heading6,
                            hr: ThematicBreak,
                            img: (props) => <Image {...props} basePath={pathToRoot} path={path} />,
                            li: ListItem,
                            ol: OrderedList,
                            p: Paragraph,
                            pre: CodeBlock,
                            strong: Bold,
                            table: Table,
                            td: TableCell,
                            th: TableHeader,
                            tr: TableRow,
                            ul: List
                        }
                    }}
                >
                    {updatedMarkdown}
                </Markdown>
            </div>
        </div>
    );
}

export default MarkdownPage;
