import React from "react";
import styles from './AgoraOverviewCard.module.css';
import { Link } from "react-router-dom";

const AgoraOverviewCard = ({ node }) => {
  const Card = ({node, enabled}) => {
    const { Image, ImageAlt, Title, Description, LinkTitle } = node;
    const cardVisualStyle = enabled ? styles["card-visual"] : styles["card-visual-disabled"];
    return (
      <div className={cardVisualStyle}>
        <div className={styles["card-image"]}>
          <img
            className={styles["rectangle-34656627"]}
            src={`images/agora/${Image}`}
            alt={ImageAlt}
          />
        </div>
        <div className={styles["group-1010107219"]}>
          <div className={styles["rectangle-38708"]}></div>
        </div>
        <div className={styles["frame-2018775824"]}>
          <div className={styles["frame-2018775810"]}>
            <div className={styles["frame-44"]}>
              <div className={styles["general-object-detection"]}>
                <div className={styles["frame-2018775799"]}>
                  <div className={styles["frame-2018775798"]}>
                    <div className={styles["industry"]}>{Title} </div>
                    <div className={styles["description"]}>
                      {Description}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles["frame-2018775809"]}>
              <div className={styles["frame-2018775809"]}>
                <div className={styles["view-applications"]}>
                  {LinkTitle}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  };

  const isValidLink = node.Link && node.Link !== "";
  return isValidLink ? (
    <Link to={node.Link} relative='path' style={{ zIndex: 1000 }} disabled>
      <Card node={node} enabled={true} />
    </Link>
  ) : (
    <Card node={node} enabled={false}/>
  )
};

export default AgoraOverviewCard;
