import { ApplicationInsights } from '@microsoft/1ds-analytics-web-js';
import { useEffect } from 'react';

export const useAnalytics = () => {
    const instrumentationKey = "f09c8d7dfd2e4508b6675609afe24cec-aea00420-3f47-4ae4-9516-43704c06e20f-7496";
    const analytics = new ApplicationInsights();
    var config = {
        instrumentationKey: instrumentationKey,
        channelConfiguration: {
            eventsLimitInMem: 5000,
        },
        propertyConfiguration: {
            env: "PROD",
        },
        webAnalyticsConfiguration: {
            autoCapture: {
                scroll: true,
                pageView: true,
                onLoad: true,
                onUnload: true,
                click: true,
                resize: true,
                jsError: true
            }
        }
    };

    //Initialize SDK
    useEffect(() => {
        analytics.initialize(config, []);
    }, [])
};