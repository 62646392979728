import React from "react";
import Footer from "../../navigation/footer/Footer";
import Error from "../../error/error";

const TooManyRequestError = ({ footerData }) => {
  return (
    <>
      <Error
        title="429 ERROR"
        header="Too many requests"
        description="There was an error downloading the drop. Please try again later."
        imgLink="./svgs/429error.svg"
      />
      <Footer footerData={footerData} />
    </>
  );
};

export default TooManyRequestError;
