import styles from "./Training.module.css";
import OverviewCard from "../cards/OverviewCard";
import Footer from '../../navigation/footer/Footer';
import OverviewCardContainer from "../cards/OverviewCardContainer";

const Training = ({ node, footerData }) => {
  return (
    <div className={styles["complete-training"]}>
      <div className={styles["frame-2018776051"]}>
        <div className={styles["frame-2018775814"]}>
          <div className={styles["hero-images"]}>
            <div className={styles["frame-1"]}>
              <div className={styles["carousel-1"]}></div>
            </div>
            <div className={styles["group-1010107217"]}>
              <div className={styles["boost-your-jumpstart-skills"]}>
                Boost your Jumpstart skills{" "}
              </div>
              <div className={styles["training"]}>TRAINING </div>
              <div
                className={
                  styles[
                  "made-for-all-experience-levels-microsoft-learning-paths-offer-a-hands-on-approach-to-learning-our-products"
                  ]
                }
              >
                Made for all experience levels, Microsoft learning paths offer a
                hands-on approach to learning our products.{" "}
              </div>
            </div>
            <div className={styles["group-1321317132"]}>
            <img
                  src='webp/Illustration_Training.webp'
                  alt='Illustration_Training'
                  style={{
                    position: 'absolute',
                    top: 128,
                    left: 603
                  }}
                />
            </div>
          </div>
          <OverviewCardContainer node={node} />
          <Footer footerData={footerData} />
        </div>
      </div>
    </div>
  );
};

export default Training;



