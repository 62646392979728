// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavBarLower_navbarLowerContainer__lagdN {
  display: flex;
  justify-content: space-between;
  align-items:flex-start;
  min-width: 1280px;
  max-width: 1440px;
  margin: 0 auto;
}

.NavBarLower_breadcrumbsContainer__kCE-p {
  padding-left: 50px; 
}

.NavBarLower_dropdownContainer__kSbe1 {
  margin-right: -9px;
}
`, "",{"version":3,"sources":["webpack://./src/components/navigation/NavBarLower.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,sBAAsB;EACtB,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".navbarLowerContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items:flex-start;\n  min-width: 1280px;\n  max-width: 1440px;\n  margin: 0 auto;\n}\n\n.breadcrumbsContainer {\n  padding-left: 50px; \n}\n\n.dropdownContainer {\n  margin-right: -9px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbarLowerContainer": `NavBarLower_navbarLowerContainer__lagdN`,
	"breadcrumbsContainer": `NavBarLower_breadcrumbsContainer__kCE-p`,
	"dropdownContainer": `NavBarLower_dropdownContainer__kSbe1`
};
export default ___CSS_LOADER_EXPORT___;
