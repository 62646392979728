import "./SubMenuItem.css";

const SubMenuItem = ({ children, isSelected, onClick, itemRef, index }) => {
  return (
    <div
      className={isSelected ? "sub-menu-item-selected" : "sub-menu-item"}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key == "Enter" || e.key == "Space") onClick();
      }}
      style={{ cursor: "pointer" }}
      role="button"
      tabIndex="0"

    >
      {isSelected && <div className="sub-menu-tab-line"></div>}
      <div
        className={
          isSelected
            ? "sub-menu-item-overview-selected"
            : "sub-menu-item-overview"
        }
      >
        {children}
      </div>
    </div>
  );
};

export default SubMenuItem;
