import React from "react";
import { Link } from "react-router-dom";
import styles from "./Home.module.css";
import Footer from "../navigation/footer/Footer";
import HomeBlog from "./blogs/HomeBlog";
import HomeCard from "./cards/HomeCard";

export const Home = ({ blogs, cards, footerData }) => {
  const homeBlogs = blogs
    .filter((blog) => blog.hasOwnProperty("HomeIndex"))
    .sort((a, b) => a.HomeIndex - b.HomeIndex);

  return (
    <>
      <div className={styles["home-v-5"]}>
        <div className={styles["frame"]}>
          <div className={styles["frame-2018775814"]}>
            <div className={styles["discover"]}>
              <div className={styles["group-1010107217"]}>
                <div className={styles["frame-2018776031"]}>
                  <div className={styles["frame-2018776030"]}>
                    <div className={styles["frame-2018776029"]}>
                      <div className={styles["discover2"]}>DISCOVER </div>
                      <div className={styles["arc-jumpstart"]}>
                        Arc Jumpstart{" "}
                      </div>
                    </div>
                    <div
                      className={
                        styles[
                          "get-an-azure-arc-environment-up-and-running-in-no-time-extensive-automated-and-open-source"
                        ]
                      }
                    >
                      Get an Azure Arc environment up and running in no
                      time—extensive, automated, and open-source.{" "}
                    </div>
                  </div>
                  <div className={styles["frame-2018775982"]}>
                    <Link to={"/jumpstart_overview"} style={{ zIndex: 1000 }}>
                      <div className={styles["frame-2018775983"]}>
                        <div className={styles["frame-2018775982"]}>
                          <div className={styles["primary-button"]}>
                            <div className={styles["primary"]}>
                              Get started{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <a
                      href={"https://aka.ms/JumpstartDemos"}
                      target="_blank"
                      style={{ zIndex: 1000 }}
                      rel="noopener noreferrer"
                    >
                      <div className={styles["secondary-button"]}>
                        <div className={styles["secondary"]}>View demos </div>
                        <svg
                          className={styles["icon"]}
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 4.41211C4.89543 4.41211 4 5.30754 4 6.41211V14.4121C4 15.5167 4.89543 16.4121 6 16.4121H14C15.1046 16.4121 16 15.5167 16 14.4121V11.9121C16 11.636 16.2239 11.4121 16.5 11.4121C16.7761 11.4121 17 11.636 17 11.9121V14.4121C17 16.069 15.6569 17.4121 14 17.4121H6C4.34315 17.4121 3 16.069 3 14.4121V6.41211C3 4.75526 4.34315 3.41211 6 3.41211H8.5C8.77614 3.41211 9 3.63597 9 3.91211C9 4.18825 8.77614 4.41211 8.5 4.41211H6ZM11 3.91211C11 3.63597 11.2239 3.41211 11.5 3.41211H16.5C16.7761 3.41211 17 3.63597 17 3.91211V8.91211C17 9.18825 16.7761 9.41211 16.5 9.41211C16.2239 9.41211 16 9.18825 16 8.91211V5.11922L11.8536 9.26566C11.6583 9.46093 11.3417 9.46093 11.1464 9.26566C10.9512 9.0704 10.9512 8.75382 11.1464 8.55856L15.2929 4.41211H11.5C11.2239 4.41211 11 4.18825 11 3.91211Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className={styles["group-1321317051"]}>
                {/* <img
                                    src='svgs/Illustration-Main.svg'
                                    alt='Illustration-Main'
                                    style={{
                                        position: 'absolute',
                                        top: 175,
                                        left: 645,
                                        width: 665,
                                        height: 665,
                                    }}
                                /> */}
              </div>
            </div>
            <div className={styles["explore-jumpstart"]}>
              <div className={styles["frame-44"]}>
                <div className={styles["design"]}>
                  <div className={styles["design"]}>
                    <div className={styles["explore"]}>EXPLORE </div>
                    <div className={styles["the-arc-jumpstart-universe"]}>
                      The Arc Jumpstart universe{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles["frame-2018776043"]}>
                <div className={styles["frame-2018776040"]}>
                  {cards.map((card, index) => (
                    <HomeCard key={index} {...card} />
                  ))}
                </div>
              </div>
            </div>
            <div className={styles["our-mission"]}>
              <div className={styles["frame-20187759822"]}>
                <Link to="/community">
                  <div className={styles["frame-2018775982"]}>
                    <div className={styles["primary-button"]}>
                      <div className={styles["primary"]}>
                        Jump to community{" "}
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/mission">
                  <div className={styles["secondary-button"]}>
                    <div className={styles["secondary"]}>Learn more </div>
                  </div>
                </Link>
              </div>
              <div className={styles["frame-2018775808"]}>
                <div className={styles["frame-443"]}>
                  <div className={styles["design"]}>
                    <div className={styles["our-mission2"]}>OUR MISSION </div>
                    <div
                      className={
                        styles["community-driven-and-committed-to-open-source"]
                      }
                    >
                      Community-driven and committed to open source{" "}
                    </div>
                    <div className={styles["frame-20187759832"]}>
                      <div
                        className={
                          styles[
                            "our-mission-to-support-the-open-source-community-where-we-can-all-build-on-the-amazing-work-of-others-we-do-this-by-providing-detailed-guides-automation-code-samples-everything-you-need-to-get-going-quickly-we-also-have-extensive-code-reviews-and-testing-to-ensure-our-code-is-reusable-comprehensive-and-reliable-currently-we-re-committed-to-supporting-over-30-open-source-jumpstart-projects"
                          ]
                        }
                      >
                        Our mission to support the open-source community, where
                        we can all build on the amazing work of others. <br />
                        <br />
                        We do this by providing detailed guides, automation,
                        code samples—everything you need to get going quickly.
                        We also have extensive code reviews and testing to
                        ensure our code is reusable, comprehensive, and
                        reliable. <br />
                        <br />
                        Currently, we’re committed to supporting over 30
                        open-source Jumpstart projects.{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles["our-mission3"]}>
                {/* <img
                                    src='svgs/Illustration_Home_Our-Mission.svg'
                                    alt='Illustration_Home_Our-Mission'
                                    style={{
                                        position: 'absolute',
                                        top: -240,
                                        left: -250,
                                        width: 1050,
                                        height: 1050,
                                    }}
                                /> */}
              </div>
            </div>
            <div className={styles["demos"]}>
              <div className={styles["design2"]}>
                <div className={styles["demos2"]}>DEMOS </div>
                <div className={styles["see-arc-jumpstart-in-action"]}>
                  See Arc Jumpstart in action{" "}
                </div>
                <div className={styles["frame-20187759833"]}>
                  <div className={styles["get-inspired-div"]}>
                    <span>
                      <span className={styles["get-inspired-span"]}>
                        Get inspired by game-changing solutions built on Arc
                        Jumpstart—see every scenario in a bite-sized demo.{" "}
                        <br />
                        <br />
                        Want to see your Azure Arc demo featured here? <br />
                      </span>
                      <a
                        href="https://aka.ms/LightningGuest"
                        target="_blank"
                        style={{ cursor: "pointer" }}
                        rel="noopener noreferrer"
                      >
                        <span className={styles["get-inspired-span2"]}>
                          Fill out this submission form for Jumpstart Lighting.
                        </span>
                      </a>
                    </span>
                  </div>
                </div>
                <div className={styles["frame-20187759823"]}>
                  <a
                    href="https://aka.ms/JumpstartDemos"
                    target="_blank"
                    style={{ zIndex: 1000 }}
                    rel="noopener noreferrer"
                  >
                    <div className={styles["frame-2018775982"]}>
                      <div className={styles["primary-button"]}>
                        <div className={styles["primary"]}>View demos </div>
                        <svg
                          className={styles["icon2"]}
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 4C4.89543 4 4 4.89543 4 6V14C4 15.1046 4.89543 16 6 16H14C15.1046 16 16 15.1046 16 14V11.5C16 11.2239 16.2239 11 16.5 11C16.7761 11 17 11.2239 17 11.5V14C17 15.6569 15.6569 17 14 17H6C4.34315 17 3 15.6569 3 14V6C3 4.34315 4.34315 3 6 3H8.5C8.77614 3 9 3.22386 9 3.5C9 3.77614 8.77614 4 8.5 4H6ZM11 3.5C11 3.22386 11.2239 3 11.5 3H16.5C16.7761 3 17 3.22386 17 3.5V8.5C17 8.77614 16.7761 9 16.5 9C16.2239 9 16 8.77614 16 8.5V4.70711L11.8536 8.85355C11.6583 9.04882 11.3417 9.04882 11.1464 8.85355C10.9512 8.65829 10.9512 8.34171 11.1464 8.14645L15.2929 4H11.5C11.2239 4 11 3.77614 11 3.5Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    </div>
                  </a>
                  <a
                    href="https://aka.ms/JumpstartLightning"
                    target="_blank"
                    style={{ zIndex: 1000 }}
                    rel="noopener noreferrer"
                  >
                    <div className={styles["secondary-button"]}>
                      <div className={styles["secondary"]}>
                        Open Jumpstart Lighting{" "}
                      </div>
                      <svg
                        className={styles["icon3"]}
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 4C4.89543 4 4 4.89543 4 6V14C4 15.1046 4.89543 16 6 16H14C15.1046 16 16 15.1046 16 14V11.5C16 11.2239 16.2239 11 16.5 11C16.7761 11 17 11.2239 17 11.5V14C17 15.6569 15.6569 17 14 17H6C4.34315 17 3 15.6569 3 14V6C3 4.34315 4.34315 3 6 3H8.5C8.77614 3 9 3.22386 9 3.5C9 3.77614 8.77614 4 8.5 4H6ZM11 3.5C11 3.22386 11.2239 3 11.5 3H16.5C16.7761 3 17 3.22386 17 3.5V8.5C17 8.77614 16.7761 9 16.5 9C16.2239 9 16 8.77614 16 8.5V4.70711L11.8536 8.85355C11.6583 9.04882 11.3417 9.04882 11.1464 8.85355C10.9512 8.65829 10.9512 8.34171 11.1464 8.14645L15.2929 4H11.5C11.2239 4 11 3.77614 11 3.5Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
              <div className={styles["illustration"]}>
                <div className={styles["group-1321317083"]}>
                  {/* <img
                                        src='svgs/Illustration_Home_Demo.svg'
                                        alt='Illustration_Home_Demo'
                                        style={{
                                            position: 'absolute',
                                            top: -200,
                                            left: -300,
                                            width: 1250,
                                            height: 1250,
                                        }}
                                    /> */}
                </div>
              </div>
            </div>
            <div className={styles["power-security"]}>
              <div className={styles["frame-2018776033"]}>
                <div className={styles["design"]}>
                  <div className={styles["the-power-security-of-azure-arc"]}>
                    THE POWER + SECURITY OF AZURE ARC{" "}
                  </div>
                  <div
                    className={
                      styles["deploy-quickly-test-easily-evaluate-confidently"]
                    }
                  >
                    Deploy quickly. Test easily. <br />
                    Evaluate confidently.{" "}
                  </div>
                  <div
                    className={
                      styles[
                        "azure-arc-enables-the-deployment-of-azure-services-anywhere-and-extends-azure-management-to-any-infrastructure-on-premises-edge-and-multi-cloud-arc-jumpstart-lets-you-build-experiences-and-harness-the-full-power-of-azure-arc-in-minutes-unlock-a-world-of-operational-efficiency-holistic-governance-and-agile-innovation-complete-with-seamless-application-performance-and-fortified-data-security"
                      ]
                    }
                  >
                    <span>
                      <span
                        className={
                          styles[
                            "azure-arc-enables-the-deployment-of-azure-services-anywhere-and-extends-azure-management-to-any-infrastructure-on-premises-edge-and-multi-cloud-arc-jumpstart-lets-you-build-experiences-and-harness-the-full-power-of-azure-arc-in-minutes-unlock-a-world-of-operational-efficiency-holistic-governance-and-agile-innovation-complete-with-seamless-application-performance-and-fortified-data-security-span"
                          ]
                        }
                      >
                        Azure Arc enables the deployment of Azure services
                        anywhere and extends Azure management to any
                        infrastructure—on-premises, edge, and multi-cloud.
                        <br />
                        <br />
                      </span>
                      <span
                        className={
                          styles[
                            "azure-arc-enables-the-deployment-of-azure-services-anywhere-and-extends-azure-management-to-any-infrastructure-on-premises-edge-and-multi-cloud-arc-jumpstart-lets-you-build-experiences-and-harness-the-full-power-of-azure-arc-in-minutes-unlock-a-world-of-operational-efficiency-holistic-governance-and-agile-innovation-complete-with-seamless-application-performance-and-fortified-data-security-span2"
                          ]
                        }
                      >
                        Arc Jumpstart
                      </span>
                      <span
                        className={
                          styles[
                            "azure-arc-enables-the-deployment-of-azure-services-anywhere-and-extends-azure-management-to-any-infrastructure-on-premises-edge-and-multi-cloud-arc-jumpstart-lets-you-build-experiences-and-harness-the-full-power-of-azure-arc-in-minutes-unlock-a-world-of-operational-efficiency-holistic-governance-and-agile-innovation-complete-with-seamless-application-performance-and-fortified-data-security-span3"
                          ]
                        }
                      >
                        {" "}
                        lets you build experiences and harness the full power of
                        Azure Arc in minutes. Unlock a world of operational
                        efficiency, holistic governance, and agile innovation,
                        complete with seamless application performance and
                        fortified data security.
                      </span>
                    </span>{" "}
                  </div>{" "}
                </div>
                <div className={styles["frame-2018775982"]}>
                  <Link to={"/jumpstart_overview"} style={{ zIndex: 1000 }}>
                    <div className={styles["frame-2018775982"]}>
                      <div className={styles["primary-button"]}>
                        <div className={styles["primary"]}>
                          Get started with Jumpstart{" "}
                        </div>
                      </div>
                    </div>
                  </Link>
                  <a
                    href="https://learn.microsoft.com/azure/azure-arc/"
                    target="_blank"
                    style={{ zIndex: 1000 }}
                    rel="noopener noreferrer"
                  >
                    <div className={styles["secondary-button"]}>
                      <div className={styles["secondary"]}>
                        More about Azure Arc{" "}
                      </div>
                      <svg
                        className={styles["icon4"]}
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 4C4.89543 4 4 4.89543 4 6V14C4 15.1046 4.89543 16 6 16H14C15.1046 16 16 15.1046 16 14V11.5C16 11.2239 16.2239 11 16.5 11C16.7761 11 17 11.2239 17 11.5V14C17 15.6569 15.6569 17 14 17H6C4.34315 17 3 15.6569 3 14V6C3 4.34315 4.34315 3 6 3H8.5C8.77614 3 9 3.22386 9 3.5C9 3.77614 8.77614 4 8.5 4H6ZM11 3.5C11 3.22386 11.2239 3 11.5 3H16.5C16.7761 3 17 3.22386 17 3.5V8.5C17 8.77614 16.7761 9 16.5 9C16.2239 9 16 8.77614 16 8.5V4.70711L11.8536 8.85355C11.6583 9.04882 11.3417 9.04882 11.1464 8.85355C10.9512 8.65829 10.9512 8.34171 11.1464 8.14645L15.2929 4H11.5C11.2239 4 11 3.77614 11 3.5Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
              <div className={styles["power-security2"]}>
                <div className={styles["group-1321317083"]}>
                  {/* <img
                                        src='svgs/Illustration_Home_Power-Security.svg'
                                        alt='Illustration_Home_Power-Security'
                                        style={{
                                            position: 'absolute',
                                            top: -350,
                                            left: -250,
                                            width: 1350,
                                            height: 1350
                                        }}
                                    /> */}
                </div>
              </div>
            </div>
            <div className={styles["blogs"]}>
              <div className={styles["text"]}></div>
              <div className={styles["related-case-studies"]}>
                <div className={styles["group-1962"]}>
                  <div className={styles["frame-49"]}>
                    {homeBlogs.map((blog, index) => (
                      <HomeBlog key={index} blog={blog} />
                    ))}
                  </div>
                </div>
              </div>
              <div className={styles["frame-2018776018"]}>
                <div className={styles["design3"]}>
                  <div className={styles["blogs2"]}>BLOGS </div>
                  <div className={styles["read-our-latest-stories"]}>
                    Read our latest stories{" "}
                  </div>
                </div>
                <Link to={"/blogs"} style={{ zIndex: 1000 }}>
                  <div className={styles["primary-button"]}>
                    <div className={styles["primary"]}>View all </div>
                  </div>
                </Link>
              </div>
            </div>
            <Footer footerData={footerData} />
          </div>
        </div>
      </div>
      <img
        className={styles["landing-page-img"]}
        src="svgs/Illustration-Main.svg"
        alt="Illustration-Main"
        style={{
          position: "absolute",
          top: 25,
          left: 645,
          width: 665,
          height: 665,
        }}
      />
      <img
        src="svgs/Illustration_Home_Our-Mission.svg"
        alt="Illustration_Home_Our-Mission"
        style={{
          position: "absolute",
          top: 1710,
          left: 550,
          width: 1050,
          height: 1050,
        }}
      />
      <img
        src="svgs/Illustration_Hero_Demos.svg"
        alt="Illustration_Hero_Demos"
        style={{
          position: "absolute",
          top: 2460,
          left: -300,
          width: 1250,
          height: 1250,
        }}
      />
      <img
        src="svgs/Illustration_Home_Power-Security.svg"
        alt="Illustration_Home_Power-Security"
        style={{
          position: "absolute",
          top: 3280,
          left: 420,
          width: 1350,
          height: 1350,
        }}
      />
    </>
  );
};

export default Home;
