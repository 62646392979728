import React, { useState } from 'react';
import './Heading2.css';
import CopyToClipboard from '../pages/buttons/CopyToClipboard';
import CopiedLinkSuccessfully from '../pages/toast/CopiedLinkSuccessfully';

export const Heading2 = (props) => {
    const [showToast, setShowToast] = useState(false);

    const copyToClipboard = (e) => {
        e.preventDefault();
        const el = document.createElement('textarea');
        const url = window.location.href.split('#')[0] + '#' + props.id;
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    const handleClick = (event) => {
        copyToClipboard(event);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000); // Toast disappears after 3 seconds
    };

    return (
        <div className='h2-container'>
            <span>
                <h2 id={props.id}>{props.children}</h2>
            </span>
            <span className='copy-link-container'>
                <CopyToClipboard onClick={handleClick} />
                <CopiedLinkSuccessfully show={showToast} />
            </span>
        </div>
    );
};
