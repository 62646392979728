// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-table-cell {
    font: 400 1rem/1.530625rem "Segoe UI", sans-serif;
    padding: 0.625rem 0.75rem;
    min-height: 148px;
    padding-left: 12px;
    margin: -0.0625rem 0 0 0;
    background-color: #242424;
    border: 1px solid #BDBDBD;
    border-width: 0.0625rem;
    border-top: none;
}

/* .custom-table-cell em {
    font-weight: normal;
} */`, "",{"version":3,"sources":["webpack://./src/components/Overrides/TableCell.css"],"names":[],"mappings":"AAAA;IACI,iDAAiD;IACjD,yBAAyB;IACzB,iBAAiB;IACjB,kBAAkB;IAClB,wBAAwB;IACxB,yBAAyB;IACzB,yBAAyB;IACzB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;;GAEG","sourcesContent":[".custom-table-cell {\n    font: 400 1rem/1.530625rem \"Segoe UI\", sans-serif;\n    padding: 0.625rem 0.75rem;\n    min-height: 148px;\n    padding-left: 12px;\n    margin: -0.0625rem 0 0 0;\n    background-color: #242424;\n    border: 1px solid #BDBDBD;\n    border-width: 0.0625rem;\n    border-top: none;\n}\n\n/* .custom-table-cell em {\n    font-weight: normal;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
