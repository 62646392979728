// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-menu-drawer {
  background-color: #292929;
  position: fixed;
  top: 48px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3000;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  transform: translateY(-100%);
}

.app-menu-drawer.visible {
  opacity: 1;
  /* box-shadow:  0 0 15px rgba(255, 255, 255, 0.5); */
  box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.5);
  transform: translateY(0);
}
/* .app-menu-drawer.visible::after{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20px;
    background-color: red;
    box-shadow:  0 0 15px rgba(255, 255, 255, 0.5);
  } */
.app-menu-drawer-content {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 1440px;
}
`, "",{"version":3,"sources":["webpack://./src/components/navigation/menu/Menu.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,eAAe;EACf,SAAS;EACT,OAAO;EACP,QAAQ;EACR,WAAW;EACX,aAAa;EACb,gCAAgC;EAChC,UAAU;EACV,4BAA4B;AAC9B;;AAEA;EACE,UAAU;EACV,oDAAoD;EACpD,oDAAoD;EACpD,wBAAwB;AAC1B;AACA;;;;;;;;;;KAUK;AACL;EACE,aAAa;EACb,uBAAuB;EACvB,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".app-menu-drawer {\n  background-color: #292929;\n  position: fixed;\n  top: 48px;\n  left: 0;\n  right: 0;\n  width: 100%;\n  z-index: 3000;\n  transition: all 0.5s ease-in-out;\n  opacity: 0;\n  transform: translateY(-100%);\n}\n\n.app-menu-drawer.visible {\n  opacity: 1;\n  /* box-shadow:  0 0 15px rgba(255, 255, 255, 0.5); */\n  box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.5);\n  transform: translateY(0);\n}\n/* .app-menu-drawer.visible::after{\n    content: \"\";\n    display: block;\n    position: absolute;\n    left: 0;\n    bottom: 0;\n    width: 100%;\n    height: 20px;\n    background-color: red;\n    box-shadow:  0 0 15px rgba(255, 255, 255, 0.5);\n  } */\n.app-menu-drawer-content {\n  display: flex;\n  justify-content: center;\n  margin: 0 auto;\n  max-width: 1440px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
