export function downloadJSON(data, filename) {
  // Convert JSON data to a Blob
  const blob = new Blob([JSON.stringify(data, null, 2)], {
    type: "application/json",
  });

  // Create a link element
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = filename;

  // Append the link to the document body and click it
  document.body.appendChild(a);
  a.click();

  // Clean up
  document.body.removeChild(a);
}

export function downloadImg(imageUrl, fileName) {
  const link = document.createElement("a");
  link.href = imageUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function downloadMd(data, fileName) {
  const blob = new Blob([data], { type: "text/markdown" });

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "document.md";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function convertBytes(bytes) {
  const kb = 1024;
  const mb = kb * 1024;
  const gb = mb * 1024;

  if (bytes < kb) {
    return `${bytes} bytes`;
  } else if (bytes < mb) {
    return `${(bytes / kb).toFixed(0)} KB`;
  } else if (bytes < gb) {
    return `${(bytes / mb).toFixed(2)} MB`;
  } else {
    return `${(bytes / gb).toFixed(2)} GB`;
  }
}

