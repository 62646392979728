import React from 'react';
import './Heading1.css';

// Override Heading Elements
export const Heading1 = (props) => {
    return (
        <h1 className="custom-h1" id={props.id}>
            {props.children}
        </h1>
    );
};
