import styles from "./Badges.module.css";
import Footer from "../../navigation/footer/Footer";

const Badges = ({ children, footerData, node }) => {
  return (
    <div className={styles["complete-guidelines"]}>
      <div className={styles["frame-2018776051"]}>
        <div className={styles["frame-2018775814"]}>
          <div className={styles["hero-images"]}>
            <div className={styles["frame-1"]}>
              <div className={styles["carousel-1"]}></div>
              <div className={styles["group-1321317151"]}>
              <img
                src='svgs/Illustration_Badges.svg'
                alt='Jumpstart_Logo_Circle'
                style={{
                  position: 'absolute',
                  top: 150,
                  left: 635,
                  width: 685,
                  height: 685,
                }}
              />
              </div>
            </div>
            <div className={styles["group-1010107217"]}>
              <div className={`${styles["follow-our-open-source-guidelines"]}`}>
                  Jumpstart <span className={`${styles["blue-badges"]}`}>Badges</span>{" "}
              </div>
              <div className={styles["write-up-guidelines"]}>
                ACHIEVEMENTS{" "}
              </div>
              <div
                className={
                  styles[
                  "view-our-best-practices-and-guidelines-for-open-source-contribution"
                  ]
                }
              >
                Learn various ways to obtain Jumpstart badges.{" "}
              </div>
            </div>
          </div>
          {children}
          <Footer footerData={footerData} />
        </div>
      </div>
    </div>
  );
};

export default Badges;


