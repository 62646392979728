import styles from "./MenuCard.module.css";

const MenuCard = ({ item, children, selectedMenuCard, setSelectedMenuCard }) => {
    const isValidLink = item.Link && item.Link !== '';
    const isExternalLink = isValidLink && item.Link.includes('http');

    const handleOnClick = () => {
        if (isValidLink) {
            setSelectedMenuCard(item)
        }
    };

    return (
        <div
            className={`${styles['container']} ${isValidLink ? styles['is-valid-link'] : ''}`}
            onClick={handleOnClick}
        >
            {isValidLink && <div className={styles.glow}></div>}
            <div className={`${styles['card']} ${isValidLink ? styles['is-valid-link'] : ''}`}>
                <div className={styles['header']}>
                    <div className={styles['text']}>
                        <div className={styles['title']}>
                            {item.Title}
                        </div>
                    </div>
                    {
                        isExternalLink && (
                            <svg
                                className={styles['icon-redirect']}
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3.5 1H3C1.89543 1 1 1.89543 1 3V9C1 10.1046 1.89543 11 3 11H9C10.1046 11 11 10.1046 11 9V8.5"
                                    stroke="#D6D6D6"
                                    strokeLinecap="round"
                                />
                                <path
                                    d="M5.64645 5.64645C5.45118 5.84171 5.45118 6.15829 5.64645 6.35355C5.84171 6.54882 6.15829 6.54882 6.35355 6.35355L5.64645 5.64645ZM11.5 1C11.5 0.723858 11.2761 0.5 11 0.5L6.5 0.5C6.22386 0.5 6 0.723858 6 1C6 1.27614 6.22386 1.5 6.5 1.5L10.5 1.5L10.5 5.5C10.5 5.77614 10.7239 6 11 6C11.2761 6 11.5 5.77614 11.5 5.5L11.5 1ZM6.35355 6.35355L11.3536 1.35355L10.6464 0.646447L5.64645 5.64645L6.35355 6.35355Z"
                                    fill="#D6D6D6"
                                />
                            </svg>
                        )
                    }
                </div>
                <div className={styles["description"]}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default MenuCard;