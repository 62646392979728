import React from "react";
import styles from "./BlogLink.module.css";
import { Link } from "react-router-dom";

const BlogLink = ({ blogLink }) => {
    const isExternalLink = blogLink.Link.includes("http");
    return isExternalLink ? (
        <a href={blogLink.Link} target="_blank" style={{ zIndex: 1000 }} rel="noopener noreferrer">
            <span className={styles["label"]}>
                {blogLink.Label}
            </span>
        </a>
    ) : (
        <Link to={blogLink.Link}>
            <span className={styles["label"]}>
                {blogLink.Label}
            </span>
        </Link>
    )
}

export default BlogLink;
