import styles from "./JumpstartAgora.module.css";
import AgoraOverviewCard from "../cards/AgoraOverviewCard";
import Footer from "../../navigation/footer/Footer";
import AgoraOverviewCardContainer from "../cards/AgoraOverviewCardContainer";

const JumpstartAgora = ({ node, footerData }) => {
  return (
    <div className={styles["complete-jumpstart-agora"]}>
      <div className={styles["frame-2018776051"]}>
        <div className={styles["frame-2018775814"]}>
          <div className={styles["hero-images"]}>
            <div className={styles["frame-1"]}>
              <div className={styles["carousel-1"]}></div>
            </div>
            <div className={styles["group-1010107217"]}>
              <div className={styles["jumpstart-agora"]}>
                <span>
                  <span className={styles["jumpstart-agora-span"]}>
                    Jumpstart{" "}
                  </span>
                  <span className={styles["jumpstart-agora-span2"]}>Agora</span>
                </span>{" "}
              </div>
              <div className={styles["industry-applications"]}>
                INDUSTRY APPLICATIONS{" "}
              </div>
              <div
                className={
                  styles[
                  "explore-comprehensive-cloud-to-edge-scenarios-designed-for-specific-industry-needs"
                  ]
                }
              >
                Explore comprehensive cloud-to-edge scenarios designed for
                specific industry needs.{" "}
              </div>
            </div>
            <div className={styles["illustration-agora"]}>
              <img
                src='svgs/Illustration_Agora.svg'
                alt='Illustration_Agora'
                style={{
                  position: 'absolute',
                  top: 150,
                  left: 635,
                  width: 685,
                  height: 685,
                }}
              />
            </div>
          </div>
          <AgoraOverviewCardContainer node={node} />
          <Footer footerData={footerData} />
        </div>
      </div>
    </div>
  );
};

export default JumpstartAgora;


