
import { LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
    names: {
        signUpSignIn: 'B2C_1_All',
        editProfile: "b2c_1_edit_profile_v2"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://jumpstartexternal.b2clogin.com/jumpstartexternal.onmicrosoft.com/b2c_1_all",
        },
        editProfile: {
            authority: "https://jumpstartexternal.b2clogin.com/jumpstartexternal.onmicrosoft.com/b2c_1_edit_profile_v2"
        }
    },
    authorityDomain: "jumpstartexternal.b2clogin.com"
};


export const msalConfig={
auth:{
    clientId:'a1ddd147-a184-4434-84f3-8d7aa63ca1c9',
    authority:b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri:'/',
    postLogoutRedirectUri:'/',
    navigateToLoginRequestUri:true
},
cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
  },
  system: {	
    loggerOptions: {	
        loggerCallback: (level, message, containsPii) => {	
            if (containsPii) {		
                return;		
            }		
            switch (level) {		
                case LogLevel.Error:		
                    console.error(message);		
                    return;		
                case LogLevel.Info:		
                    console.info(message);		
                    return;		
                case LogLevel.Verbose:		
                    console.debug(message);		
                    return;		
                case LogLevel.Warning:		
                    console.warn(message);		
                    return;		
            }	
        }	
    }	
}
}
export const apiRequest={
    scopes: ['https://jumpstartexternal.onmicrosoft.com/api/read'],
}