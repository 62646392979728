import React from 'react';
import './Blockquote.css';

export const Blockquote = (props) => {

    const containsText = (element, searchText) => {
        return React.Children.toArray(element).some(child => containsTextChildren(child, searchText));
    }

    const containsTextChildren = (element, searchText) => {
        if (typeof element === 'string' && element.includes(searchText)) {
            return true;
        }

        if (React.isValidElement(element) && element.props.children) {
            return React.Children.toArray(element.props.children).some(child => containsTextChildren(child, searchText));
        }

        return false;
    };

    const modifiedChildren = React.Children.map(props.children, child => child);

    return (
        <blockquote
            style={{
                backgroundColor:
                    containsText(modifiedChildren, "Important") ? "#5E5176" :
                    containsText(modifiedChildren, "Tip") ? "#3A5937" :
                    containsText(modifiedChildren, "Caution") ? "#75383C" :
                    containsText(modifiedChildren, "Disclaimer") ? "#625D31" :
                    containsText(modifiedChildren, "Warning") ? "#625D31" : "#2F3646",
                color:
                    containsText(modifiedChildren, "Important") ? "#F3ECFF" :
                    containsText(modifiedChildren, "Tip") ? "#D8FFD5" :
                    containsText(modifiedChildren, "Caution") ? "#FFE2E3" :
                    containsText(modifiedChildren, "Disclaimer") ? "#FFF492" :
                    containsText(modifiedChildren, "Warning") ? "#FFF492" : "#B2CDFF",
                padding: "15px 25px 15px 50px",
                marginLeft: "0px",
                marginRight: "0",
            }}
        >
            {modifiedChildren}
        </blockquote>
    );
};
