// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h2-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    align-items: top;
    gap: 0.5rem;
    min-height: 2rem;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
}

.h2-container span h2 {
    margin: 0px;
    line-height: 2.1875rem;
}

.h2-container span h2 em.custom-italics {
    font-weight: bold;
}

.copy-link-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 3px;
}`, "",{"version":3,"sources":["webpack://./src/components/Overrides/Heading2.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,+BAA+B;IAC/B,8BAA8B;IAC9B,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,qBAAqB;IACrB,QAAQ;AACZ","sourcesContent":[".h2-container {\n    width: 100%;\n    display: grid;\n    grid-template-columns: 1fr auto;\n    justify-content: space-between;\n    align-items: top;\n    gap: 0.5rem;\n    min-height: 2rem;\n    margin-top: 2.5rem;\n    margin-bottom: 1.5rem;\n}\n\n.h2-container span h2 {\n    margin: 0px;\n    line-height: 2.1875rem;\n}\n\n.h2-container span h2 em.custom-italics {\n    font-weight: bold;\n}\n\n.copy-link-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-end;\n    gap: 3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
