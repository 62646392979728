// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ByLine_text__Nmo4t {
    text-align: left;
    color: var(--neutral-text-text-white, #ffffff);
    font: 400 0.75rem/1.5rem "Segoe UI", sans-serif;
    position: relative;
    width: 43.515625rem;
}

.ByLine_author__Zlovd {
    color: #50E6FF;
    font-family: Segoe UI;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0.36px;
    text-decoration: none;
}

.ByLine_author__Zlovd:hover {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/blogs/ByLine.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,8CAA8C;IAC9C,+CAA+C;IAC/C,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".text {\n    text-align: left;\n    color: var(--neutral-text-text-white, #ffffff);\n    font: 400 0.75rem/1.5rem \"Segoe UI\", sans-serif;\n    position: relative;\n    width: 43.515625rem;\n}\n\n.author {\n    color: #50E6FF;\n    font-family: Segoe UI;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 15px;\n    letter-spacing: 0.36px;\n    text-decoration: none;\n}\n\n.author:hover {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `ByLine_text__Nmo4t`,
	"author": `ByLine_author__Zlovd`
};
export default ___CSS_LOADER_EXPORT___;
