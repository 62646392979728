import React, { useEffect, useState } from "react";
import styles from "./SearchDrawer.module.css";
import { Link } from "react-router-dom";
import SearchClear from "../buttons/SearchClear";

const SearchDrawer = ({
  isSearchDrawerVisible,
  onClick,
  onSearch,
  searchResults,
}) => {
  const [searchText, setSearchText] = useState("");

  const SearchItem = ({ item }) => {
    const { title, href } = item;
    const words = title.split(" ");

    return (
      <Link to={href} onClick={onClick}>
        <div className={styles["search-items2"]}>
          <svg
            className={styles["frame3"]}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.84487 2.80593C8.64493 2.61547 8.3284 2.62317 8.13793 2.82311C7.94747 3.02307 7.9552 3.33955 8.15513 3.53001L12.3227 7.49973H2.5C2.22386 7.49973 2 7.7236 2 7.99973C2 8.27587 2.22386 8.49973 2.5 8.49973H12.3228L8.15513 12.4695C7.9552 12.66 7.94747 12.9765 8.13793 13.1765C8.3284 13.3764 8.64493 13.3841 8.84487 13.1937L13.7908 8.48253C13.9034 8.37527 13.9701 8.2384 13.9907 8.0962C13.9968 8.065 14 8.03273 14 7.99973C14 7.96673 13.9968 7.9344 13.9907 7.90313C13.97 7.761 13.9033 7.62427 13.7908 7.51707L8.84487 2.80593Z"
              fill="#ADADAD"
            />
          </svg>
          <div className={styles["frame-2018776051"]}>
            {words.map((word, index) => {
              return (
                <span
                  className={
                    styles[
                      word.toLowerCase().includes(searchText.toLowerCase())
                        ? "jumpstart"
                        : "scenarios"
                    ]
                  }
                >
                  {word}
                </span>
              );
            })}
          </div>
        </div>
      </Link>
    );
  };

  const searchTextRef = React.createRef(null);

  useEffect(() => {
    if (isSearchDrawerVisible && searchTextRef.current) {
      searchTextRef.current.focus();
    }
  }, [isSearchDrawerVisible]);

  const clearSearchText = () => {
    setSearchText("");
    onSearch("");
    if (searchTextRef.current) {
      searchTextRef.current.value = "";
    }
  };

  return (
    <div className={styles["frame-2018775984"]}>
      <div className={styles["frame-2018776078"]}>
        <div className={styles["frame-2018776077"]}>
          <svg
            className={styles["frame"]}
            width="22"
            height="25"
            viewBox="0 0 22 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.0591 5.52539C12.811 5.52539 15.8526 8.56693 15.8526 12.3189C15.8526 13.8679 15.3341 15.2959 14.4613 16.4387L18.7576 20.7364C19.1113 21.0901 19.1113 21.6636 18.7576 22.0173C18.431 22.3439 17.9173 22.369 17.5619 22.0927L17.4766 22.0173L13.1789 17.721C12.0361 18.5939 10.6081 19.1123 9.0591 19.1123C5.30716 19.1123 2.26562 16.0708 2.26562 12.3189C2.26562 8.56693 5.30716 5.52539 9.0591 5.52539ZM9.0591 7.33698C6.30768 7.33698 4.07722 9.56745 4.07722 12.3189C4.07722 15.0703 6.30768 17.3008 9.0591 17.3008C11.8106 17.3008 14.041 15.0703 14.041 12.3189C14.041 9.56745 11.8106 7.33698 9.0591 7.33698Z"
              fill="white"
            />
          </svg>

          <input
            ref={searchTextRef}
            type="text"
            cursror="none"
            className={styles["search"]}
            defaultValue={searchText}
            placeholder="Search"
            style={{
              background: "none",
              border: "none",
              cursror: "none",
            }}
            onChange={(e) => {
              const value = e.target.value;
              setSearchText(value);
              onSearch(value);
            }}
          />
          {searchText.trim() !== "" && (
            <SearchClear onClick={clearSearchText} />
          )}
        </div>
        <div className={styles["search-results"]}>
          <div className={styles["search-items"]}>
            <div className={styles["suggested-links"]}>
              {searchText === "" ? "Quick links" : "Suggested links"}
            </div>
          </div>
          {searchResults.map((item, index) => {
            return <SearchItem item={item} key={index} />;
          })}
          {/* ok */}
        </div>
      </div>
    </div>
  );
};

export default SearchDrawer;
