// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlogLink_label__ZeEub {
    color: var(--neutral-text-text-cta, #50e6ff);
    font: normal 600 0.75rem/1.5rem "Segoe UI", sans-serif;
    letter-spacing: 0.0225rem;
}

.BlogLink_label__ZeEub:hover {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/blogs/BlogLink.module.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;IAC5C,sDAAsD;IACtD,yBAAyB;AAC7B;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".label {\n    color: var(--neutral-text-text-cta, #50e6ff);\n    font: normal 600 0.75rem/1.5rem \"Segoe UI\", sans-serif;\n    letter-spacing: 0.0225rem;\n}\n\n.label:hover {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `BlogLink_label__ZeEub`
};
export default ___CSS_LOADER_EXPORT___;
