import React, { useState, useEffect } from "react";
import styles from "./CopiedLinkSuccessfully.module.css";

const CopiedLinkSuccessfully = ({ show, position }) => {
  const style = {
    opacity: show ? 1 : 0,
    transition: 'opacity 0.5s',
    display: 'grid',
    gridTemplateRows: 'auto auto',
    justifyItems: 'end',
  }

  return (
    <span style={style}>
      <svg
        className={styles["above-start"]}
        width="8"
        height="4"
        viewBox="0 0 8 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.58579 1.41421C3.36684 0.633164 4.63317 0.633165 5.41421 1.41421L8 4H4H0L2.58579 1.41421Z"
          fill="#383838"
        />
      </svg>
      <span className={styles["copied-link-successfully"]}>
        Copied link to clipboard
      </span>
    </span>
  );
};

export default CopiedLinkSuccessfully;
