import React from "react";
import styles from "./OverviewCardContainer.module.css";
import OverviewCard from "./OverviewCard";

const OverviewCardContainer = ({ node }) => {
    return (
        <div className={styles["frame-2018775831"]}>
            <div className={styles["frame-20187758312"]}>
                <div className={styles["frame-2018775833"]}>
                    {
                        node.Items.map((item, index) => (
                            <OverviewCard key={index} node={item} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
};

export default OverviewCardContainer;
