import React from "react";
import BlogLink from "./BlogLink";
import ByLine from "./ByLine";
import { Link } from "react-router-dom";
import styles from "./Blog.module.css";

const Blog = ({ blog }) => {
    const { PubDate, MinRead, BlogLinks, Title, Byline, Text } = blog;
    const link = blog.Link;

    const blogLinks = BlogLinks.map((blogLink, index) => [
        index > 0 && <span style={{ color: '#50e6ff' }}>, </span>,
        <BlogLink key={index} blogLink={blogLink} />
    ]).flat();

    const isExternalLink = link.includes("http");

    return isExternalLink ? (
        <a href={link} target="_blank" style={{ zIndex: 1000 }} rel="noopener noreferrer">
            <div className={styles["news2"]}>
                <div className={styles["frame-44"]}>
                    <div className={styles["general-object-detection"]}>
                        <div className={styles["frame-2018775799"]}>
                            <div className={styles["frame-2018775798"]}>
                                <div className={styles["frame-2018776024"]}>
                                    <div className={styles["published-container"]}>
                                        <span>
                                            <span className={styles["published"]}>
                                                Published {PubDate} • {MinRead} min read | {blogLinks}
                                            </span>
                                            
                                        </span>
                                    </div>
                                    <div className={styles["title-container"]}>
                                        <div className={styles["title"]}>
                                            {Title}
                                        </div>
                                    </div>
                                    <ByLine byline={Byline} />
                                </div>
                                <div className={styles["text"]}>
                                    {Text}
                                </div>
                            </div>
                        </div>
                    </div>
                    <svg
                        className={styles["svg"]}
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6.43351 4.92188C5.46703 4.92188 4.68355 5.70537 4.68355 6.67188V18.1719C4.68355 19.1384 5.46703 19.9219 6.43351 19.9219H17.9332C18.8997 19.9219 19.6832 19.1384 19.6832 18.1719V14.1719C19.6832 13.7577 20.019 13.4219 20.4332 13.4219C20.8474 13.4219 21.1831 13.7577 21.1831 14.1719V18.1719C21.1831 19.9668 19.7281 21.4219 17.9332 21.4219H6.43351C4.63863 21.4219 3.18359 19.9668 3.18359 18.1719V6.67188C3.18359 4.87695 4.63863 3.42188 6.43351 3.42188H10.4334C10.8476 3.42188 11.1834 3.75767 11.1834 4.17188C11.1834 4.58608 10.8476 4.92188 10.4334 4.92188H6.43351ZM13.1833 4.17188C13.1833 3.75767 13.5191 3.42188 13.9333 3.42188H20.4336C20.8478 3.42188 21.1836 3.75767 21.1836 4.17188V10.6719C21.1836 11.0861 20.8478 11.4219 20.4336 11.4219C20.0194 11.4219 19.6836 11.0861 19.6836 10.6719V5.98261L14.4636 11.2023C14.1707 11.4951 13.6959 11.4951 13.403 11.2022C13.1101 10.9093 13.1101 10.4344 13.403 10.1415L18.6231 4.92188H13.9333C13.5191 4.92188 13.1833 4.58608 13.1833 4.17188Z"
                            fill="#50E6FF"
                        />
                    </svg>
                </div>
            </div>        </a>
    ) : (
        <Link to={link}>
            <div className={styles["news2"]}>
                <div className={styles["frame-44"]}>
                    <div className={styles["general-object-detection"]}>
                        <div className={styles["frame-2018775799"]}>
                            <div className={styles["frame-2018775798"]}>
                                <div className={styles["frame-2018776024"]}>
                                    <div className={styles["published-container"]}>
                                        <span>
                                            <span className={styles["published"]}>
                                                Published {PubDate} • {MinRead} min read | {blogLinks}
                                            </span>
                                           
                                        </span>
                                    </div>
                                    <div className={styles["title-container"]}>
                                        <div className={styles["title"]}>
                                            {Title}
                                        </div>
                                    </div>
                                    <ByLine byline={Byline} />
                                </div>
                                <div className={styles["text"]}>
                                    {Text}
                                </div>
                            </div>
                        </div>
                    </div>
                    <svg
                        className={styles["svg"]}
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6.43351 4.92188C5.46703 4.92188 4.68355 5.70537 4.68355 6.67188V18.1719C4.68355 19.1384 5.46703 19.9219 6.43351 19.9219H17.9332C18.8997 19.9219 19.6832 19.1384 19.6832 18.1719V14.1719C19.6832 13.7577 20.019 13.4219 20.4332 13.4219C20.8474 13.4219 21.1831 13.7577 21.1831 14.1719V18.1719C21.1831 19.9668 19.7281 21.4219 17.9332 21.4219H6.43351C4.63863 21.4219 3.18359 19.9668 3.18359 18.1719V6.67188C3.18359 4.87695 4.63863 3.42188 6.43351 3.42188H10.4334C10.8476 3.42188 11.1834 3.75767 11.1834 4.17188C11.1834 4.58608 10.8476 4.92188 10.4334 4.92188H6.43351ZM13.1833 4.17188C13.1833 3.75767 13.5191 3.42188 13.9333 3.42188H20.4336C20.8478 3.42188 21.1836 3.75767 21.1836 4.17188V10.6719C21.1836 11.0861 20.8478 11.4219 20.4336 11.4219C20.0194 11.4219 19.6836 11.0861 19.6836 10.6719V5.98261L14.4636 11.2023C14.1707 11.4951 13.6959 11.4951 13.403 11.2022C13.1101 10.9093 13.1101 10.4344 13.403 10.1415L18.6231 4.92188H13.9333C13.5191 4.92188 13.1833 4.58608 13.1833 4.17188Z"
                            fill="#50E6FF"
                        />
                    </svg>
                </div>
            </div>
        </Link>
    )
}

export default Blog;
