import React from 'react';
import './Heading5.css';

export const Heading5 = (props) => {
    return (
        <h5 className="custom-h5" id={props.id}>
            {props.children}
        </h5>
    );
};
