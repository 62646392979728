// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-h6 {
  
}

.custom-h5 em.custom-italics {
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/Overrides/Heading6.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".custom-h6 {\n  \n}\n\n.custom-h5 em.custom-italics {\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
