import React, { useState } from "react";
import styles from "./Feedback.module.css";

const Feedback = (props) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '2px '
            }}
        >
            <button
                onClick={() => {
                    window.open(props.href, '_blank');
                }}
                className={styles["button"]}
                tabIndex="0"
            >
                <span className={styles["icon"]}></span>
                <span className={styles["text"]}>Feedback</span>
            </button>
        </div>
    );
};

export default Feedback;
