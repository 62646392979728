import React from "react";
import Footer from "../../navigation/footer/Footer";
import Error from "../../error/error";

const NotFoundError = ({ footerData }) => {
  return (
    <>
      <Error
        title="404 ERROR"
        header="Page not found"
        description="Sorry, this page does not exist or has been moved."
        imgLink="./svgs/404error.svg"
      />
      <Footer footerData={footerData} />
    </>
  );
};

export default NotFoundError;
