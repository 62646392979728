import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Breadcrumbs.css';


function Breadcrumbs({ breadcrumbs }) {
    return (
        <div className='breadcrumb-bar'>
            <Link to={''} >
                <div className={breadcrumbs.length === 0 ? "breadcrumb-item-selected" : "breadcrumb-item"}>
                    <div className={breadcrumbs.length === 0 ? "breadcrumb-item-breadcrumb-selected" : "breadcrumb-item-breadcrumb"}>
                        Home
                    </div>
                </div>
            </Link>
            {
                breadcrumbs.length > 0 && (
                    <span className="forwardSlash">/ </span>
                )
            }
            {breadcrumbs.map((breadcrumb, index) => {
                const routeTo = breadcrumb.path;
                let title = breadcrumb.title;
                if (breadcrumb.title === '') {
                    title = 'Home';
                } else if (index === breadcrumbs.length - 1 && title.length > 10) {
                    title = title.slice(0, 20) + '...';
                }
                return (
                    <>
                        <span key={index} className='breadcrumb-focus-on-tab'>
                            <Link to={routeTo} className='' >
                                <div className={index === breadcrumbs.length - 1 ? "breadcrumb-item-selected" : "breadcrumb-item "} title={index === breadcrumbs.length - 1 ? breadcrumb.title : ''}>
                                    <div className={index === breadcrumbs.length - 1 ? "breadcrumb-item-breadcrumb-selected" : "breadcrumb-item-breadcrumb   "}>
                                        {title}
                                    </div>
                                </div>
                            </Link>
                        </span>
                        {
                            index !== breadcrumbs.length - 1 && (
                                <span className="forwardSlash">/ </span>
                            )
                        }
                    </>
                );
            })}
        </div>
    );
}

export default Breadcrumbs;
