// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-h1 {
    color: #FFF;
    font-family: Segoe UI, sans-serif;
    font-size: 30.477px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    /* 131.246% */
    letter-spacing: -0.305px;
}

.custom-h1 em.custom-italics {
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/Overrides/Heading1.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iCAAiC;IACjC,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".custom-h1 {\n    color: #FFF;\n    font-family: Segoe UI, sans-serif;\n    font-size: 30.477px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 40px;\n    /* 131.246% */\n    letter-spacing: -0.305px;\n}\n\n.custom-h1 em.custom-italics {\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
