import { useEffect, useMemo, useState } from "react";
import styles from "./Profile.module.css";
import { useAuthContext } from "../../../context/AuthProvider";

const Profile = () => {
  const { name } = useAuthContext();
  return (
    <div
      className={`${styles[`state-rest-item-profile`]} focus-on-tab`}
      role="button"
      tabIndex="0"
    >
      <div className={styles["frame-59961"]}>
        <div className={styles["profile"]}>
          Hi {name ? name : sessionStorage.getItem("username")}{" "}
        </div>
      </div>
    </div>
  );
};

export default Profile;
