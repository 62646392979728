// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/svgs/Search_Clear.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../public/svgs/Search_Clear_Hover.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchClear_property-1-default__1VZRJ,
.SearchClear_property-1-default__1VZRJ * {
  box-sizing: border-box;
}
.SearchClear_property-1-default__1VZRJ {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
  cursor: pointer;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.SearchClear_property-1-default__1VZRJ:hover {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}`, "",{"version":3,"sources":["webpack://./src/components/navigation/buttons/SearchClear.module.css"],"names":[],"mappings":"AAAA;;EAEE,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,yDAAiE;AACnE;;AAEA;IACI,yDAAuE;AAC3E","sourcesContent":[".property-1-default,\n.property-1-default * {\n  box-sizing: border-box;\n}\n.property-1-default {\n  width: 24px;\n  height: 24px;\n  position: relative;\n  overflow: visible;\n  cursor: pointer;\n  background-image: url('../../../../public/svgs/Search_Clear.svg');\n}\n\n.property-1-default:hover {\n    background-image: url('../../../../public/svgs/Search_Clear_Hover.svg');\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"property-1-default": `SearchClear_property-1-default__1VZRJ`
};
export default ___CSS_LOADER_EXPORT___;
