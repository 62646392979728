import styles from "./Videos.module.css";
import Footer from "../../navigation/footer/Footer";
import OverviewCard from "../cards/OverviewCard";
import OverviewCardContainer from "../cards/OverviewCardContainer";

const Videos = ({ node, footerData }) => {
  return (
    <div className={styles["complete-videos"]}>
      <div className={styles["frame-2018776051"]}>
        <div className={styles["frame-2018775814"]}>
          <div className={styles["hero-images"]}>
            <div className={styles["frame-1"]}>
              <div className={styles["carousel-1"]}></div>
            </div>
            <div className={styles["group-1010107217"]}>
              <div className={styles["watch-and-learn"]}>Watch and learn </div>
              <div className={styles["videos"]}>VIDEOS </div>
              <div
                className={
                  styles[
                  "check-out-our-you-tube-channel-for-real-customer-stories-helpful-product-demos-quick-tutorials-and-more"
                  ]
                }
              >
                Check out our YouTube channel for real customer stories, helpful
                product demos, quick tutorials, and more.{" "}
              </div>
            </div>
            <div className={styles["frame-2018776082"]}>
              <div className={styles["group-1321317103"]}>
                <img
                  src='webp/Illustration_Videos.webp'
                  alt='Illustration_Videos'
                  style={{
                    position: 'absolute',
                    top: 55,
                    left: 375
                  }}
                />
              </div>
            </div>
          </div>
          <OverviewCardContainer node={node} />
          <Footer footerData={footerData} />
        </div>
      </div>
    </div>
  );
};

export default Videos;


