import React from 'react';
import './Heading4.css';

export const Heading4 = (props) => {
    return (
        <h4 className="custom-h4" id={props.id}>
            {props.children}
        </h4>
    );
};
