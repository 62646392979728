import React, { useRef, useState } from "react";
import "./Menu.css";
import MenuItem from "./MenuItem";
import MenuDrawer from "./MenuDrawer";
import { parse } from "flatted";

const Menu = ({
  menuItems,
  selectedMenuItem,
  setSelectedMenuItem,
  setQueHideMenuDrawer,
  setIsMenuDrawerVisible,
  isMenuDrawerVisible,
}) => {
  const handleOnClick = (menuItem) => {
    setSelectedMenuItem(selectedMenuItem !== menuItem ? menuItem : null);
  };

  return (
    <span
      style={{
        display: "flex",
      }}
    >
      {menuItems &&
        menuItems.map((menuItem, index) => {
          return (
            <>
              <MenuItem
                index={index}
                selectedMenuItem={selectedMenuItem}
                isSelected={selectedMenuItem === menuItem}
                isMenuDrawerVisible={isMenuDrawerVisible}
                onClick={() => {
                  handleOnClick(menuItem);
                  setIsMenuDrawerVisible(
                    isMenuDrawerVisible.map((menuDrawer, i) =>
                      i === index ? !menuDrawer : false
                    )
                  );
                }}
                setIsMenuDrawerVisible={setIsMenuDrawerVisible}
                menuItems={menuItems}
                setSelectedMenuItem={setSelectedMenuItem}
              >
                {menuItem.Title}
              </MenuItem>
              {isMenuDrawerVisible[index] ? (
                <div
                  key={index}
                  className={`app-menu-drawer ${
                    isMenuDrawerVisible[index] ? "visible" : ""
                  }`}

                  // ref={menuDrawerRef}
                  // onMouseEnter={handleMouseEnterMenuDrawer}
                  // onMouseLeave={handleMouseLeaveMenuDrawer}
                >
                  <div className="app-menu-drawer-content">
                    <MenuDrawer
                      key={index}
                      menuItem={menuItem}
                      setQueHideMenuDrawer={setQueHideMenuDrawer}
                      setIsMenuDrawerVisible={setIsMenuDrawerVisible}
                      menuItems={menuItems}
                      // setCurrentRef={currentSubItemRef}
                      // setSelectedMenuItem={setSelectedMenuItem}
                    />
                  </div>
                </div>
              ) : null}
            </>
          );
        })}
    </span>
  );
};

export default Menu;
