import React from 'react';
import './ListItem.css';

export const ListItem = ({ children, ...props }) => {
    // function to recursively look into children to see if they contain text with the word HYPHEN
    // if so, replace it with a real hyphen

    const fixedInlineHyphen = (children) => {
        return React.Children.map(children, child => {
            if (typeof child === 'string') {
                return child.replace(/HYPHEN/g, '-');
            }
            return child;
        });
    }

    // a function like fixedInlineHyphen but recursive
    // this is used to look into children of children
    // and replace the HYPHEN text with a real hyphen
    const fixedInlineHyphenRecursive = (children) => {
        return React.Children.map(children, child => {
            if (typeof child === 'string') {
                return child.replace(/HYPHEN/g, '-');
            }
            if (React.isValidElement(child) && child.props.children) {
                const newChildren = fixedInlineHyphenRecursive(child.props.children);
                return React.cloneElement(child, {...child.props, children: newChildren});
            }
            return child;
        });
    }
    


    return (
        <li {...props} className="custom-list-item">
            {fixedInlineHyphenRecursive(children)}
        </li>

    )
};
