import styles from "./AllBlogs.module.css";
import Blog from "../blogs/Blog";
import Footer from "../../navigation/footer/Footer";

const ArcJumpstartBlogs = ({ blogs, footerData }) => {
  return (
    <div className={styles["complete-all-blogs"]}>
      <div className={styles["frame-2018776051"]}>
        <div className={styles["frame-2018775814"]}>
          <div className={styles["hero-images"]}>
            <div className={styles["frame-1"]}>
              <div className={styles["carousel-1"]}></div>
            </div>
            <div className={styles["group-1010107218"]}>
              <div className={styles["see-what-s-new-and-noteworthy"]}>
                See what’s new and noteworthy{" "}
              </div>
              <div className={styles["blogs"]}>BLOGS </div>
              <div
                className={
                  styles["featured-stories-from-arc-jumpstart-and-azure-edge"]
                }
              >
                Read the latest announcements.{" "}
              </div>
            </div>
            <div className={styles["illustration-blogs"]}>
              <img
                src='webp/Illustration_Blogs.webp'
                alt='Illustration_Blogs'
                style={{
                  position: 'absolute',
                  top: -85,
                  left: 170
                }}
              />
            </div>
          </div>
          <div className={styles["blogs2"]}>
            <div className={styles["frame-2018775832"]}>
              <div className={styles["frame-20187758322"]}>
                <div className={styles["frame-2018776026"]}>
                  {
                    blogs.map((blog, index) => <Blog key={index} blog={blog} />)
                  }
                </div>
              </div>
            </div>
          </div>
          <Footer footerData={footerData} />
        </div>
      </div>
    </div>
  );
};

export default ArcJumpstartBlogs;
