import React from "react";
import Footer from "../../navigation/footer/Footer";
import Error from "../../error/error";

const InternalServerError = ({ footerData }) => {
  return (
    <>
      <Error
        title="500 ERROR"
        header="Internal server error"
        description="An unexpected error occurred on our server. Please try again later."
        imgLink="./svgs/500error.svg"
      />
      <Footer footerData={footerData} />
    </>
  );
};

export default InternalServerError;
