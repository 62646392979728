import { useEffect } from 'react';

function Title() {
    useEffect(() => {
        const title = window.location.href.includes('preview') || window.location.href.includes('canary') || window.location.href.includes('localhost') ? 'Preview Azure Arc Jumpstart' : 'Azure Arc Jumpstart';

        // Set the OG Graph Meta Tag
        var metaTag = document.querySelector('meta[property="og:title"]');
        metaTag.setAttribute('content', title);

        // Set the Twitter Meta Tag
        var twitterTag = document.querySelector('meta[name="twitter:title"]');
        twitterTag.setAttribute('content', title);

        // Set the HTML Document Title
        document.title = title;
    }, []);

    return null;
}

export default Title;