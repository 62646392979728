import React from "react";
import styles from "./ReleaseNotes.module.css";
import { Link } from "react-router-dom";

export const ReleaseNotes = ({ setQueHideSearchDrawer }) => {
  return (
    <Link
      className="focus-on-tab"
      to={"release_notes"}
      onFocus={() => {
        setQueHideSearchDrawer(true);
      }}
    >
      <div className={styles["state-rest-item-release-notes"]}>
        <div className={styles["frame-59941"]}>
          <div className={styles["release-notes"]}>Release notes </div>
          <span className={styles["frame"]}></span>
        </div>
      </div>
    </Link>
  );
};

export default ReleaseNotes;
