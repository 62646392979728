// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-image-container {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

.custom-image {
  max-width: 100%;  /* Ensure it doesn't exceed the container's width */
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* Stroke is #757575 (variable: Colors/Neutral/Grey/46), 1px thick, and inside. */
  border: 1px solid #757575;
}

.badges-custom-image {
  max-width: 100%;  /* Ensure it doesn't exceed the container's width */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.custom-image-width {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

p a .custom-image-container {
  display: inline-block;
}

p a div img.custom-image {
  width: auto;
  display: inline-block;
  max-height: 1.5em;
  vertical-align: bottom;
}

p a div img.custom-image-width {
  display: inline-block;
  max-height: 1.5em;
  vertical-align: bottom;
}`, "",{"version":3,"sources":["webpack://./src/components/Overrides/Image.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,eAAe,GAAG,mDAAmD;EACrE,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,iFAAiF;EACjF,yBAAyB;AAC3B;;AAEA;EACE,eAAe,GAAG,mDAAmD;EACrE,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,qBAAqB;EACrB,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,sBAAsB;AACxB","sourcesContent":[".custom-image-container {\n  max-width: 1024px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.custom-image {\n  max-width: 100%;  /* Ensure it doesn't exceed the container's width */\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n  /* Stroke is #757575 (variable: Colors/Neutral/Grey/46), 1px thick, and inside. */\n  border: 1px solid #757575;\n}\n\n.badges-custom-image {\n  max-width: 100%;  /* Ensure it doesn't exceed the container's width */\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.custom-image-width {\n  max-width: 100%;\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n}\n\np a .custom-image-container {\n  display: inline-block;\n}\n\np a div img.custom-image {\n  width: auto;\n  display: inline-block;\n  max-height: 1.5em;\n  vertical-align: bottom;\n}\n\np a div img.custom-image-width {\n  display: inline-block;\n  max-height: 1.5em;\n  vertical-align: bottom;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
