import styles from "./Guidelines.module.css";
import Footer from "../../navigation/footer/Footer";

const Guidelines = ({ children, footerData, node }) => {
  return (
    <div className={styles["complete-guidelines"]}>
      <div className={styles["frame-2018776051"]}>
        <div className={styles["frame-2018775814"]}>
          <div className={styles["hero-images"]}>
            <div className={styles["frame-1"]}>
              <div className={styles["carousel-1"]}></div>
              <div className={styles["group-1321317151"]}>
              <img
                src='webp/Illustration_Guidelines.webp'
                alt='Illustration_Guidelines'
                style={{
                  position: 'absolute',
                  top: 184,
                  left: 616
                }}
              />
              </div>
            </div>
            <div className={styles["group-1010107217"]}>
              <div className={styles["follow-our-open-source-guidelines"]}>
                Follow our open source guidelines{" "}
              </div>
              <div className={styles["write-up-guidelines"]}>
                WRITE-UP GUIDELINES{" "}
              </div>
              <div
                className={
                  styles[
                  "view-our-best-practices-and-guidelines-for-open-source-contribution"
                  ]
                }
              >
                View our best practices and guidelines for open source
                contribution.{" "}
              </div>
            </div>
          </div>
          {children}
          <Footer footerData={footerData} />
        </div>
      </div>
    </div>
  );
};

export default Guidelines;


