// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-h3 {}

.custom-h3 em.custom-italics {
    font-weight: bold;
}

.custom-h3#frequently-asked-questions-faq {
    margin-top: 50px !important;
}

.custom-h3#apply-for-a-jumpstart-community-badge {
    margin-top: 50px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Overrides/Heading3.css"],"names":[],"mappings":"AAAA,YAAY;;AAEZ;IACI,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".custom-h3 {}\n\n.custom-h3 em.custom-italics {\n    font-weight: bold;\n}\n\n.custom-h3#frequently-asked-questions-faq {\n    margin-top: 50px !important;\n}\n\n.custom-h3#apply-for-a-jumpstart-community-badge {\n    margin-top: 50px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
