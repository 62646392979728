import React, { useState } from "react";
import styles from "./JumpToSection.module.css";

const JumpToSection = ({ sections }) => {
  const [open, setOpen] = useState(false);

  const scrollToSection = (href) => {
    const elements = href.split("#");
    if (elements.length > 0) {
      const section = elements[1];
      const sectionElement = document.getElementById(section);
      const topPosition =
        sectionElement.getBoundingClientRect().top + window.pageYOffset - 150;
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
  };

  let dropdownContainerStyles = styles["dropdown-container"];
  if (open) {
    dropdownContainerStyles += ` ${styles["open"]}`;
  }

  let mainMenuStyles = styles["main-menu"];
  if (open) {
    mainMenuStyles += ` ${styles["open"]}`;
  }

  let frame2018775997Styles = styles["frame-2018775997"];
  if (open) {
    frame2018775997Styles += ` ${styles["open"]}`;
  }

  return (
    <div
      className={`${dropdownContainerStyles}`}
      onMouseLeave={() => setOpen(false)}
    >
      <div className={frame2018775997Styles}>
        {sections.map((section, index) => (
          <div
            className={styles["dropdown-item"]}
            key={index}
            onClick={() => {
              setOpen(false);
              scrollToSection(section.href);
            }}
          >
            <div className={styles["list-container"]}>
              <div className={styles["checkmark-control"]}></div>
              <div className={styles["list-item-text2"]}>{section.name}</div>
            </div>
          </div>
        ))}
      </div>
      <div
        className={styles["dropdown-body"]}
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(!open)}
      >
        <div className={`${mainMenuStyles} focus-on-tab`} tabIndex={0}>
          <div className={styles["frame-59941"]}>
            <div className={styles["community"]} style={{ textWrap: "nowrap" }}>
              Jump to section{" "}
            </div>
            <svg
              className={styles["chevron"]}
              transform={open ? "rotate(0)" : "rotate(180)"}
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4952 9.16264C11.2674 9.39045 10.898 9.39045 10.6702 9.16264L6.99935 5.49178L3.32849 9.16264C3.1007 9.39045 2.73133 9.39045 2.50354 9.16264C2.27575 8.93483 2.27575 8.56549 2.50354 8.33768L6.58687 4.25435C6.81467 4.02656 7.18403 4.02656 7.41182 4.25435L11.4952 8.33768C11.723 8.56549 11.723 8.93483 11.4952 9.16264Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JumpToSection;
