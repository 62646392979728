import styles from "./Logout.module.css";
import msalInstance from "../../../auth";
import { useMsal } from "@azure/msal-react";
import { useAuthContext } from "../../../context/AuthProvider";

const Logout = () => {
  const { dispatch } = useAuthContext();
  return (
    <div
      className={`${styles[`state-rest-item-logout`]} focus-on-tab`}
      onClick={async () => {
        try {
          await msalInstance.logoutPopup({
            scopes: [
              "openid",
              "offline_access",
              "https://jumpstartexternal.onmicrosoft.com/b2c_1_all/user_impersonation",
            ],
          });
          sessionStorage.removeItem("authToken");
          dispatch({
            type: "SET_TOKEN",
            payload: {
              token: "",
            },
          });
        } catch (err) {
          console.log(err);
        }
      }}
      role="button"
      tabIndex="0"
    >
      <div className={styles["frame-59961"]}>
        <div className={styles["logout"]}>Logout </div>
      </div>
    </div>
  );
};

export default Logout;
