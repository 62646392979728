import React from 'react';
import './Heading6.css';

export const Heading6 = (props) => {
    return (
        <h6 className="custom-h6" id={props.id}>
            {props.children}
        </h6>
    );
};
