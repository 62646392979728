import Footer from '../../navigation/footer/Footer';
import OverviewCard from "../cards/OverviewCard";
import OverviewCardContainer from '../cards/OverviewCardContainer';
import styles from "./About.module.css";

const About = ({ node, footerData }) => {
    return (
        <div className={styles["complete-about-overview"]}>
            <div className={styles["frame-2018776051"]}>
                <div className={styles["frame-2018775814"]}>
                    <div className={styles["hero-images"]}>
                        <div className={styles["frame-1"]}>
                            <div className={styles["carousel-1"]}></div>
                            <div className={styles["illustration-get-started-security-006"]}>
                                <div className={styles["group-56367"]}>
                                    <svg
                                        className={styles["group-1750"]}
                                        width="300"
                                        height="180"
                                    ></svg>
                                </div>
                            </div>
                        </div>
                        <div className={styles["group-1010107217"]}>
                            <div className={styles["all-about-jumpstart"]}>
                                All about Jumpstart{" "}
                            </div>
                            <div className={styles["about"]}>ABOUT </div>
                            <div
                                className={
                                    styles[
                                    "learn-about-what-we-re-doing-when-we-re-doing-it-and-how-to-try-it-out"
                                    ]
                                }
                            >
                                Learn about what we’re doing, when we’re doing it, and how to
                                try it out.{" "}
                            </div>
                        </div>
                        <div className={styles["group-1321317152"]}>
                            <img
                                src='webp/Illustration_About.webp'
                                alt='Illustration_About'
                                style={{
                                    position: 'absolute',
                                    top: 155,
                                    left: 560,
                                    width: 800,
                                    height: 800,
                                }}
                            />
                        </div>
                    </div>
                    <OverviewCardContainer node={node} />
                    <Footer footerData={footerData} />
                </div>
            </div>
        </div>
    );
};

export default About;
