import React, { useState } from 'react';
import './CodeBlock.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CopyCodeToClipboard from '../pages/buttons/CopyCodeToClipboard';
import CopiedCodeSuccessfully from '../pages/toast/CopiedCodeSuccessfully';

export const CodeBlock = (props) => {
    const [showToast, setShowToast] = useState(false);

    const copyToClipboard = (e, text) => {
        e.preventDefault();
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };
    

    const handleClick = (event, text) => {
        copyToClipboard(event, text);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000); // Toast disappears after 3 seconds
    };

    const getChildrenText = (children) => {
        if (typeof children === 'string') {
            return children;
        }
        if (Array.isArray(children)) {
            return children.map(getChildrenText).join('');
        }
        if (children.props && children.props.children) {
            return getChildrenText(children.props.children);
        }
        return '';
    };

    const getLanguage = (children) => {
        if (children.props && children.props.className && children.props.className.includes('lang-')) {
            return children.props.className.split('lang-')[1];
        }
        if (Array.isArray(children)) {
            return children.map(getLanguage).join('');
        }
        if (children.props && children.props.children) {
            return getLanguage(children.props.children);
        }
        return '';
    };

    const language = getLanguage(props.children);
    let text = getChildrenText(props.children);
    if (text) {
        text = text.replace(/\n+$/, '');
    }

    return (
        <div className='code-block-container'>
            <div className='code-block-header'>
                <span>{language}</span>
                <CopyCodeToClipboard onClick={(e) => handleClick(e, text)} />
            </div>
            <div className='code-block-body'>
                <SyntaxHighlighter 
                    language={language} 
                    style={oneDark}
                    showLineNumbers={false}
                >
                    {text}
                </SyntaxHighlighter>
                <span className='copy-code-successfully-toast'>
                    <CopiedCodeSuccessfully show={showToast}/>
                </span>
            </div>
        </div>
    )
};
