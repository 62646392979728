import styles from "./JumpstartGems.module.css";
import OverviewCard from "../cards/OverviewCard";
import Footer from '../../navigation/footer/Footer';
import OverviewCardContainer from "../cards/OverviewCardContainer";

const JumpstartGems = ({ node, footerData }) => {
  return (
    <div className={styles["complete-jumpstart-assets"]}>
      <div className={styles["frame-2018776051"]}>
        <div className={styles["frame-2018775814"]}>
          <div className={styles["hero-images"]}>
            <div className={styles["frame-1"]}>
              <div className={styles["carousel-1"]}></div>
            </div>
            <div className={styles["group-1010107217"]}>
              <div className={styles["access-jumpstart-assets"]}>
                Jumpstart <span className={`${styles["red-badges"]}`}>Gems</span>{" "}
              </div>
              <div className={styles["jumpstart-assets"]}>
                DIAGRAMS{" "}
              </div>
              <div
                className={
                  styles[
                  "take-advantage-of-our-range-of-assets-more-coming-soon"
                  ]
                }
              >
                Get deeper insights with detailed architectural diagrams and visual assets.{" "}
              </div>
            </div>
            <div className={styles["group-55960"]}>
              <img
                src='svgs/Illustration_Gems.svg'
                alt='Illustration_Jumpstart-Gems'
                style={{
                  position: 'absolute',
                  top: 150,
                  left: 635,
                  width: 685,
                  height: 685,
                }}
              />
            </div>
          </div>
          <OverviewCardContainer node={node} />
          <Footer footerData={footerData} />
        </div>
      </div>
    </div>
  );
};

export default JumpstartGems;


