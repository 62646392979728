// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/feedback.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../public/feedback-hover.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../../public/feedback-focus.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Feedback_button__5guEE {
  background: #242424;
  border: none;
  display: flex;
  align-items: center;
  gap: 6px;
}

.Feedback_button__5guEE:hover {
  background: #383838;
}

.Feedback_icon__LwQjg {
  width: 16px;
  height: 17px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.Feedback_button__5guEE:hover .Feedback_icon__LwQjg {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

.Feedback_button__5guEE:focus .Feedback_icon__LwQjg {
  outline: none;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

.Feedback_text__H1mE2 {
  color: #ffffff;
  text-align: left;
  font: 400 0.875rem/1.530625rem "Segoe UI", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/buttons/Feedback.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mDAAkD;AACpD;;AAEA;EACE,mDAAwD;AAC1D;;AAEA;EACE,aAAa;EACb,mDAAwD;AAC1D;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,qDAAqD;AACvD","sourcesContent":[".button {\n  background: #242424;\n  border: none;\n  display: flex;\n  align-items: center;\n  gap: 6px;\n}\n\n.button:hover {\n  background: #383838;\n}\n\n.icon {\n  width: 16px;\n  height: 17px;\n  background: url('../../../../public/feedback.svg');\n}\n\n.button:hover .icon {\n  background: url('../../../../public/feedback-hover.svg');\n}\n\n.button:focus .icon {\n  outline: none;\n  background: url('../../../../public/feedback-focus.svg');\n}\n\n.text {\n  color: #ffffff;\n  text-align: left;\n  font: 400 0.875rem/1.530625rem \"Segoe UI\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Feedback_button__5guEE`,
	"icon": `Feedback_icon__LwQjg`,
	"text": `Feedback_text__H1mE2`
};
export default ___CSS_LOADER_EXPORT___;
