// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CopiedCodeSuccessfully_copied-code-successfully__x24c2,
.CopiedCodeSuccessfully_copied-code-successfully__x24c2 * {
    box-sizing: border-box;
}

.CopiedCodeSuccessfully_copied-code-successfully__x24c2 {
    position: relative;
    display: inline-flex;
    padding: 0px 7px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid var(--neutral-stroke-transparent-rest, rgba(255, 255, 255, 0.00));
    background: var(--neutral-background-7-hover, #383838);
    /* Elevation / Light / Shadow 16 */
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 8px 16px 0px rgba(0, 0, 0, 0.14);
    color: #FFF;
    text-align: center;
    font-family: Segoe UI;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 200% */
    letter-spacing: 0.36px;
}

.CopiedCodeSuccessfully_above-start__Rtg1P,
.CopiedCodeSuccessfully_above-start__Rtg1P * {
    box-sizing: border-box;
}

.CopiedCodeSuccessfully_above-start__Rtg1P {
    border-radius: 0px;
    width: 8px;
    height: 4px;
    position: relative;
    transform: translateX(-13px);
    overflow: visible;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/toast/CopiedCodeSuccessfully.module.css"],"names":[],"mappings":"AAAA;;IAEI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,gBAAgB;IAChB,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,mFAAmF;IACnF,sDAAsD;IACtD,kCAAkC;IAClC,qFAAqF;IACrF,WAAW;IACX,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,SAAS;IACT,sBAAsB;AAC1B;;AAEA;;IAEI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,4BAA4B;IAC5B,iBAAiB;AACrB","sourcesContent":[".copied-code-successfully,\n.copied-code-successfully * {\n    box-sizing: border-box;\n}\n\n.copied-code-successfully {\n    position: relative;\n    display: inline-flex;\n    padding: 0px 7px;\n    flex-direction: column;\n    align-items: flex-start;\n    border-radius: 4px;\n    border: 1px solid var(--neutral-stroke-transparent-rest, rgba(255, 255, 255, 0.00));\n    background: var(--neutral-background-7-hover, #383838);\n    /* Elevation / Light / Shadow 16 */\n    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 8px 16px 0px rgba(0, 0, 0, 0.14);\n    color: #FFF;\n    text-align: center;\n    font-family: Segoe UI;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 24px;\n    /* 200% */\n    letter-spacing: 0.36px;\n}\n\n.above-start,\n.above-start * {\n    box-sizing: border-box;\n}\n\n.above-start {\n    border-radius: 0px;\n    width: 8px;\n    height: 4px;\n    position: relative;\n    transform: translateX(-13px);\n    overflow: visible;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copied-code-successfully": `CopiedCodeSuccessfully_copied-code-successfully__x24c2`,
	"above-start": `CopiedCodeSuccessfully_above-start__Rtg1P`
};
export default ___CSS_LOADER_EXPORT___;
