// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/svgs/Release_Notes.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../public/svgs/Release_Notes_Hover.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReleaseNotes_state-rest-item-release-notes__eK9B4,
.ReleaseNotes_state-rest-item-release-notes__eK9B4 * {
    box-sizing: border-box;
}

.ReleaseNotes_state-rest-item-release-notes__eK9B4 {
    background: var(--neutral-background-5-rest, #000000);
    padding: 14px 10px 14px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
}
.ReleaseNotes_state-rest-item-release-notes__eK9B4:hover {
    background-color: #333;
}
.ReleaseNotes_state-rest-item-release-notes__eK9B4:active {
    background-color: #242424;
}
.ReleaseNotes_frame-59941__-50G4 {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
}

.ReleaseNotes_release-notes__u9O8h {
    color: #ffffff;
    text-align: center;
    font: 400 13px/20px "Segoe UI", sans-serif;
    font-style: normal;
    letter-spacing: 0.65px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ReleaseNotes_frame__fpw5L {
    flex-shrink: 0;
    position: relative;
    overflow: visible;
    width: 17px;
    height: 16px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.ReleaseNotes_state-rest-item-release-notes__eK9B4:hover .ReleaseNotes_frame__fpw5L {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
`, "",{"version":3,"sources":["webpack://./src/components/navigation/buttons/ReleaseNotes.module.css"],"names":[],"mappings":"AAAA;;IAEI,sBAAsB;AAC1B;;AAEA;IACI,qDAAqD;IACrD,4BAA4B;IAC5B,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,uBAAuB;IACvB,2BAA2B;IAC3B,kBAAkB;AACtB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,mBAAmB;IACnB,2BAA2B;IAC3B,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,0CAA0C;IAC1C,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,yDAAkE;AACtE;;AAEA;IACI,yDAAwE;AAC5E","sourcesContent":[".state-rest-item-release-notes,\n.state-rest-item-release-notes * {\n    box-sizing: border-box;\n}\n\n.state-rest-item-release-notes {\n    background: var(--neutral-background-5-rest, #000000);\n    padding: 14px 10px 14px 10px;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    align-items: flex-start;\n    justify-content: flex-start;\n    position: relative;\n}\n.state-rest-item-release-notes:hover {\n    background-color: #333;\n}\n.state-rest-item-release-notes:active {\n    background-color: #242424;\n}\n.frame-59941 {\n    display: flex;\n    flex-direction: row;\n    gap: 4px;\n    align-items: center;\n    justify-content: flex-start;\n    flex-shrink: 0;\n    position: relative;\n}\n\n.release-notes {\n    color: #ffffff;\n    text-align: center;\n    font: 400 13px/20px \"Segoe UI\", sans-serif;\n    font-style: normal;\n    letter-spacing: 0.65px;\n    position: relative;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.frame {\n    flex-shrink: 0;\n    position: relative;\n    overflow: visible;\n    width: 17px;\n    height: 16px;\n    background-image: url('../../../../public/svgs/Release_Notes.svg');\n}\n\n.state-rest-item-release-notes:hover .frame {\n    background-image: url('../../../../public/svgs/Release_Notes_Hover.svg');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"state-rest-item-release-notes": `ReleaseNotes_state-rest-item-release-notes__eK9B4`,
	"frame-59941": `ReleaseNotes_frame-59941__-50G4`,
	"release-notes": `ReleaseNotes_release-notes__u9O8h`,
	"frame": `ReleaseNotes_frame__fpw5L`
};
export default ___CSS_LOADER_EXPORT___;
