import React from 'react';
import Breadcrumbs from './breadcrumbs/Breadcrumbs';
import JumpToSection from './jump-to-section/JumpToSection';
import styles from './NavBarLower.module.css';

const NavBarLower = ({ breadcrumbs, sections }) => {
    return (
        <div className={styles.navbarLowerContainer}>
            <div className={styles.breadcrumbsContainer}>
                <Breadcrumbs breadcrumbs={breadcrumbs} />
            </div>
            <div className={styles.dropdownContainer}>
                <JumpToSection sections={sections} />
            </div>
        </div>
    );
};

export default NavBarLower;
