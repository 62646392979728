import styles from "./JumpstartOverview.module.css";
import Footer from "../../navigation/footer/Footer";
import HomeCard from "../cards/HomeCard";

const JumpstartOverview = ({cards, footerData }) => {
  return (
    <div className={styles["complete-jumpstart-overview"]}>
      <div className={styles["frame-2018776051"]}>
        <div className={styles["frame-2018775814"]}>
          <div className={styles["hero-images"]}>
            <div className={styles["frame-1"]}>
              <div className={styles["carousel-1"]}></div>
            </div>
            <div className={styles["group-1010107217"]}>
              <div className={styles["get-started-with-jumpstart"]}>
                Get started with Jumpstart{" "}
              </div>
              <div className={styles["overview"]}>OVERVIEW </div>
              <div
                className={
                  styles[
                  "from-multi-scenario-to-industry-specific-explore-the-arc-jumpstart-universe-and-have-a-working-demo-in-no-time"
                  ]
                }
              >
                From multi-scenario to industry-specific, explore the Arc
                Jumpstart universe and have a working demo in no time.{" "}
              </div>
            </div>
            <div className={styles["group-1321317051"]}>
              <img
                src='svgs/Illustration-Main.svg'
                alt='Illustration-Main'
                style={{
                  position: 'absolute',
                  top: 175,
                  left: 645,
                  width: 665,
                  height: 665,
                }}
              />
            </div>
          </div>
          <div className={styles["frame-2018775831"]}>
            <div className={styles["explore-jumpstart"]}>
              <div className={styles["frame-2018776043"]}>
                <div className={styles["frame-2018776040"]}>
                  {
                    cards.map((card, index) => <HomeCard key={index} {...card} />)
                  }
                </div>
              </div>
            </div>
          </div>
          <Footer footerData={footerData} />
        </div>
      </div>
    </div>
  );
};

export default JumpstartOverview;

