import React from "react";
import styles from "./AgoraOverviewCardContainer.module.css";
import AgoraOverviewCard from "./AgoraOverviewCard";

const AgoraOverviewCardContainer = ({ node }) => {
    const items = node.Items.filter((item) => !item.HideInOverview);

    return (
        <div className={styles["frame-2018775831"]}>
            <div className={styles["frame-20187758312"]}>
                <div className={styles["frame-2018775833"]}>
                    {
                        items.map((item, index) => (
                            <AgoraOverviewCard key={index} node={item} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
};

export default AgoraOverviewCardContainer;
