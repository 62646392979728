import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./OverviewCard.module.css";

const OverviewCard = ({ node }) => {
    const title = node.Title || '';
    const description = node.Description || '';
    const icon = node.Icon || 'placeholder';
    const link = node.Link || '';
    const isExternalLink = node.Link.includes('http');
    const linkTitle = node.LinkTitle || 'View Scenario';
    const [iconState, setIconState] = useState('regular');

    const filledImage = <img className={styles["icon"]} alt={description} width={24} height={24} src={`../../../../../icon/${icon}-filled.svg`} />;
    const regularImage = <img className={styles["icon"]} alt={description} width={24} height={24} src={`../../../../../icon/${icon}-regular.svg`} />;

    useEffect(() => {
        // Preload the images
        const filledImage = new Image();
        filledImage.src = `../../../../../icon/${icon}-filled.svg`;

        const regularImage = new Image();
        regularImage.src = `../../../../../icon/${icon}-regular.svg`;
    }, [icon]); // Preload when icon changes

    return isExternalLink ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
            <div
                className={styles["mode-dark-state-rest-type-internal"]}
                onMouseEnter={() => setIconState('filled')}
                onMouseLeave={() => setIconState('regular')}
            >
                <div className={styles["frame-2018775810"]}>
                    <div className={styles["frame-44"]}>
                        <div className={styles["general-object-detection"]}>
                            <div className={styles["frame-2018775799"]}>
                                <div className={styles["frame-2018775798"]}>
                                    <div className={styles["frame-2018775832"]}>
                                        <div className={styles["frame-2018776042"]}>
                                            {iconState === 'filled' ? filledImage : regularImage}
                                        </div>
                                        <div className={styles["title"]}>{title} </div>
                                    </div>
                                    <div
                                        className={
                                            styles[
                                            "lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-sed-do-eiusmod-tempor"
                                            ]
                                        }
                                        title={description}
                                        style={{ cursor: 'arrow' }}
                                    >
                                        {description}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles["frame-2018775809"]}>
                        <div className={styles["view-scenario"]}>{linkTitle}</div>
                        <svg
                            className={styles["icon-redirect"]}
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3.04883 0.901367H2.74232C1.53089 0.901367 0.548828 1.88343 0.548828 3.09486V8.70788C0.548828 9.91931 1.53089 10.9014 2.74232 10.9014H8.35534C9.56677 10.9014 10.5488 9.91931 10.5488 8.70788V8.40137"
                                stroke="#50E6FF"
                                strokeWidth="1.09674"
                                strokeLinecap="round"
                            />
                            <path
                                d="M5.16107 5.51361C4.94692 5.72776 4.94692 6.07497 5.16107 6.28913C5.37522 6.50328 5.72243 6.50328 5.93659 6.28913L5.16107 5.51361ZM11.0972 0.901367C11.0972 0.59851 10.8517 0.352995 10.5488 0.352995L5.61348 0.352995C5.31062 0.352995 5.0651 0.59851 5.0651 0.901367C5.0651 1.20422 5.31062 1.44974 5.61348 1.44974H10.0005V5.83672C10.0005 6.13958 10.246 6.38509 10.5488 6.38509C10.8517 6.38509 11.0972 6.13958 11.0972 5.83672L11.0972 0.901367ZM5.93659 6.28913L10.9366 1.28913L10.1611 0.513609L5.16107 5.51361L5.93659 6.28913Z"
                                fill="#50E6FF"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </a>
    ) : (
        <Link to={`/${link}`}>
            <div
                className={styles["mode-dark-state-rest-type-internal"]}
                onMouseEnter={() => setIconState('filled')}
                onMouseLeave={() => setIconState('regular')}
            >
                <div className={styles["frame-2018775810"]}>
                    <div className={styles["frame-44"]}>
                        <div className={styles["general-object-detection"]}>
                            <div className={styles["frame-2018775799"]}>
                                <div className={styles["frame-2018775798"]}>
                                    <div className={styles["frame-2018775832"]}>
                                        <div className={styles["frame-2018776042"]}>
                                            <span
                                                className={styles["icon"]}
                                                style={{
                                                    width: 24,
                                                    height: 24,
                                                    backgroundImage: `url(../../../../../icon/${icon}-${iconState}.svg)`
                                                }}
                                            >
                                            </span>
                                        </div>
                                        <div className={styles["title"]}>{title} </div>
                                    </div>
                                    <div
                                        className={
                                            styles[
                                            "lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-sed-do-eiusmod-tempor"
                                            ]
                                        }
                                        title={description}
                                        style={{ cursor: 'arrow' }}
                                    >
                                        {description}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles["frame-2018775809"]}>
                        <div className={styles["view-scenario"]}>{linkTitle}</div>
                        <svg
                            className={styles["chevron-close"]}
                            width="16"
                            height="20"
                            viewBox="0 0 16 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M5.4205 6.86776C5.23744 7.05082 5.23744 7.34762 5.4205 7.53068L9.62027 11.7305L5.4205 15.9303C5.23744 16.1134 5.23744 16.4101 5.4205 16.5932C5.60355 16.7762 5.90035 16.7762 6.08341 16.5932L10.6146 12.0619C10.7977 11.8789 10.7977 11.5821 10.6146 11.399L6.08341 6.86776C5.90035 6.6847 5.60355 6.6847 5.4205 6.86776Z"
                                fill="#50E6FF"
                            />
                        </svg>
                    </div>
                </div>
            </div >
        </Link>
    );
};

export default OverviewCard;
