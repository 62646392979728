// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vertical-line {
  transform: translate(-0.5px, -0.5px);
}

.arc-jumpstart-logo {
  color: var(--neutral-foreground-1-rest, #ffffff);
  font: 600 14px/20px "Segoe UI", sans-serif;
  font-style: normal;
  letter-spacing: 0.42px;
}`, "",{"version":3,"sources":["webpack://./src/components/navigation/logo/Logo.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,gDAAgD;EAChD,0CAA0C;EAC1C,kBAAkB;EAClB,sBAAsB;AACxB","sourcesContent":[".vertical-line {\n  transform: translate(-0.5px, -0.5px);\n}\n\n.arc-jumpstart-logo {\n  color: var(--neutral-foreground-1-rest, #ffffff);\n  font: 600 14px/20px \"Segoe UI\", sans-serif;\n  font-style: normal;\n  letter-spacing: 0.42px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
