import React from "react";
import styles from "./AdaptiveCloud.module.css";
import { Link } from "react-router-dom";

export const AdaptiveCloud = ({
  setSelectedMenuItem,
  setIsMenuDrawerVisible,
  menuItems,
  setQueHideSearchDrawer,
}) => {
  return (
    <Link
    className="focus-on-tab"
      to={"adaptive_cloud"}
      onFocus={() => {
        setSelectedMenuItem(null);
        setIsMenuDrawerVisible(menuItems.map(() => false));
        setQueHideSearchDrawer(true);
      }}
    >
      <div className={styles["state-rest-action-link-tab-adaptive-cloud"]}>
        <div className={styles["frame-59941"]}>
          <div className={styles["adaptive-cloud"]}>Adaptive cloud </div>
        </div>
      </div>
    </Link>
  );
};

export default AdaptiveCloud;
