import Footer from '../../navigation/footer/Footer';
import OverviewCard from "../cards/OverviewCard";
import OverviewCardContainer from '../cards/OverviewCardContainer';
import styles from "./JumpstartArcBox.module.css";

const JumpstartArcBox = ({ node, footerData }) => {
  return (
    <div className={styles["complete-jumpstart-arc-box"]}>
      <div className={styles["frame-2018776051"]}>
        <div className={styles["frame-2018775814"]}>
          <div className={styles["hero-images"]}>
            <div className={styles["frame-1"]}>
              <div className={styles["carousel-1"]}></div>
            </div>
            <div className={styles["group-1010107217"]}>
              <div className={styles["jumpstart-arc-box"]}>
                <span>
                  <span className={styles["jumpstart-arc-box-span"]}>
                    Jumpstart{" "}
                  </span>
                  <span className={styles["jumpstart-arc-box-span2"]}>
                    ArcBox
                  </span>
                </span>{" "}
              </div>
              <div className={styles["azure-arc"]}>AZURE ARC </div>
              <div
                className={
                  styles[
                  "get-all-the-major-capabilities-of-azure-arc-in-a-virtual-hybrid-sandbox-all-you-need-is-an-azure-subscription-to-get-started"
                  ]
                }
              >
                Get all the major capabilities of Azure Arc in a virtual, hybrid
                sandbox. All you need is an Azure subscription to get started.{" "}
              </div>
            </div>
            <div className={styles["illustration-arc-box"]}>
              <img
                src='svgs/Illustration_ArcBox.svg'
                alt='Illustration_ArcBox'
                style={{
                  position: 'absolute',
                  top: 150,
                  left: 635,
                  width: 685,
                  height: 685,
                }}
              />
            </div>
          </div>
          <OverviewCardContainer node={node} />
          <Footer footerData={footerData} />
        </div>
      </div>
    </div>
  );
};

export default JumpstartArcBox;
