import styles from "./Documentation.module.css";
import OverviewCard from "../cards/OverviewCard";
import Footer from '../../navigation/footer/Footer';
import OverviewCardContainer from "../cards/OverviewCardContainer";

const Documentation = ({ node, footerData }) => {
  return (
    <div className={styles["complete-documentation"]}>
      <div className={styles["frame-2018776051"]}>
        <div className={styles["frame-2018775814"]}>
          <div className={styles["hero-images"]}>
            <div className={styles["frame-1"]}>
              <div className={styles["carousel-1"]}></div>
            </div>
            <div
              className={styles["illustration-get-started-monitor-008"]}
            ></div>
            <div className={styles["group-1321317133"]}>
            <img
                  src='webp/Illustration_Documentation.webp'
                  alt='Illustration_Documentation'
                  style={{
                    position: 'absolute',
                    top: 105,
                    left: 642
                  }}
                />
            </div>
            <div className={styles["group-1010107217"]}>
              <div className={styles["dive-into-the-documentation"]}>
                Dive into the documentation{" "}
              </div>
              <div className={styles["documentation"]}>DOCUMENTATION </div>
              <div
                className={
                  styles["get-all-the-technical-details-on-microsoft-learn"]
                }
              >
                Get all the technical details on Microsoft Learn.{" "}
              </div>
            </div>
          </div>
          <OverviewCardContainer node={node} />
          <Footer footerData={footerData} />
        </div>
      </div>
    </div>
  );
};

export default Documentation;


